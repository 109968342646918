import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

export class Document {
  id: string;
  doc: string;
}

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(private http: HttpClient) { }

  sendMessage(body) {

    return this.http.post<any>(`${environment.apiUrl}/v1/chat/message`, body).pipe(map(response => {
      return response.data;
    }));
  }

  addChat(body) {
    return this.http.post<any>(`${environment.apiUrl}/v1/chat/`, body).pipe(map(response => {
      return response.data;
    }));
  }

  getChats() {
    return this.http.get<any>(`${environment.apiUrl}/v1/chat/`).pipe(map(response => {
      return response.data;
    }));
  }

  getChatById(id) {
    return this.http.get<any>(`${environment.apiUrl}/v1/chat/` + `${id}`).pipe(map(response => {
      return response.data;
    }));
  }

  getMessages(filter = null) {
    return this.http.get<any>(`${environment.apiUrl}/v1/chat/message`, { params: filter }).pipe(map(response => {
      return response.data;
    }));
  }

  downLoadFile(id, download = false): Observable<any> {
    const params = new HttpParams().append('download', download.toString());
    return this.http.get(`${environment.apiUrl}/v1/chat/file/` + id.toString() + '/download', { params, responseType: 'blob' });
  }

  acknowledgeChat(chatId) {
    return this.http.get(`${environment.apiUrl}/v1/chat/ack?chat_id=` + chatId.toString());
  }

  acknowledgeMessage(messageId) {
    return this.http.get(`${environment.apiUrl}/v1/chat/ack?message_id=` + messageId.toString());
  }

  deleteChat(id) {
    return this.http.delete<any>(`${environment.apiUrl}/v1/chat/` + `${id}`).pipe(map(response => {
      return response.data;
    }));
  }

}
