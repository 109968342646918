import { ProductPopupComponent } from './../../shared/components/product-popup/product-popup.component';
import { Privilages, UserRoles } from '@app/core/enums/user-roles';
import { CartService } from './../cart/services/cart.service';
import { ShipService } from '@app/modules/ship/services/ship.service';
import { SharedService } from '@app/shared/service/shared.service';
import { CategoryService } from '@app/modules/category/services/category.service';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Product } from '../product/model/product.model';
import { ProductService } from '../product/services/product.service';
import { AuthenticationService } from '@app/authentication/services/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { PortService } from '@app/modules/port/services/port.service';
import { RfqService } from '@app/modules/rfq/services/rfq.service';
import { LoadingService } from '@app/authentication/services/loading.service';
import { AlertService } from '@app/shared/service/alert.service';
import { CartRequirement } from '../cart/models/cart.model';
import { Category } from '../category/models/category.model';

@Component({
  selector: 'app-catalogue',
  templateUrl: './catalogue.component.html',
  styleUrls: ['./catalogue.component.css']
})
export class CatalogueComponent implements OnInit {


  file: File;
  quantity = 1;

  modalRef: BsModalRef;
  loading: boolean;
  pageLoading: boolean;

  clearAll = true;
  categoryList = [];
  subCategoryList = [];
  shipList = [];
  portList = [];
  portId: number;
  productList: Product[] = [];
  noProduct: any;
  cart: any = [];
  shipId: number = null;

  selectCategoryId: number = 0;
  selectCategory: number;
  selectedCategory: number;
  categoryName: string;
  subCategoryName: string;

  selectedSubCategory = 0;
  selectedAutocomplete: string = null;

  page = 1;
  limit = 10;
  total: number;
  visibleCategories: boolean;
  searchForm: FormGroup;
  toggle: boolean;
  PROVISION_CATEGORY_ID = 61;

  @ViewChild('Selectship', { static: false }) selectShip: TemplateRef<any>;
  @ViewChild('productPopup', { static: false })
  productPopup: ProductPopupComponent;
  role: number;

  description: any;
  productImg: string;
  productDetail;
  userRoles = UserRoles;
  submitted: boolean;

  suggestion: any;
  query = null;
  canPlaceOrder: boolean;
  canPlaceReq: boolean;
  userSource: string = null;
  currencyCode: string;
  loading1: boolean;
  loading2: boolean;
  cartPortName = '';
  deleteSelected: number[] = [];
  @ViewChild('portChange', { static: false }) portChange: TemplateRef<any>;
  isAllCategoryClicked = false;
  searchQuery: any;
  previousPort: any;
  processing = false;
  userDetails: any;
  categorySelected = 61;

  viewPrice = false;
  rfqData: any = [];
  availableCategories: string[] = [];
  cartNewRequisitionForm: FormGroup;
  @ViewChild('cartNewRequirement') cartNewRequirement: TemplateRef<any>;
  cartRequirements: CartRequirement[];
  requirementsDropdownDataSource: CartRequirement[];
  selectedRequirement: CartRequirement;
  requirementCategories: Category[] = [];
  groupedCategoryIds = [];
  storeCategoryList = [];
  otherCategoryList = [];
  selectedRequirementName: string;
  creatorRank = [];
  privilages = Privilages;
  subAdminPrivilageIds: [number];
  subAdminCompanyIds: [number];
  subAdminRequisitionRights: boolean = false;
  attachmentsModal: TemplateRef<any>;
  assignedShips: any;
  commonImpaSubcategory: any[];
  constructor(
    private modalService: BsModalService,
    private categoryService: CategoryService,
    private productService: ProductService,
    private cartService: CartService,
    private authService: AuthenticationService,
    private sharedService: SharedService,
    private shipService: ShipService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private alertService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private portService: PortService,
    private rfqService: RfqService,
    private loadingService: LoadingService,
    private altService: AlertService
  ) {
    this.sharedService.shipIdSubject.subscribe(id => {
      this.shipId = id ? id : null;
    });
    this.assignedShips = this.sharedService.getShipData().data.ship_ids
  }
  get form() {
    return this.searchForm.controls;
  }
  ngOnInit() {
    this.canPlaceOrder = this.authService.currentUserValue.data.user_details.can_place_order;
    this.canPlaceReq = this.authService.currentUserValue.data.user_details.can_place_req;
    this.userSource = this.authService.currentUserValue.data.user_details.user_details.source;
    this.currencyCode = this.authService.currentUserValue.data.user_details.company_details.currency_code;
    this.viewPrice = this.canPlaceOrder; // this.canPlaceOrder || this.userSource != null;
    this.subAdminPrivilageIds = this.sharedService.getSubAdminsPrivileges();
    this.subAdminCompanyIds = this.sharedService.getSubAdminsCompanies();
    this.buildDropdownForm();
    this.getShip();
    this.userDetails = this.sharedService.getUserDetails();
    this.getCategory();
    this.getSubCategory();
    this.role = this.sharedService.getUserRole();
    if (this.role !== UserRoles.ROLE_ADMIN && this.role !== UserRoles.ROLE_CAPTAIN) {
      this.getShipList();
    }
    if (this.shipId) {
      this.getCartAllRequirements(this.shipId);
    }
    if (this.canPlaceOrder || this.userSource != null) {
      this.getPortList();
      if (this.query == null && !this.canPlaceReq) {
        this.getProduct();
      } else if (this.role == this.userRoles.ROLE_CAPTAIN) {
        this.getProduct();
      }
      else {
        this.getProduct();
      }
    } else {
      this.activatedRoute.queryParams.subscribe(
        p => {
          this.query = p.q;
          this.searchForm.get('search').setValue(this.query);
          if (this.query != null) {
            this.searchProduct();
          }
        }
      );
      if (this.query == null) {
        this.getProduct();
      }
    }
    this.createCartNewRequisitionForm();
    if (this.subAdminPrivilageIds && this.subAdminPrivilageIds.length > 0)
      this.subAdminRequisitionRights = this.subAdminPrivilageIds.includes(this.privilages.CREATE_REQUISITION);
  }

  createCartNewRequisitionForm() {
    this.cartNewRequisitionForm = this.formBuilder.group({
      requirement_category_id: [null, Validators.required],
      requirement_name: [null, Validators.required],
      creator_name: ['', Validators.required],
      rank: [null, Validators.required],
      product_id: [''],
      quantity: [''],
      category_id: [''],
      port_id: [''],
      remark: [''],
    });
  }

  get Rform() {
    return this.cartNewRequisitionForm.controls;
  }

  viewCategories() {
    // return (this.visibleCategories = !this.visibleCategories);
    if (window.innerWidth < 1025) {
      this.visibleCategories = !this.visibleCategories;
      return this.visibleCategories;
    }
  }


  openList(id) {
    if (this.selectCategory === id) {
      this.toggle = !this.toggle;
    } else {
      this.toggle = true;
    }
    this.selectCategory = id;
    this.selectCategoryId = null;
  }

  setShip(ship) {
    this.shipId = ship.id;
    if (this.shipList.length > 1) {
      this.toastr.success('Changed Successfully', 'Ship',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
    }
    localStorage.setItem('ship_id', this.shipId.toString());
    this.getCartAllRequirements(this.shipId);
    this.getProduct(this.selectedSubCategory, this.selectCategoryId);
    // this.getCategory();
    // this.getSubCategory();
  }

  openPopup(product) {
    this.productDetail = product;
    this.productPopup.openModal();
  }

  openModal(template: TemplateRef<any>, product = null) {
    if (product) {
      const emptyArray = [];
      emptyArray.push(product);

      this.productDetail = emptyArray;
      this.description = product.description;
      this.productImg =
        product.images.length !== 0 && product.images[0].path ? product.images[0].path : null;
    }
    this.modalRef = this.modalService.show(
      template,
      Object.assign(
        {},
        {
          class: 'gray modal-md'
        }
      )
    );
    this.altService.backModalClose(this.modalRef);
  }

  increaseVal(i) {
    if (i.sub_category.category_ids.includes(this.PROVISION_CATEGORY_ID) && this.categorySelected === this.PROVISION_CATEGORY_ID && this.portId == null &&
      this.role === this.userRoles.ROLE_CAPTAIN && this.canPlaceOrder) {
      this.alertService.error('Please select port');
      return;
    } else {
      if (!this.shipId) {
        this.alertService.error('Please select ship');
        return;
      }
      if (!this.checkRequirementSelect(i)) {
        return false;
      }
      this.processing = true;
      i.quantity++;
      this.addItemToCart(i);
    }
  }

  decreaseVal(i) {
    if (i.sub_category.category_ids.includes(this.PROVISION_CATEGORY_ID) && this.categorySelected === this.PROVISION_CATEGORY_ID && this.portId == null &&
      this.role === this.userRoles.ROLE_CAPTAIN && this.canPlaceOrder) {
      this.alertService.error('Please select port');
      return;
    } else {
      if (!this.checkRequirementSelect(i)) {
        return false;
      }
      if (i.quantity >= 0) {
        this.processing = true;
        if (i.quantity < 1) {
          i.quantity = 0;
        } else {
          i.quantity--;
        }
        if (i.quantity == 0) {
          this.remove(i, i.cart_id);
          return 'deleted';
        } else {
          this.addItemToCart(i);
        }
      }
    }
  }
  uniqueObjectsArray
  getCategory() {
    const page = this.page;
    this.categoryService.getAllV2(page, 20).subscribe((data: any) => {
      data.data.map(x => { x.active = false; });
      this.categoryList = data.data;
      this.requirementCategories = data.data;
      this.groupedCategoryIds = data.grouped_category_ids;
      this.otherCategoryList = data.data.filter(x => !this.groupedCategoryIds.includes(x.id));
      const index = this.otherCategoryList.findIndex(x => x.id === this.PROVISION_CATEGORY_ID);
      if (this.otherCategoryList.length > 0) {
        this.otherCategoryList.unshift(this.otherCategoryList.splice(index, 1)[0]);
      }
      if (data.grouped_category_ids && data.grouped_category_ids.length > 0) {
        this.storeCategoryList = data.data.filter(x => this.groupedCategoryIds.includes(x.id));
        const subCategoriesMap = this.storeCategoryList.map(item => item.sub_categories);
        const uniqueObjectsMap = new Map();
        subCategoriesMap.forEach(obj => {
          if (!uniqueObjectsMap.has(obj.id)) {
            uniqueObjectsMap.set(obj.id, obj);
          }
        });
        this.uniqueObjectsArray = Array.from(uniqueObjectsMap.values())[0];
      }
      if (this.categoryList.length === 1) {
        this.selectCategory = this.categoryList[0].id;
        this.selectedCategory = this.categoryList[0].id;
        this.toggle = true;
      }
    });
  }
  getSubCategory() {
    this.categoryService.getSubCategoryV2().subscribe(data => {
      data.map(x => { x.active = false; });
      this.subCategoryList = data;
    });
  }
  setSubCategory(subCategoryId, categoryId, viewPrice = false, isAllCategories?) {
    this.isAllCategoryClicked = isAllCategories;
    this.viewPrice = viewPrice;
    this.selectCategory = categoryId;
    this.selectedCategory = categoryId;
    this.selectCategoryId = categoryId;
    // const cartRequirements = this.cartRequirements.;

    if (this.cartRequirements && this.cartRequirements.length > 0 && this.storeCategoryList.some(x => x.id == categoryId)) {
      this.requirementsDropdownDataSource = this.cartRequirements.filter(x => this.storeCategoryList.find(({ id }) => (x.category_id == id)))
    }
    else if (this.cartRequirements && this.cartRequirements.length > 0 && categoryId) {
      this.requirementsDropdownDataSource = this.cartRequirements.filter(x => (x.category_id === categoryId))
    } else {
      this.requirementsDropdownDataSource = this.cartRequirements;
    }
    console.log('setsubcategory', this.otherCategoryList);

    this.categorySelected = categoryId ? categoryId : this.PROVISION_CATEGORY_ID;
    this.selectedSubCategory = subCategoryId;
    this.page = 1;
    this.selectedAutocomplete = null;
    // this.checkSelectCategory();
    this.getProduct(this.selectedSubCategory, this.selectCategoryId);
    this.clearAll = true;
    this.searchForm.get('search').setValue('');
  }

  getProduct(id = 0, catId = 0, search = this.selectedAutocomplete, showLoading = false) {
    this.selectedSubCategory = id;
    if (!showLoading) {
      this.pageLoading = true;
    }
    this.processing = true;
    const page = this.page;
    const limit = this.limit;
    const portId = this.portId;
    const shipId = this.shipId;
    const requirementId = this.selectedRequirement ? this.selectedRequirement.id : null;

    // search = search ? search.replace(/[^a-zA-Z ]/g, "") : null;
    this.productService.getByCategoryId(id, catId, page, limit, search, shipId, portId, requirementId).subscribe(
      data => {
        this.productList = data.results;
        this.total = data.total;
        this.noProduct = this.total === 0 ? 'No Product Found' : '';
        this.productList.map(product => {
          product.quantity = (product.quantity && product.quantity > 0) ? product.quantity : 0;
          product.product_id = product.id;
          product.ship_id = this.shipId;
          product.category_id = this.selectCategoryId;
          product.categories = this.availableCategory(product.sub_category.category_ids);
        });
        if (this.role === this.userRoles.ROLE_CAPTAIN && this.productList && this.productList.length > 0) {
          this.portId = this.portId ? this.portId : this.productList[0].current_port_id;
        }
        this.processing = false;
        this.pageLoading = false;
        this.categoryName = this.getCategoryName();
        this.subCategoryName = this.getSubCategoryName();
        if (this.shipId) {
          this.cartService.getCartSummary(this.shipId).subscribe(res => { });
        }
        this.loading2 = false;
      }, error => {
        this.processing = false;
        this.loading2 = false;
        this.pageLoading = false;
      });
  }

  checkSelectCategory() {
    if (this.role === this.userRoles.ROLE_CAPTAIN && this.canPlaceOrder && !this.canPlaceReq) {
      this.requirementCategories = this.categoryList.filter(cat => cat.id === this.PROVISION_CATEGORY_ID);
      this.categoryList && this.categoryList.length > 0 && !this.selectedCategory ? this.cartNewRequisitionForm.get('requirement_category_id').setValue(this.requirementCategories[0].id) : this.cartNewRequisitionForm.get('requirement_category_id').setValue(this.selectedCategory);
    } else {
      if (this.selectedCategory == 0 || !this.selectedCategory) {
        this.requirementCategories = this.categoryList;
      } else if (this.groupedCategoryIds.includes(this.selectedCategory)) {
        this.requirementCategories = this.categoryList.filter(cat => this.groupedCategoryIds.includes(cat.id));
      } else {
        this.requirementCategories = this.categoryList.filter(cat => cat.id === this.selectedCategory);
      }
      this.categoryList && this.categoryList.length > 0 && !this.selectedCategory ? this.cartNewRequisitionForm.get('requirement_category_id').setValue(this.categoryList[0].id) : this.cartNewRequisitionForm.get('requirement_category_id').setValue(this.selectedCategory);
    }
  }

  availableCategory(categoryIds) {
    const categories = [];
    if (categoryIds && categoryIds.length > 0) {
      this.categoryList.forEach(x => {
        if (x && categoryIds.includes(x.id)) {
          categories.push(x.name);
        }
      });
    }
    return categories;
  }

  changePort(portId) {
    // this.isAllCategoryClicked = true;
    if (portId && this.role === this.userRoles.ROLE_CAPTAIN) {
      this.loading1 = true;
      this.loadingService.setLoading(true);
      let cartCategory = false;
      this.cartService.getCart(this.shipId, true).subscribe(res => {
        this.cart = res.data;
        this.rfqData = [];
        this.getProduct(this.selectedSubCategory, this.selectCategoryId);
        if (this.cart.length !== 0) {
          if (this.cart && this.cart[0].port_id) {
            this.previousPort = this.cart[0].port_id;
          }
          this.cart.find(x => {
            if (x.category.id === this.PROVISION_CATEGORY_ID && this.portId !== x.port_id) {
              cartCategory = true;
              this.cartPortName = x.port.name;
              this.selectItemToDelete(x);
              this.loading1 = false;
              this.loadingService.setLoading(false);
            } else {
              cartCategory = false;
              this.loading1 = false;
              this.loadingService.setLoading(false);
            }
          });
          if (cartCategory) {
            this.modalRef = this.modalService.show(
              this.portChange,
              Object.assign({ backdrop: 'static', class: 'gray modal-lg' })
            );
            this.altService.backModalClose(this.modalRef);
          }
        } else {
          if (this.selectCategoryId === this.PROVISION_CATEGORY_ID && this.selectedSubCategory && this.viewPrice && portId) {
            this.getProduct(this.selectedSubCategory, this.selectCategoryId);
          }
          this.loading1 = false;
          this.loadingService.setLoading(false);
        }
      });
    }
  }

  selectItemToDelete(cart) {
    const index = this.deleteSelected.findIndex(
      x => (x === cart.id)
    );
    if (index < 0) {
      this.deleteSelected.push(cart.id);
    } else if (index >= 0) {
      this.deleteSelected.splice(index, 1);
    }
  }

  removeCartItems() {
    if (this.deleteSelected.length > 0) {
      this.loading1 = true;
      this.loadingService.setLoading(true);
      this.cartService
        .removeFormCart(this.deleteSelected)
        .then(res => {
          if (res) {
            // this.getCartAllRequirements(this.shipId);
            // this.selectedRequirement = null;
            // this.selectedRequirementName = null;
            this.getProduct(this.selectedSubCategory, this.selectCategoryId);
            this.loading1 = false;
            this.loadingService.setLoading(false);
            this.toastr.success('Port and Its product(s) removed from the cart successfully', '',
              {
                closeButton: true,
                timeOut: 2000,
                positionClass: 'toast-bottom-left'
              });
          }
        }).catch(err => {
          this.loading1 = false;
          this.loadingService.setLoading(false);
        });
    }
  }

  portChanged() {
    if (this.shipId && this.portId) {
      this.loading1 = true;
      this.loadingService.setLoading(true);
      this.cartService
        .cartPortChange(this.shipId, this.portId).subscribe(res => {
          if (res) {
            this.deleteSelected = [];
            this.getProduct(this.selectedSubCategory, this.selectCategoryId);
            this.loading1 = false;
            this.loadingService.setLoading(false);
            this.toastr.success('Port Change Successfully', '',
              {
                closeButton: true,
                timeOut: 2000,
                positionClass: 'toast-bottom-left'
              });
          }
        }, error => {
          this.loading1 = false;
          this.loadingService.setLoading(false);
        });
    }
  }

  cancelPort() {
    this.portId = this.previousPort;
    this.deleteSelected = [];
    // this.productList=[];
    // this.total = null;
  }

  getShip() {
    return this.sharedService.getShip();
  }

  getShipList() {
    const page = this.page;
    const limit = 1000;
    this.shipService.getAll(page, limit).subscribe(res => {
      this.shipList = res.results;
      if (this.role == this.userRoles.ROLE_SUBADMIN) {
        this.shipList = this.shipList.filter(ship => this.subAdminCompanyIds && this.subAdminCompanyIds.includes(ship?.company?.id))
      }
      if (this.role != this.userRoles.ROLE_COMPANY_ADMIN && this.role != this.userRoles.ROLE_SUBADMIN) {
        this.shipList = this.shipList.filter(ship => {
          for (let i = 0; i < this.assignedShips.length; i++) {
            if (this.assignedShips[i].id == ship?.id)
              return true;
          }
          return false
        })
      }
      if (this.shipList.length > 0 && this.shipList.length === 1) {
        this.setShip(this.shipList[0]);
      }
    });
  }

  getPortList() {
    this.loading2 = true;
    this.portService.getAll(1, 1000).subscribe(res => {
      this.portList = res.results.filter(x => x.status === 1 && x.is_active === 1);
      this.loading2 = false;
      if (!this.portId) {
        this.loading2 = true;
      } else {
        this.loading2 = false;
      }
    });
  }

  async addItemToCart(item) {
    if (item.sub_category.category_ids.includes(this.PROVISION_CATEGORY_ID) && this.categorySelected === this.PROVISION_CATEGORY_ID &&
      this.portId == null && this.role === this.userRoles.ROLE_CAPTAIN && this.canPlaceOrder) {
      item.quantity = 0;
      this.alertService.error('Please select port');
      return;
    }
    if (!this.shipId) {
      item.quantity = 0;
      this.alertService.error('Please select ship');
      return;
    }
    if (!this.checkRequirementSelect(item)) {
      return false;
    }
    item.ship_id = this.shipId;
    item.user_id = this.authService.currentUserValue.data.user_details.user_id;
    const payload = this.buildCartPayload(item);
    if (payload.category_id == null) {
      this.toastr.error('Product category/subcategory not available', 'Category not available',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
      return;
    }
    if (isNaN(payload.quantity) || payload.quantity === '' || payload.quantity == null) {
      this.getProduct(this.selectedSubCategory, this.selectCategoryId, this.selectedAutocomplete, true);
      this.toastr.error('Please select a valid product quantity', 'Invalid quantity provided',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
      return;
    }
    if (item.quantity >= 0) {
      if (item.quantity == 0) {
        item.quantity = 0;
        if (item && item.cart_id) {
          this.remove(item, item.cart_id);
        }
      } else {
        await this.cartService
          .addToCart(payload)
          .then(res => {
            this.getProduct(this.selectedSubCategory, this.selectCategoryId, this.selectedAutocomplete, true);
            let text: string = `"${payload.name}" has been added to "${payload.requirement_name}" requisition in the cart `
            this.toastr.success(text, 'Added to cart',
              {
                closeButton: true,
                timeOut: 2000,
                positionClass: 'toast-bottom-left'
              });
          })
          .catch(err => {
            this.getProduct(this.selectedSubCategory, this.selectCategoryId, this.selectedAutocomplete, true);
            this.toastr.error(err.error, '',
              {
                closeButton: true,
                timeOut: 2000,
                positionClass: 'toast-bottom-left'
              });
          });
      }
    }
  }

  async remove(item, id) {
    await this.cartService
      .removeFormCart(id)
      .then(res => {
        this.getProduct(this.selectedSubCategory, this.selectCategoryId, this.selectedAutocomplete, true);
        this.toastr.info('Removed from Cart', 'Successfully',
          {
            closeButton: true,
            timeOut: 2000,
            positionClass: 'toast-bottom-left'
          });
      })
      .catch(err => {
        if (err.error) {
          this.getProduct(this.selectedSubCategory, this.selectCategoryId, this.selectedAutocomplete, true);
          this.toastr.error(err.error, '',
            {
              closeButton: true,
              timeOut: 2000,
              positionClass: 'toast-bottom-left'
            });
        } else {
          this.getProduct(this.selectedSubCategory, this.selectCategoryId, this.selectedAutocomplete, true);
          this.toastr.error('Please select a valid ship', 'Something went wrong',
            {
              closeButton: true,
              timeOut: 2000,
              positionClass: 'toast-bottom-left'
            });
        }
      });
  }

  searchProduct() {
    this.submitted = true;
    this.selectedAutocomplete = this.searchForm.value.search;
    this.page = 1;
    this.getProduct(this.selectedSubCategory, this.selectCategoryId, this.selectedAutocomplete);
    this.suggestion = [];
  }

  resetForm() {
    this.searchForm.get('search').setValue('');
    this.searchProduct();
  }

  buildDropdownForm() {
    this.searchForm = this.formBuilder.group({
      search: ['', [Validators.required]],
      category_id: [null]
    });
  }

  onPageChange($event) {
    this.page = $event;
    this.getProduct(this.selectedSubCategory, this.selectCategoryId, this.selectedAutocomplete);
  }

  onPackChange(productId, variantProducts, currentProduct) {
    const variantProduct = variantProducts.find(x => x.id === parseFloat(productId));
    currentProduct.id = variantProduct.id;
    currentProduct.product_id = variantProduct.id;
    currentProduct.name = variantProduct.name;
    currentProduct.product_code = variantProduct.product_code;
    currentProduct.impa_code = variantProduct.impa_code;
    currentProduct.pack_type = variantProduct.pack_type;
    currentProduct.images = variantProduct.images;
    currentProduct.vendor = variantProduct.vendor;
    currentProduct.rfq_submitted = variantProduct.rfq_submitted;
    currentProduct.quantity = variantProduct.quantity ? variantProduct.quantity : 0;
    if (variantProduct.quantity && variantProduct.quantity > 0) {
      currentProduct.cart_id = variantProduct.cart_id;
    }
    this.productDetail = currentProduct;
  }

  search(event) {
    this.searchQuery = event.query;
    this.productService
      .getProductSuggestion(this.selectedSubCategory, event.query, this.selectCategory)
      .subscribe(data => {
        data.data.map(x => {
          x.name = x._source.title;
          x.impa_code = x._source.impa_code;
          x.product_code = x._source.product_code;
          x.pack_type = x._source.pack_type;
          x.brand_name = x._source.brand_name ? x._source.brand_name : null
          x.unit = x._source.unit && x._source.unit.name ? x._source.unit.name : x._source.unit ? x._source.unit : '';
        });
        this.suggestion = data.data;
      }, error => {
        this.suggestion = [];
      });
  }

  showCrossBtn(event) {
    this.selectedAutocomplete = event.target.value;
  }

  doOnSelect(event) {
    this.page = 1;
    if (this.searchQuery === event.name) {
      this.selectedAutocomplete = event.name;
      this.searchForm.get('search').setValue(event.name);
      this.getProduct(this.selectedSubCategory, this.selectCategoryId, event.name);
    } else if (this.searchQuery === event._source.impa_code) {
      this.selectedAutocomplete = event._source.impa_code;
      this.searchForm.get('search').setValue(event._source.impa_code);
      this.getProduct(this.selectedSubCategory, this.selectCategoryId, event._source.impa_code);
    } else if (this.searchQuery === event._source.product_code) {
      this.selectedAutocomplete = event._source.product_code;
      this.searchForm.get('search').setValue(event._source.product_code);
      this.getProduct(this.selectedSubCategory, this.selectCategoryId, event._source.product_code);
    } else {
      this.selectedAutocomplete = event.name;
      this.searchForm.get('search').setValue(event.name);
      this.getProduct(this.selectedSubCategory, this.selectCategoryId, event.name);
    }
    this.suggestion = [];
  }

  buildCartPayload(item) {

    return {
      product_id: item.product_id,
      id: item.id,
      name: item.name,
      remark: item.remark || '',
      quantity: item.quantity,
      ship_id: this.shipId,
      category_id: this.selectedRequirement.category_id,
      port_id: (this.canPlaceOrder || this.userSource) && this.selectedRequirement.category_id === this.PROVISION_CATEGORY_ID ? this.portId : null,
      requirement_id: this.selectedRequirement ? this.selectedRequirement.id : null,
      requirement_name: this.selectedRequirement ? this.selectedRequirement.requirement_name : null,
    };
  }

  getCategoryName() {
    if (this.selectCategoryId) {
      // get Category
      const categoryObj = this.categoryList.find(
        x => x.id === this.selectCategoryId
      );
      if (categoryObj) {
        return categoryObj.name;
      }
    }
  }

  getSubCategoryName() {
    if (this.selectCategoryId) {
      const categoryObj = this.categoryList.find(
        x => x.id === this.selectCategoryId
      );
      // get Sub category
      const subCategoryObj = categoryObj.sub_categories.find(
        x => x.id === this.selectedSubCategory
      );
      if (subCategoryObj) {
        return subCategoryObj.name;
      }
    } else {
      return null;
    }
  }

  showModal(template) {
    if (!this.portId && !this.canPlaceReq && this.canPlaceOrder && this.role == this.userRoles.ROLE_CAPTAIN) {
      this.toastr.error('Please select port!', '',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
      return false;
    }
    this.modalRef = this.modalService.show(
      template,
    );
    this.altService.backModalClose(this.modalRef);
  }

  selectedfileName(files: FileList) {
    if (files.length > 0) {
      this.file = files[0];
    }
  }

  closeModalClicked(event) {
    this.modalRef.hide();
    if (event) {
      this.alertService.success('Product added successfully in your cart');
      this.getProduct(this.selectedSubCategory, this.selectCategoryId, this.selectedAutocomplete, true);
    }
  }
  selectRfq(vendorId, productId, i, isChecked) {
    if (this.portId == null) {
      this.alertService.error('Please select port!');
      return;
    } else {
      if (isChecked) {
        this.rfqPayloadBuilder(vendorId, productId);
        const vendorSelectedRfq = this.rfqData.filter(
          x => x.vendor_id === vendorId
        );
        // this.selectVendor(ischecked, vendor);
        // // console.log('vendor', vendorSelectedRfq);

        // if (
        //   vendorSelectedRfq.length ==
        //   this.orderDetails.length - vendor.price.length
        // ) {
        //   this.selectedAll[i] = true;
        // }
      } else {
        // this.selectedAll[i] = false;
        const index = this.rfqData.findIndex(
          x => (x.product_id = productId && x.vendor_id === vendorId)
        );
        this.rfqData.splice(index, 1);
      }
    }
  }

  rfqPayloadBuilder(vendorId, productId) {
    const paylaod = {
      product_id: productId,
      vendor_id: vendorId,
      port_id: this.portId,
      currency: this.currencyCode === 'USD' ? 'USD' : this.currencyCode === 'INR' ? 'INR' : 'JPY',
      requester_id: this.userDetails.user_id
    };
    this.rfqData.push(paylaod);
  }

  submitRFQ() {
    this.loading = true;
    this.rfqService.submitRfq(this.rfqData).subscribe(data => {
      this.toastr.success('RFQ submitted successfully', '',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
      this.getProduct(this.selectedSubCategory, this.selectCategoryId, this.selectedAutocomplete, true);
      this.rfqData = [];
      this.loading = false;
    }, error => {
      this.toastr.error(error, '',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
      this.loading = false;
    });
  }

  getCartAllRequirements(shipId: number) {
    this.cartService.getCartAllRequirements(shipId).subscribe((res) => {
      this.creatorRank = res.ranks.map(rank => rank);
      if (res && res.data.length > 0) {
        this.cartRequirements = res.data;
        this.requirementsDropdownDataSource = res.data;
        this.requirementsDropdownDataSource = this.requirementsDropdownDataSource.filter(x =>
          this.categoryList.find(index => index.id == x.category_id))
        if (this.canPlaceOrder && !this.canPlaceReq) {
          this.requirementsDropdownDataSource = this.cartRequirements.filter(x => x.category_id == 61);
        }
        this.setSubCategory(0, 0, false, true);
      } else {
        this.cartRequirements = null;
        // this.toastr.error(`No requirements found for this ship! Create new one.`);
      }
    }, error => {
      this.cartRequirements = null;
    });
  }

  checkRequirementSelect(item?) {
    if (!this.selectedRequirement) {
      this.alertService.error('Please select cart requisition name!');
      item.quantity = 0;
      return false;
    } else {
      if (!item.sub_category.category_ids.includes(this.selectedRequirement.category_id)) {
        this.getValidMessage(item);
        return false;
      }
      return true;
    }
  }

  getValidMessage(item?) {
    this.alertService.error('Selected Category is not matched with Requisition Category! Please select same category.');
    item.quantity = 0;
  }

  changeRequirement(event) {
    if (event) {
      this.selectedRequirement = event;
    } else {
      this.selectedRequirement = null;
    }
    this.getProduct(this.selectedSubCategory, this.selectCategoryId, this.selectedAutocomplete);
  }

  openNewRequirementPopup() {
    if (!this.shipId) {
      this.alertService.error('Please select ship');
      return;
    }
    this.cartNewRequisitionForm.reset();
    this.modalRef = this.modalService.show(
      this.cartNewRequirement,
      Object.assign({ backDrop: 'static', class: 'gray modal-small confirmation-box' })
    );
    this.altService.backModalClose(this.modalRef);
    this.submitted = false;
    this.checkSelectCategory();
  }

  createCartRequirement() {
    this.submitted = false;
    if (this.shipId) {
      this.submitted = true;
      if (!this.cartNewRequisitionForm.valid) {
        return false;
      } else {
        this.loading = true;
        const formData = new FormData()
        formData.append('requirement_category_id', this.cartNewRequisitionForm.get('requirement_category_id').value);
        formData.append('requirement_name', this.cartNewRequisitionForm.get('requirement_name').value);
        formData.append('creator_name', this.cartNewRequisitionForm.get('creator_name').value);
        formData.append('rank', this.cartNewRequisitionForm.get('rank').value);
        this.cartService.createCartRequirements(this.shipId, formData).subscribe((res: any) => {
          if (res) {
            this.submitted = false;
            this.loading = false;
            this.modalRef.hide();
            this.toastr.success('New Cart Requisition Successfully!', '',
              {
                closeButton: true,
                timeOut: 2000,
                positionClass: 'toast-bottom-left'
              });
            this.cartNewRequisitionForm.reset();
            this.getCartAllRequirements(this.shipId);
            this.selectedRequirement = res.data;
            this.getProduct(this.selectedSubCategory, this.selectCategoryId, this.selectedAutocomplete);
            this.selectedRequirementName = `<b>${res.data.requirement_name} |</b> ${res.data.category.name} <br/> Created By: <b>${res.data.creator_name}/${res.data.rank}</b>`;
          }
        }, error => {
          this.loading = false;
          this.toastr.error('Something went wrong! Try again.', '',
            {
              closeButton: true,
              timeOut: 2000,
              positionClass: 'toast-bottom-left'
            });
        });
      }
    } else {
      this.toastr.error('No Ship Found!', '',
        {
          closeButton: true,
          timeOut: 2000,
          positionClass: 'toast-bottom-left'
        });
    }
  }

  cancelRequirement() {
    this.modalRef.hide();
    this.submitted = false;
    this.processing = false;
  }

  selectCat() {
    return (this.canPlaceOrder && (this.selectCategoryId == 0 || this.selectCategoryId == 61 || this.selectCategory == 61))
  }
}
