import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  transform(value='') {
    if(value.includes('_')){
      let data = value.split('_');
      let str='';
        str+=data[0].slice(0,1).toUpperCase()+data[0].slice(1,)+' '+data[1];
      return str;   
    }
    let data = value.split(' ');
    let str='';
    data.forEach(data=>{
      str+=data.slice(0,1).toUpperCase()+data.slice(1,)+' ';
    })
    return str
  }

}
