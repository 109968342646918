import { CommonService } from '@app/shared/helper/common';
import { ToastrService } from 'ngx-toastr';
import { CategoryService } from '@app/modules/category/services/category.service';
import { UserRoles, Privilages } from './../../../../core/enums/user-roles';
import { Company } from './../../../company/models/company.modal';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { ProductStatusLabel } from './../../model/product-status';
import { ProductColumns } from './../../model/product-column';
import { Component, OnInit, TemplateRef, ViewChild, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Price, Product } from '../../model/product.model';
import { ProductService } from '../../services/product.service';
import {
  DataTableColumn,
  DataTablePagination
} from '@app/core/models/data-table';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginationData } from '@app/core/models/response.model';
import { ProductStatus } from '../../model/product-status';
import { SharedService } from '@app/shared/service/shared.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FileUpload } from 'primeng/fileupload';
import { Category } from '@app/modules/category/models/category.model';
import { NgSelectComponent } from '@ng-select/ng-select';
import { CompanyService } from '@app/modules/company/services/company.service';
import { PortService } from '@app/modules/port/port.service';
import { DatePipe } from '@angular/common';
import { RfqService } from '@app/modules/rfq/services/rfq.service';
import { AlertService } from '@app/shared/service/alert.service';
import { OrderService } from '@app/modules/order/service/order.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.css']
})
export class ProductListComponent implements OnInit, AfterContentChecked {
  rows = new Array<Product>();
  columns: DataTableColumn[] = ProductColumns;
  isLoading: boolean;
  page = new DataTablePagination();

  uploadForm: FormGroup;
  submitted = false;
  productList: Product[] = [];
  loading = false;
  openFilters: boolean;
  //toggleProductView = false;
  product: Product;
  companys: Company[] = [];
  msg: boolean;
  total: number;
  //isEditMode: boolean;
  role: number;
  company: Company;
  userRoles = UserRoles;
  filterForm: FormGroup;
  value;
  sortInfo;
  fileName: string;
  modalRef: BsModalRef;
  productExpiry: BsModalRef;
  loaderView = false;

  status = [{ name: 'Active', value: 1 }, { name: 'Inactive', value: 0 }];

  suggestion: Product[];
  selectedAutocomplete: string = null;

  @ViewChild('productTable', { static: false }) table;
  @ViewChild('productUpload', { static: false }) productUpload: FileUpload;
  @ViewChild('autocomplete', { static: false }) autocomplete;
  categories: any;
  formData: FormData;
  file: File;
  @ViewChild('ngCategorySelect', { static: false }) ngCategorySelect: NgSelectComponent;
  @ViewChild('ngPortSelect', { static: true }) ngPortSelect: NgSelectComponent;
  @ViewChild('ngSubCategorySelect', { static: true }) ngSubCategorySelect: NgSelectComponent;

  /*  actions: any[] = [{ name: 'Update Product If Shipskart Code Exist', id: 1 },
   { name: 'Create New Product If Shipskart Code Exist', id: 0 }]; */
  clickedPage: any;
  subCategoryList: any;
  subCategoryId: number;
  currentUrl: any;
  pageCount = 0;
  pageCount1 = 0;
  filterApplied = false;
  selectedProductId;
  categoryIds: number[] = [];
  currencyDropdown = [];
  vendorId: number;
  portId: number = null;
  portName: string;
  vendorName: string;
  portList = [];
  editPriceForm: FormGroup;
  selectedRow;
  editMode: boolean;
  currentDate = new Date();
  todayDate = new Date();
  selectedId: number;
  productPrices = false;
  selectedCurrency = 'USD';
  subAdminPrivileges: [number];
  showAddEditToSubAdmin: boolean = false;
  showAddEditProductPrices: boolean = false;
  modelRef: BsModalRef;
  selectFile: boolean = false;
  uploadProductPrices: FormGroup;
  dateTime = new Date();
  expireIn: number = 0;
  currency = {}
  vendor: Company;
  showExpiredProduct: boolean;
  goingToExpireProduct: number = 0;
  expiredProducts: any;
  inrPrice = 0
  usdPrice = 0
  jpyPrice = 0
  sgdPrice = 0
  eurPrice = 0
  inrConversionRate
  updatedData = {}
  usdConversionRate
  jpyConversionRate
  sgdConversionRate
  eurConversionRate
  inValidFileSize: boolean;
  isSelected: boolean;
  supportingDoc: string;
  showError: boolean;
  regex = new RegExp(/^(?:\d|[0-9]\d|9[0-9])(?:\.\d)?$/);
  brands
  attachedDocuments: any = [];
  priceHistory: boolean = false
  shippingCompanyList: any = [];
  shippingCompanyId: any;
  validityExpiryDate;
  selectedDate = '';
  constructor(
    private productService: ProductService,
    private router: Router,
    private cdref: ChangeDetectorRef,
    private sharedService: SharedService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private categoryService: CategoryService,
    private toastr: ToastrService,
    private commonService: CommonService,
    private activeRoute: ActivatedRoute,
    private companyService: CompanyService,
    private portService: PortService,
    private rfqService: RfqService,
    private datePipe: DatePipe,
    private alertService: AlertService,
    private companyservice: CompanyService,
    private orderService: OrderService
  ) {
    if (this.router.url.includes('product/prices')) {
      this.productPrices = true;
    } else {
      this.productPrices = false;
    }
    this.page.limit = 10;
    this.page.offset = 0;
    this.page.count = 0;
    this.formData = new FormData();
    this.currentDate.setDate(this.currentDate.getDate() + 7);
    this.currencyDropdown = this.sharedService.currencyDropdown;
    this.selectedCurrency = this.sharedService.defaultCurrency;
    this.role = this.sharedService.getUserRole();
    if (this.role == this.userRoles.ROLE_SUBADMIN) {
      this.subAdminPrivileges = this.sharedService.getSubAdminsPrivileges();
      if (this.subAdminPrivileges && this.subAdminPrivileges.length > 0) {
        this.showAddEditToSubAdmin = this.subAdminPrivileges.includes(Privilages.MANAGE_CATALOGUE);
        this.showAddEditProductPrices = this.subAdminPrivileges.includes(Privilages.MANAGE_PRODUCT_PRICES);
      }
    }

    this.company = this.sharedService.getCompany();

    if (this.role === this.userRoles.ROLE_COMPANY_ADMIN && this.company.type === 2) {
      this.vendorId = this.company.id;
      this.defaultCurrency = this.company.currency_code;
      //this.getExpiredProducts()
      this.getCompanyList();
    } else {
      if (this.productPrices) {
        this.companyList();
        this.getCompanyList();
      }
    }
  }

  ngOnInit() {
    this.uploadForm = this.formBuilder.group({
      category_id: [null, Validators.required],
      file_name: ['', Validators.required],
      // is_new: [0]
    });

    this.uploadProductPrices = this.formBuilder.group({
      port_id: [this.portId],
      file_name: [''],
      company_id: [null],
      vendor_id: [this.vendorId],
      transport_fee: [null],
      launch_fee: [null],
      expire_in: [null]
    })
    // this.getJson();
    this.filterForm = this.formBuilder.group({
      name: [''],
      impa_code: [''],
      product_code: [''],
      category_id: [''],
      vendor_id: [this.vendorId ? this.vendorId : null],
      port_id: [this.portId],
      status: this.vendorId ? 1 : null,
    });
    this.editPriceForm = this.formBuilder.group({
      currency: 'USD',
      price: ['', [Validators.required]],
      tax: ['0', [Validators.required]],
      expiryDate: [''],
      conversion_rates: new FormGroup({}),
      remark: '',
      pack_type: [null],
      brand_name: [null],
      brand_id: [null],
      supporting_documents: [null],
      deliverable_in: [null]
    });
    if (!this.productPrices) {
      this.allCategories();
    }
    this.activeRoute.queryParams.subscribe(params => {
      if (params.filter) {
        const filter: any = decodeURIComponent(params.filter);
        // console.log(filter);
        this.selectedAutocomplete = JSON.parse(filter).name;
        this.categoryIds = JSON.parse(filter).category_id;
        if (this.vendorId || filter && JSON.parse(filter).vendor_id) {
          this.vendorId = JSON.parse(filter).vendor_id;
          this.portId = JSON.parse(filter).port_id;
          // this.getCategories(this.vendorId)
        }
      } else {
        this.selectedAutocomplete = null;
        // this.vendorId == null;
        this.portId = null;
      }
      this.page.offset = parseInt(params.page) > 0 ? parseInt(params.page) - 1 : 0;
      this.currentUrl = { page: params.page, filter: params.filter };
      this.companyService.getCurrentUrl(this.currentUrl);
      if (this.pageCount > 0) {
        this.pageCount = 0;
        return;
      } else {
        if (!this.productPrices) {
          if (this.vendorId) {
            if (this.pageCount1 > 0) { return; }
            this.pageCount1 = 1;
            //this.getPort();// Api calls multiple time due to it
          } else {
            this.setPage(this.page);
          }
        }
        if (this.productPrices && this.role === this.userRoles.ROLE_COMPANY_ADMIN && this.company.type === 2) {
          if (this.pageCount1 > 0) { return; }
          this.pageCount1 = 1;
          this.getPort();// Api calls multiple time due to it
        }
      }
    });
    this.getAllBrands()
  }

  addExpiryDate(event) {
    console.log(event)
  }

  formReset() {
    this.uploadForm.reset();
  }
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
  onCellClicked(row) {
    this.router.navigate(['/product/details/', row.id]);
  }

  sortCallback(sortInfo: {
    sorts: { dir: string; prop: string }[];
    column: {};
    prevValue: string;
    newValue: string;
  }) {
    const params = new HttpParams()
      .set('orderby', `${sortInfo.sorts[0].dir}`)
      .set('page', `${this.page.offset + 1}`)
      .set('limit', `${this.page.limit}`)
      .set('coloum', `${sortInfo.sorts[0].prop}`);
    this.getData(params);
  }

  setPage(page: DataTablePagination, parms = {}) {
    this.vendorData = this.vendor ? this.vendor : this.company;
    this.defaultCurrency = this.vendorData.currency_code;
    // Server pagination start from 1
    this.isLoading = true;
    const pageNo = page.offset + 1;
    let params = new HttpParams()
      .set('page', `${pageNo}`)
      .set('limit', `${page.limit}`);
    if (this.categoryIds && this.categoryIds.length > 0) {
      params = params.append('category_id', this.categoryIds.toString());
    }
    if (this.productPrices && this.vendorId) {
      params = params.append('vendor_id', `${this.vendorId}`);
    }
    if (this.portId) {
      params = params.append('port_id', `${this.portId}`);
    }
    if (this.subCategoryId) {
      params = params.append('sub_category_id', this.subCategoryId.toString());
    }

    if (this.shippingCompanyId) {
      params = params.append('company_id', `${this.shippingCompanyId}`);
    } else {
      params.delete('company_id');
    }
    this.getData(params);

    // table scroll on pagination change
    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 5);
  }
  onPageChange($event, count) {
    this.clickedPage = $event.page;
    this.pageCount = count;
    this.page.offset = $event.page - 1;
    this.commonService.buildFilterUrlParm(this.filterForm, this.page.offset + 1);
  }

  getData(params, searchItem = null) {
    if (!this.filterForm.value.name) {
      this.filterForm.get('name').setValue('')
    }
    this.page = this.clickedPage ? this.page : this.commonService.CheckPage(this.page);
    // const pageNo = this.page.offset + 1;
    let filter;
    if ((this.role == this.userRoles.ROLE_ADMIN) || (this.role == this.userRoles.ROLE_SUBADMIN)) {
      filter = this.commonService.CheckFilter(this.filterForm);
      this.filterForm.patchValue(JSON.parse(filter));
      this.filterForm.value.category_id = null;
      if (this.productPrices) {
        this.filterForm.value.vendor_id = null;
        this.filterForm.value.port_id = null;
      }
      filter = JSON.stringify(this.filterForm.value);
    } else {
      if (Object.keys(this.filterForm.value).some(key => this.filterForm.value[key] != null && this.filterForm.value[key] !== '')) {
        this.filterForm.value.category_id = null;
        filter = JSON.stringify(this.filterForm.value);
      } else {
        filter = JSON.stringify(this.filterForm.reset());
      }
    }
    const filterFormValue = JSON.parse(filter);
    this.filterForm.patchValue(filterFormValue);
    const headers = new HttpHeaders().set(
      'x-filter',
      filter
    );
    this.filterForm.get('category_id').setValue(this.categoryIds);
    if ((this.role == this.userRoles.ROLE_ADMIN) || (this.role == this.userRoles.ROLE_SUBADMIN) && this.productPrices) {
      this.filterForm.value.vendor_id = this.vendorId;
      this.filterForm.value.port_id = this.portId;
    }
    this.productService
      .getAll(params, headers, this.productPrices)
      .subscribe((ProductData: PaginationData) => {
        this.rows = ProductData.results.map((product: Product) => {
          const rowData = {
            id: product.id,
            name: product.name,
            sub_category: product.sub_category ? product.sub_category.name : '',
            category: product.category ? product.category.name : '',
            impa_code: product.impa_code || 'N/A',
            product_code: product.product_code,
            unit: product.unit.name ? product.unit.name : '',
            pack_type: product.pack_type ? product.pack_type : '',
            remark: product.remark ? product.remark : '',
            tax: product.tax ? product.tax : '',
            brand_name: product?.brand?.name,
            country_id: product.country_id ? product.country_id : null,
            inrPrice: product?.price == null || (product?.price && product?.price?.inr == null) ? null : product?.price?.inr,
            usdPrice: product?.price == null || (product?.price && product?.price?.usd == null) ? null : product?.price?.usd,
            jpyPrice: product?.price == null || (product?.price && product?.price?.jpy == null) ? null : product?.price?.jpy,
            sgdPrice: product?.price == null || (product?.price && product?.price?.sgd == null) ? null : product?.price?.sgd,
            eurPrice: product?.price == null || (product?.price && product?.price?.eur == null) ? null : product?.price?.eur,
            product_type: product.id === product.variant_product_id ?
              product.variants && product.variants.length > 1 ? 'Parent' : 'Individual' : 'Variant',
            status: this.getStatus(product.status),
            conversion_rates: product.conversion_rates,
            expiry_date: product?.price == null || (product?.price && product?.price?.inr?.expiry_date == null) ? null : product?.price?.inr?.expiry_date,
            deliverable_in: product?.price == null ? null : this.getDeliverable(product.price)
          };
          //rowData.unit = rowData.pack_type + ' ' + rowData.unit;
          // rowData.inrPrice = this.currencyPrice(rowData.inrPrice, rowData);
          return rowData;
        });
        this.page.count = ProductData.total;
        this.isLoading = false;
        if (this.vendorId)
          this.getExpiredProducts()
      }, error => {
        this.isLoading = false;
      });
  }

  getStatus(status: number) {
    switch (status) {
      case ProductStatus.Active:
        return ProductStatusLabel.Active;
      case ProductStatus.InActive:
        return ProductStatusLabel.InActive;
      default:
        return status;
    }
  }

  /* getCategories(event) {
    let id = event && event.id ? event.id : this.vendorId;
    this.companyService.getCategoryByID(id).subscribe(data => {
      this.categories = data;
    })
  } */

  /* currencyPrice(price?) {
    if (price && price.length > 0) {
      price.forEach(elem => {
        if (elem) {

        }    
      });
      return price;
    } else {
      return null;  
    }
  } */

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({ backdrop: 'static', keyboard: false, class: 'gray product-modal' })
    );
    this.alertService.backModalClose(this.modalRef);
  }

  productExpiryModal(template: TemplateRef<any>, product_id = null) {
    if (product_id)
      this.selectedProductId = product_id;
    this.productExpiry = this.modalService.show(
      template,
      Object.assign({ backdrop: 'static', keyboard: false, class: 'gray product-modal' })
    );
    this.alertService.backModalClose(this.modalRef);
  }

  printData(data) {
    let date = new Date(data);
    this.selectedDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  submitExpiryDate() {
    this.updatedData['price_id'] = this.selectedProductId;
    this.updatedData['expiry_date'] = this.selectedDate;
  }

  updateExpiryDate() {
    this.productService.updateExpiryDate(this.updatedData).subscribe(response => {
      this.toastr.success('Expiry Date Updated Successfully', 'Success');
      this.setPage(this.page);
    }, error => {
      this.toastr.error(error.error)
    })
    this.selectedDate = null;
    this.selectedProductId = null;
  }

  resetValues() {
    this.selectedDate = null;
    this.selectedProductId = null;
  }

  selectedfileName(files: FileList) {
    if (files.length > 0) {
      this.fileName = files[0].name;
      this.file = files[0];
    }
    this.selectFile = true;
  }

  resetImportForm() {
    this.uploadForm.reset();
    this.fileName = null;
    this.file = null;
  }

  uploadProduct() {
    if (this.uploadForm.valid) {
      const formData = new FormData();
      // const is_edit = +(this.uploadForm.value.is_new);
      formData.append('file', this.file, this.fileName);
      formData.append('file_name', this.fileName);
      formData.append('category_id', this.uploadForm.value.category_id);
      // formData.append('is_new', is_edit.toString());

      this.isLoading = true;
      this.productService.uploadProduct(formData).subscribe(
        data => {
          this.toastr.success('Product Successfully Import');
          this.modalRef.hide();
          this.isLoading = false;
          this.file = this.fileName = null;
          this.setPage(this.page);
          this.uploadForm.reset();  // #TODO  Due To upload product >> Click filter >> upload form auto hits# ERROR
        },
        error => {
          this.toastr.error('Something Went Wrong', error.error);
          this.isLoading = false;
        }
      );
    }
  }
  filterData(count) {
    if (this.filterForm.value.name) {
      this.filterForm.get('name').setValue(this.filterForm.value.name.trim());
    }
    let selectedname = this.filterForm.value.name;
    this.autocomplete.hide();
    this.pageCount = count;
    this.filterApplied = true;
    if (this.filterForm.valid && (count == 1 || selectedname)) {
      this.isLoading = true;
      this.page.limit = 10;
      this.page.offset = 0;
      if (this.vendorId) {
        this.filterForm.value.vendor_id = this.vendorId;
        this.filterForm.value.port_id = this.portId;
      }
      this.filterFormData();
      /* this.commonService.buildFilterUrlParm(this.filterForm);
      let params = new HttpParams()
        .set('page', `${this.page.offset + 1}`)
        .set('limit', `${this.page.limit}`);
      if (this.categoryIds && this.categoryIds.length > 0) {
        params = params.append('category_id', this.categoryIds.toString());
      }
      if (this.productPrices && this.vendorId) { params = params.append('vendor_id', `${this.vendorId}`); }
      if (this.portId) { params = params.append('port_id', `${this.portId}`); }
      setTimeout(() => {
        this.getData(params, true);
      }); */
    } else {
      this.selectedAutocomplete = ''
      return;
    }
    this.openFilters = false;
  }
  resetForm(count) {
    this.pageCount = count;
    this.filterApplied = false;
    if (count > 1) {
      const status = this.filterForm.get('status').value;
      this.filterForm.patchValue({
        name: '',
        product_code: '',
        port_id: this.portId,
        vendor_id: this.vendorId ? this.vendorId : null,
        status: this.vendorId ? 1 : null,
      });
      if (!this.vendorId) {
        this.filterForm.get('status').setValue(status);
      }
    } else {
      this.filterForm.reset({
        name: '',
        impa_code: '',
        product_code: null,
        category_id: null,
        port_id: this.portId,
        vendor_id: this.vendorId ? this.vendorId : null,
        status: this.vendorId ? 1 : null,
      });
      this.categoryIds = [];
      this.ngCategorySelect.handleClearClick();
    }
    this.selectedAutocomplete = null;
    this.isLoading = true;
    this.page.offset = 0;
    this.filterFormData();
    /* this.commonService.buildFilterUrlParm(this.filterForm);
    let params = new HttpParams()
      .set('page', `${this.page.offset + 1}`)
      .set('limit', `${this.page.limit}`);
    if (this.categoryIds && this.categoryIds.length > 0) {
      params = params.append('category_id', this.categoryIds.toString());
    }
    if (this.productPrices && this.vendorId) { params = params.append('vendor_id', `${this.vendorId}`); }
    if (this.portId) { params = params.append('port_id', `${this.portId}`); }
    setTimeout(() => {
      this.getData(params);
    }); */
    this.openFilters = false;
  }

  filterFormData() {
    this.commonService.buildFilterUrlParm(this.filterForm);
    let params = new HttpParams()
      .set('page', `${this.page.offset + 1}`)
      .set('limit', `${this.page.limit}`);
    if (this.categoryIds && this.categoryIds.length > 0) {
      params = params.append('category_id', this.categoryIds.toString());
    }
    if (this.productPrices && this.vendorId) { params = params.append('vendor_id', `${this.vendorId}`); }
    if (this.productPrices && this.shippingCompanyId) { params = params.append('company_id', `${this.shippingCompanyId}`); }
    if (this.portId) { params = params.append('port_id', `${this.portId}`); }
    setTimeout(() => {
      this.getData(params, true);
    });
  }

  allCategories() {
    this.categoryService.getAllV2(1, 20).subscribe(data => {
      this.categories = data.data;
    });
  }

  openExportModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({ backdrop: 'static', keyboard: false, class: 'gray modal-small product-modal' })
    );
    this.alertService.backModalClose(this.modalRef);
  }

  exportProduct() {
    this.submitted = true;
    if (this.categoryIds && this.categoryIds.length === 0) {
      return false;
    }
    this.isLoading = true;
    let params;
    const pageNo = this.page.offset + 1;
    const header = new HttpHeaders()
      .set('x-file', 'xlsx')
      .set('x-filter', '');

    params = new HttpParams()
      .set('page', `${pageNo}`)
      .set('limit', `${this.page.limit}`);

    if (this.categoryIds && this.categoryIds.length > 0) {
      params = params.append('category_id', this.categoryIds.toString());
    }

    if (this.subCategoryId) {
      params = params.delete('category_id');
      params = params.append('subcategory_id', this.subCategoryId.toString());
    }

    this.productService.export(params, header).subscribe(data => {
      this.sharedService.saveAsBlob(
        data,
        'application/vnd.ms-excel',
        `products_list.xlsx`
      );
      this.isLoading = false;
      this.submitted = false;
      //this.categoryIds = [];
      this.subCategoryId = null;
      this.modalRef.hide();
      // this.toastr.success('Successfully export');
    }, error => {
      this.isLoading = false;
      this.submitted = false;
      this.toastr.error('Something went wrong !');
    }
    );
  }

  search(event) {
    if (this.filterForm.valid) {
      this.productService
        .getProductSuggestion(0, event.query)
        .subscribe(data => {
          data.data.map(x => {
            x.name = x._source.title;
            x.impa_code = x._source.impa_code;
            x.pack_type = x._source.pack_type;
            x.product_code = x._source.product_code;
            x.unit = x._source.unit || (x._source.unit && x._source.unit.name) ? x._source.unit || x._source.unit.name : null;
          });
          this.suggestion = data.data;
        }, error => {
          this.suggestion = [];
        });
    }
    else {
      this.selectedAutocomplete = null;
      this.filterForm.get('name').setValue('');
    }
  }

  showCrossBtn(event) {
    this.selectedAutocomplete = event.target.value;
  }

  doOnSelect(event) {
    let eventData = '';
    if (event && event.name) {
      eventData = event.name;
    }
    this.selectedAutocomplete = eventData;
    this.filterForm.get('name').setValue(eventData);
    //this.filterForm.get('product_code').setValue(event.product_code);
    this.filterData(1);
    this.suggestion = [];
  }
  /* getJson() {
    this.productService.getjosnData().subscribe(data => {
    });
  } */

  categorySelected(event) {
    this.categoryIds = [];
    if (event && event.length > 0) {
      this.submitted = false;
      const subCategories = [];

      event.forEach(elem => {
        if (elem) {
          this.categoryIds.push(elem.id);
          if (elem.sub_categories && elem.sub_categories.length > 0) {
            elem.sub_categories.forEach(item => {
              if (item && subCategories.find(x => x.id === item.id)) { } else { subCategories.push(item); }
            });
          }
        }
      });
      this.subCategoryList = subCategories;
    }
  }

  categoryChange(categories: Category[]) {
    if (categories && categories.length > 0) {
      this.categoryIds = [];
      categories.forEach(category => {
        this.categoryIds.push(category.id);
      });
    } else {
      this.categoryIds = [];
    }
  }

  selectSubcategory(event) {
    this.subCategoryId = null;
    if (event && event.length > 0) {
      event.forEach(item => {
        if (event) {
          this.subCategoryId = event.id;
        }
      });
    }
    this.setPage(this.page);
  }

  filteBySubcategory(event) {
    //this.subCategoryId = [];
    if (event != undefined)
      this.subCategoryId = event.id;
    this.setPage(this.page);
  }
  getPort() {
    this.portService.getByVendor(this.vendorId, 1, 500).subscribe(res => {
      this.portList = res;
      if (this.portList.length > 0) {
        this.portId = this.portId ? this.portId : this.portList[0].port_id;
        this.portName = this.portId ? this.portList.find(x => x.port_id === this.portId).port : this.portList[0].port;
      }
      this.filterForm.get('vendor_id').setValue(this.vendorId);
      this.filterForm.get('port_id').setValue(this.portId);
      this.commonService.buildFilterUrlParm(this.filterForm, this.page.offset + 1);
      this.companyservice.getSubCategories(this.vendorId).subscribe(data => {
        const subCatList: any = data;
        if (subCatList && subCatList.length > 0) {
          subCatList.unshift({ name: 'All Subcategories', id: 0 });
        }
        this.subCategoryList = subCatList.map(v => ({ ...v, disabled: false }));
      });
      this.setPage(this.page);
    });
  }
  companyList() {
    this.loading = true;
    this.companyService.getVendors(1, 1000, 0).subscribe(data => {
      this.companys = data.results;
      if (this.companys) {
        this.vendor = this.companys.find(x => x.id === this.vendorId);
      }
      if (data.results && data.results.length > 0) {
        this.vendorId = this.vendorId ? this.vendorId : data.results[0].id;

        //Subscribing company improvement by vendorId gettting vendors related data i.e. category,convesion_rate
        this.companyService.getVendorDetailsByID(this.vendorId, 1, 1000, 2).subscribe(data => {
          this.categories = data.results.map(cat => cat.category_deals_on)
        })
        //this.categories = this.vendorId ? data.results.find(x => x.id === this.vendorId).category.map(cat => cat.category_deals_on) : this.companys[0].category.map(cat => cat.category_deals_on)
        this.vendorName = this.vendorId ? data.results.find(x => x.id === this.vendorId).name : data.results[0].name;
        this.getPort();
        //this.sortCategory(this.categories);
      }
      this.loading = false;
    });
  }
  defaultCurrency
  vendorEvent(event) {
    this.filterForm.get('category_id').setValue(null);
    this.categoryIds = []
    this.defaultCurrency = event.currency_code;
    this.vendorName = event.name;
    this.portId = null;
    this.vendor = event;
    this.filterCategory(this.vendor)
    //this.getExpiredProducts();
    this.checkVendorCurrency(event)
  }

  checkVendorCurrency(event: any) {
    if (event && event.conversion_rates) {
      this.currency = event && event.conversion_rates.reduce((acc, cur) => ({ ...acc, [cur.to_currency_code]: cur.rate }), {})
      switch (event.currency_code) {
        case "USD":
          delete this.currency['USD'];
          this.formData.append('conversion_rates', JSON.stringify(this.currency));
          break;
        case "INR":
          delete this.currency['INR'];
          this.formData.append('conversion_rates', JSON.stringify(this.currency));
          break;
        case "JPY":
          delete this.currency['JPY'];
          this.formData.append('conversion_rates', JSON.stringify(this.currency));
          break;
        case "SGD":
          delete this.currency['SGD'];
          this.formData.append('conversion_rates', JSON.stringify(this.currency));
          break;
        case "EUR":
          delete this.currency['EUR'];
          this.formData.append('conversion_rates', JSON.stringify(this.currency));
          break;
      }
    }

  }
  port
  setPort(event) {
    this.portId = event.port_id ? event.port_id : this.portId;
    this.portName = event.port;
    this.filterForm.get('vendor_id').setValue(this.vendorId);
    this.filterForm.get('port_id').setValue(this.portId);
    this.commonService.buildFilterUrlParm(this.filterForm);
    this.page.offset = 0;
    this.port = event;
    this.setPage(this.page);
  }
  get form() {
    return this.editPriceForm.controls;
  }

  get productPriceForm() {
    return this.uploadProductPrices.controls;
  }
  vendorData
  openPriceModal(template: TemplateRef<any>, row, currencySelect) {
    this.selectedRow = row;
    this.editPriceForm.reset({ currency: currencySelect });
    this.vendorData = this.vendor ? this.vendor : this.company;
    this.checkDefaultPrice();
    this.changePrice(this.vendorData)
    switch (true) {
      case (this.vendor && this.vendor.currency_code === 'INR' || this.vendorData.currency_code == 'INR') && this.selectedRow && this.selectedRow.inrPrice !== null && this.selectedRow.inrPrice.price > 0:
        this.editFormPatch(this.selectedRow.inrPrice, this.vendor && this.vendor.currency_code ? this.vendor.currency_code : this.vendorData.currency_code);
        break;
      case (this.vendor && this.vendor.currency_code === 'USD' || this.vendorData.currency_code == 'USD') && this.selectedRow && this.selectedRow.usdPrice !== null && this.selectedRow.usdPrice.price > 0:
        this.editFormPatch(this.selectedRow.usdPrice, this.vendor && this.vendor.currency_code ? this.vendor.currency_code : this.vendorData.currency_code);
        break;
      case (this.vendor && this.vendor.currency_code === 'JPY' || this.vendorData.currency_code == 'JPY') && this.selectedRow && this.selectedRow.jpyPrice !== null && this.selectedRow.jpyPrice.price > 0:
        this.editFormPatch(this.selectedRow.jpyPrice, this.vendor && this.vendor.currency_code ? this.vendor.currency_code : this.vendorData.currency_code);
        break;
      case (this.vendor && this.vendor.currency_code === 'SGD' || this.vendorData.currency_code == 'SGD') && this.selectedRow && this.selectedRow.sgdPrice !== null && this.selectedRow.sgdPrice.price > 0:
        this.editFormPatch(this.selectedRow.sgdPrice, this.vendor && this.vendor.currency_code ? this.vendor.currency_code : this.vendorData.currency_code);
        break;
      case (this.vendor && this.vendor.currency_code === 'EUR' || this.vendorData.currency_code == 'EUR') && this.selectedRow && this.selectedRow.eurPrice !== null && this.selectedRow.eurPrice.price > 0:
        this.editFormPatch(this.selectedRow.eurPrice, this.vendor && this.vendor.currency_code ? this.vendor.currency_code : this.vendorData.currency_code);
        break;
      default:
        this.editPriceForm.patchValue({
          tax: (this.selectedRow && this.selectedRow.tax > 0 && this.selectedRow.country_id === 1) ? this.selectedRow.tax : '0',
        });
        this.editMode = false;
    }

    /* if (currencySelect === 'INR' && this.selectedRow && this.selectedRow.inrPrice !== null && this.selectedRow.inrPrice.price > 0) {
      this.editFormPatch(this.selectedRow.inrPrice, currencySelect);
    } else if (currencySelect === 'USD' && this.selectedRow && this.selectedRow.usdPrice !== null && this.selectedRow.usdPrice.price > 0) {
      this.editFormPatch(this.selectedRow.usdPrice, currencySelect);
    } else if (currencySelect === 'JPY' && this.selectedRow && this.selectedRow.jpyPrice !== null && this.selectedRow.jpyPrice.price > 0) {
      this.editFormPatch(this.selectedRow.jpyPrice, currencySelect); 
    } else {
      this.editPriceForm.patchValue({
        tax: (this.selectedRow && this.selectedRow.tax > 0 && this.selectedRow.country_id === 1) ? this.selectedRow.tax : '',
      });
      this.editMode = false;
    } */
    this.inrPrice = this.selectedRow.inrPrice && this.selectedRow.inrPrice.price ? this.selectedRow.inrPrice.price : 0;
    this.usdPrice = this.selectedRow.usdPrice && this.selectedRow.usdPrice.price ? this.selectedRow.usdPrice.price : 0
    this.jpyPrice = this.selectedRow.jpyPrice && this.selectedRow.jpyPrice.price ? this.selectedRow.jpyPrice.price : 0
    this.sgdPrice = this.selectedRow.sgdPrice && this.selectedRow.sgdPrice.price ? this.selectedRow.sgdPrice.price : 0
    this.eurPrice = this.selectedRow.eurPrice && this.selectedRow.eurPrice.price ? this.selectedRow.eurPrice.price : 0
    this.modalRef = this.modalService.show(
      template,
      Object.assign({ backdrop: 'static', keyboard: false, class: 'gray modal-lg' })
    );
    this.alertService.backModalClose(this.modalRef);
  }

  editFormPatch(price, currency) {
    this.editMode = true;
    this.editPriceForm.patchValue({
      currency: currency,
      price: (price && price.price > 0) ?
        this.getPrice(price.price, price.tax) : '',
      tax: (price && price.tax >= 0) ? price.tax : '',
      expiryDate: (price && price.expiry_date) ?
        this.changeDate(price.expiry_date, null) : null,
      remark: (price && price.remark) ? price.remark : '',
      validityDate: price.expiry_date || null,
      pack_type: price && price.pack_type ? price.pack_type : null,
      brand_name: price && price.brand_name ? price.brand_name : null,
      supporting_documents: price && price.supporting_documents ? price.supporting_documents : null,
      conversion_rates: (price && price.conversion_rates) ? price.conversion_rates : '',
      deliverable_in: (price && price.deliverable_in) ? price.deliverable_in : null
    });
  }

  addPrice() {
    this.submitted = true;
    if (!this.editPriceForm.valid) {
      return false;
    }
    if (this.editPriceForm.get('price').value == 0) {
      this.toastr.error('Price should not be zero!');
      return false;
    }
    this.isLoading = true;
    let expiry
    if (this.editPriceForm.get('expiryDate').value) {
      let date = new Date();
      date.setDate(date.getDate() + parseInt(this.editPriceForm.get('expiryDate').value))
      expiry = this.datePipe.transform(date, 'yyyy-MM-dd')
    }
    const payload = [{
      product_id: this.selectedRow.id,
      vendor_id: this.vendorId,
      price: this.editPriceForm.get('price').value > 0 ? this.editPriceForm.get('price').value : 0,
      port_id: this.portId,
      tax: this.editPriceForm.get('tax').value,
      product: this.selectedRow.name,
      expiry_date: this.editPriceForm.get('expiryDate').value ?
        expiry : undefined,
      currency: this.vendor ? this.vendor.currency_code : this.vendorData.currency_code,
      remark: this.editPriceForm.get('remark').value ? this.editPriceForm.get('remark').value : '',
      pack_type: this.editPriceForm.get('pack_type').value ? this.editPriceForm.get('pack_type').value : '',
      brand_name: this.editPriceForm.get('brand_name').value ? this.editPriceForm.get('brand_name').value : '',
      supporting_documents: this.editPriceForm.get('supporting_documents').value ? this.editPriceForm.get('supporting_documents').value : null,
      deliverable_in: this.editPriceForm.get('deliverable_in').value ? this.editPriceForm.get('deliverable_in').value : null,
      conversion_rates: this.editPriceForm.get('conversion_rates').value ? JSON.stringify(this.editPriceForm.get('conversion_rates').value) : ''
    }];
    console.log(payload)
    if (this.shippingCompanyId) {

      payload[0]['company_id'] = this.shippingCompanyId
    }
    this.rfqService.updatePrice(payload)
      .subscribe(data => {
        this.modalRef.hide();
        this.toastr.success(data.data);

        this.isLoading = false;
        this.submitted = false;
        /* this.editPriceForm.reset({
          currency: 'USD'
        }); */
        this.setPage(this.page);
      }, error => {
        this.toastr.error(error ? error.error : 'Something went wrong');
        this.isLoading = false;
        this.submitted = false;
      });
  }

  editPrice() {
    this.submitted = true;
    if (!this.editPriceForm.valid) {
      return false;
    }
    if (this.editPriceForm.get('price').value == 0) {
      this.toastr.error('Price should not be zero!');
      return false;
    }
    this.isLoading = true;
    let expiry
    if (this.editPriceForm.get('expiryDate').value) {
      let date = new Date();
      date.setDate(date.getDate() + parseInt(this.editPriceForm.get('expiryDate').value))
      expiry = this.datePipe.transform(date, 'yyyy-MM-dd')
    }
    const payload = [{
      product_id: this.selectedRow.id,
      vendor_id: this.vendorId,
      price: this.editPriceForm.get('price').value > 0 ? this.editPriceForm.get('price').value : 0,
      port_id: this.portId,
      tax: this.editPriceForm.get('tax').value,
      product: this.selectedRow.name,
      expiry_date: this.editPriceForm.get('expiryDate').value ?
        expiry : undefined,
      currency: this.vendor ? this.vendor.currency_code : this.vendorData.currency_code,
      remark: this.editPriceForm.get('remark').value ? this.editPriceForm.get('remark').value : '',
      deliverable_in: this.editPriceForm.get('deliverable_in').value ? this.editPriceForm.get('deliverable_in').value : null,
      pack_type: this.editPriceForm.get('pack_type').value ? this.editPriceForm.get('pack_type').value : '',
      brand_name: this.editPriceForm.get('brand_name').value ? this.editPriceForm.get('brand_name').value : '',
      supporting_documents: this.editPriceForm.get('supporting_documents').value ? this.editPriceForm.get('supporting_documents').value : null,
      conversion_rates: this.editPriceForm.get('conversion_rates').value ? JSON.stringify(this.editPriceForm.get('conversion_rates').value) : ''
    }];
    if (this.shippingCompanyId) {

      payload[0]['company_id'] = this.shippingCompanyId
    }
    /* const payload = [{
      id: (this.editPriceForm.get('currency').value === 'INR' && this.selectedRow.inrPrice && this.selectedRow.inrPrice.price > 0) ?
        this.selectedRow.inrPrice.id : (this.editPriceForm.get('currency').value === 'USD' && this.selectedRow.usdPrice && this.selectedRow.usdPrice.price > 0) ?
          this.selectedRow.usdPrice.id : this.selectedRow.jpyPrice && this.selectedRow.jpyPrice.price > 0 ? this.selectedRow.jpyPrice.id : '',
      price: this.editPriceForm.get('price').value,
      tax: this.editPriceForm.get('tax').value,
      expiry_date: this.editPriceForm.get('expiryDate').value ?
        this.datePipe.transform(this.editPriceForm.get('expiryDate').value, 'yyyy-MM-dd') : undefined,
      remark: this.editPriceForm.get('remark').value ? this.editPriceForm.get('remark').value : '',
      currency: this.vendor ? this.vendor.currency_code : '',
      conversion_rates: this.editPriceForm.get('conversion_rates').value ? JSON.stringify(this.editPriceForm.get('conversion_rates').value) : ''
    }]; */
    this.rfqService.updatePrice(payload)
      .subscribe(data => {
        this.modalRef.hide();
        this.toastr.success(data.data);
        this.isLoading = false;
        this.submitted = false;
        this.setPage(this.page);
      }, error => {
        this.toastr.error(error ? error.error : 'Something went wrong');
        this.isLoading = false;
        this.submitted = false;
      });
  }

  currencySwitch(event) {
    if (event && this.selectedRow) {
      this.editPriceForm.reset({ currency: event.id });
      switch (true) {
        case event.id === 'INR' && this.selectedRow && this.selectedRow.inrPrice !== null && this.selectedRow.inrPrice.price > 0:
          this.editFormPatch(this.selectedRow.inrPrice, event.id);
          break;
        case event.id === 'USD' && this.selectedRow && this.selectedRow.usdPrice !== null && this.selectedRow.usdPrice.price > 0:
          this.editFormPatch(this.selectedRow.usdPrice, event.id);
          break;
        case event.id === 'JPY' && this.selectedRow && this.selectedRow.jpyPrice !== null && this.selectedRow.jpyPrice.price > 0:
          this.editFormPatch(this.selectedRow.jpyPrice, event.id);
          break;
        case event.id === 'SGD' && this.selectedRow && this.selectedRow.sgdPrice !== null && this.selectedRow.sgdPrice.price > 0:
          this.editFormPatch(this.selectedRow.sgdPrice, event.id);
          break;
        case event.id === 'EUR' && this.selectedRow && this.selectedRow.eurPrice !== null && this.selectedRow.eurPrice.price > 0:
          this.editFormPatch(this.selectedRow.eurPrice, event.id);
          break;
        default:
          this.editPriceForm.patchValue({
            tax: (this.selectedRow && this.selectedRow.tax > 0 && this.selectedRow.country_id === 1) ? this.selectedRow.tax : '',
          });
          this.editMode = false;
      }
      /* if (event.id === 'INR' && this.selectedRow && this.selectedRow.inrPrice !== null && this.selectedRow.inrPrice.price > 0) {
        this.editFormPatch(this.selectedRow.inrPrice, event.id);
      } else if (event.id === 'USD' && this.selectedRow && this.selectedRow.usdPrice !== null && this.selectedRow.usdPrice.price > 0) {
        this.editFormPatch(this.selectedRow.usdPrice, event.id);
      } else if (event.id === 'JPY' && this.selectedRow && this.selectedRow.jpyPrice !== null && this.selectedRow.jpyPrice.price > 0) {
        this.editFormPatch(this.selectedRow.jpyPrice, event.id);
      } else {
        this.editPriceForm.patchValue({
          tax: (this.selectedRow && this.selectedRow.tax > 0 && this.selectedRow.country_id === 1) ? this.selectedRow.tax : '',
        });
        this.editMode = false;
      } */
    }
  }

  getPrice(price, tax) {
    const x = tax + 100;
    const y = price * 100;
    const principalAmt = y / x;
    const finalPrice = tax / 100 * principalAmt;
    return (price - finalPrice).toFixed(2);
  }

  openDelete(template: TemplateRef<any>) {
    if (this.editPriceForm.get('currency').value === 'INR' && this.selectedRow.inrPrice !== null && this.selectedRow.inrPrice.id) {
      this.selectedId = this.selectedRow.inrPrice.id;
    } else if (this.editPriceForm.get('currency').value === 'USD' && this.selectedRow.usdPrice !== null && this.selectedRow.usdPrice.id) {
      this.selectedId = this.selectedRow.usdPrice.id;
    } else {
      this.selectedId = this.selectedRow.jpyPrice.id;
    }
    this.modalRef = this.modalService.show(
      template,
      Object.assign({ backdrop: 'static', keyboard: false, class: 'gray modal-small product-modal' })
    );
    this.alertService.backModalClose(this.modalRef);
  }

  deleteRequest(id) {
    this.isLoading = true;
    this.rfqService.deleteRequest(id).subscribe(data => {
      this.toastr.success(data.data);
      this.modalRef.hide();
      this.isLoading = false;
      this.setPage(this.page);
    }, error => {
      this.toastr.error(error ? error.error : 'Something went wrong!');
      this.isLoading = false;
    });
  }
  hideModal() {
    if (!this.filterApplied) {
      this.categoryIds = [];
      this.subCategoryId = null
    }
    this.modalRef.hide();
  }
  currencyCode: string;
  price
  get conversionRate(): FormGroup {
    return this.editPriceForm.get('conversion_rates') as FormGroup;
  }
  checkDefaultPrice() {
    this.currencyCode = this.vendor && this.vendor.currency_code ? this.vendor.currency_code : this.vendorData.currency_code;
    switch (this.currencyCode) {
      case "USD":
        this.conversionRate.removeControl('USD');
        this.conversionRate.addControl('INR', new FormControl);
        this.conversionRate.addControl('JPY', new FormControl);
        this.conversionRate.addControl('SGD', new FormControl);
        this.conversionRate.addControl('EUR', new FormControl);
        if (this.editMode) {
          this.conversionRate.removeControl('USD');
          this.conversionRate.addControl('INR', new FormControl);
          this.conversionRate.addControl('JPY', new FormControl);
          this.conversionRate.addControl('SGD', new FormControl);
          this.conversionRate.addControl('EUR', new FormControl);
        }
        break
      case "JPY":
        this.conversionRate.removeControl('JPY');
        this.conversionRate.addControl('INR', new FormControl);
        this.conversionRate.addControl('USD', new FormControl);
        this.conversionRate.addControl('SGD', new FormControl);
        this.conversionRate.addControl('EUR', new FormControl);
        if (this.editMode) {
          this.conversionRate.removeControl('JPY');
          this.conversionRate.addControl('INR', new FormControl);
          this.conversionRate.addControl('USD', new FormControl);
          this.conversionRate.addControl('SGD', new FormControl);
          this.conversionRate.addControl('EUR', new FormControl);
        }
        break;
      case "INR":
        this.conversionRate.removeControl('INR');
        this.conversionRate.addControl('USD', new FormControl);
        this.conversionRate.addControl('JPY', new FormControl);
        this.conversionRate.addControl('SGD', new FormControl);
        this.conversionRate.addControl('EUR', new FormControl);
        if (this.editMode) {
          this.conversionRate.removeControl('INR');
          this.conversionRate.addControl('USD', new FormControl);
          this.conversionRate.addControl('JPY', new FormControl);
          this.conversionRate.addControl('SGD', new FormControl);
          this.conversionRate.addControl('EUR', new FormControl);
        }
        break;
      case "SGD":
        this.conversionRate.removeControl('SGD');
        this.conversionRate.addControl('INR', new FormControl);
        this.conversionRate.addControl('JPY', new FormControl);
        this.conversionRate.addControl('USD', new FormControl);
        this.conversionRate.addControl('EUR', new FormControl);
        if (this.editMode) {
          this.conversionRate.removeControl('SGD');
          this.conversionRate.addControl('INR', new FormControl);
          this.conversionRate.addControl('JPY', new FormControl);
          this.conversionRate.addControl('USD', new FormControl);
          this.conversionRate.addControl('EUR', new FormControl);
        }
        break;
      case "EUR":
        this.conversionRate.removeControl('EUR');
        this.conversionRate.addControl('INR', new FormControl);
        this.conversionRate.addControl('JPY', new FormControl);
        this.conversionRate.addControl('SGD', new FormControl);
        this.conversionRate.addControl('USD', new FormControl);
        if (this.editMode) {
          this.conversionRate.removeControl('EUR');
          this.conversionRate.addControl('INR', new FormControl);
          this.conversionRate.addControl('JPY', new FormControl);
          this.conversionRate.addControl('SGD', new FormControl);
          this.conversionRate.addControl('USD', new FormControl);
        }
        break;
      default:
        this.conversionRate.addControl('INR', new FormControl);
        this.conversionRate.addControl('JPY', new FormControl);
        this.conversionRate.addControl('SGD', new FormControl);
        this.conversionRate.addControl('USD', new FormControl);
        this.conversionRate.addControl('EUR', new FormControl);
    }
  }

  openModel(template: TemplateRef<any>) {
    this.modelRef = this.modalService.show(
      template,
      Object.assign({ backdrop: 'static', keyboard: false, class: 'gray modal-small product-modal' })
    );
    this.alertService.backModalClose(this.modelRef);
  }

  uploadProductPrice() {
    this.submitted = true;
    if (!this.uploadProductPrices.valid || !this.selectFile) {
      return false;
    }
    if (this.uploadProductPrices.valid) {
      let value: any;
      if (!this.uploadProductPrices.get('port_id').value) {
        this.uploadProductPrices.get('port_id').setValue(this.portId);
      }
      if (!this.uploadProductPrices.get('vendor_id').value) {
        this.uploadProductPrices.get('vendor_id').setValue(this.vendorId);
      }

      Object.keys(this.uploadProductPrices.value).forEach(key => {
        value = this.uploadProductPrices.value[key] ? this.uploadProductPrices.value[key] : '';
        this.formData.append(key, value);
      });

      if (!this.formData.get('expire_in')) {
        this.formData.delete('expire_in');
      }
      this.checkVendorCurrency(this.vendorData);
      this.formData.delete('file_name');
      this.formData.append('currency_code', this.vendorData.currency_code);
      this.isLoading = true;
      let isRfq = 0
      this.formData.append('file', this.file, this.fileName);
      this.formData.append('file_name', this.fileName);
      this.formData.append('is_rfq', JSON.stringify(isRfq));
      this.rfqService.uploadProduct(this.formData).subscribe(data => {
        this.setPage(this.page);
        this.modelRef.hide();
        this.toastr.success(data.data);
        this.selectFile = false;
        this.isLoading = false;
        this.resetProductForm();
      }, error => {
        this.toastr.error(error ? error.error : 'Something went wrong', null, {
          disableTimeOut: true,
          tapToDismiss: true,
          closeButton: false,
        });
        this.selectFile = false;
        this.isLoading = false;
        this.resetProductForm();
      });
    }
  }

  onSelectDate(event) {
    if (event > this.dateTime) {
      event = JSON.parse(this.datePipe.transform(event, 'dd'));
      const curDate: number = parseFloat(this.datePipe.transform(this.dateTime, 'dd'));
      this.expireIn = event - curDate;
      this.uploadProductPrices.patchValue({
        expire_in: this.expireIn,
      });
    }
  }

  resetProductForm() {
    this.uploadProductPrices.reset();
    this.uploadProductPrices.patchValue({
      port_id: this.portId,
      vendor_id: this.vendorId,
      transport_fee: null,
      launch_fee: null
    });
    this.fileName = null;
    this.isLoading = false;
    this.submitted = false;
    this.formData = new FormData();
  }

  conversion;
  changePrice(event) {
    this.price = 0;
    if (event.id) {
      this.checkSelectedRowCurrency();
    } else {
      this.price = parseInt(event.target.value);
      this.convertPrice(event)
    }
    this.currencyCode = this.vendor && this.vendor.currency_code ? this.vendor.currency_code : this.vendorData.currency_code;
    let vendorCurrency = this.vendor ? this.vendor : this.vendorData;
    this.conversion = vendorCurrency.conversion_rates.reduce((acc, cur) => ({ ...acc, [cur.to_currency_code]: cur.rate }), {})
    let keys = Object.keys(this.conversionRate.controls);
    switch (this.currencyCode) {
      case "USD":
        keys.forEach(key => {
          this.conversionRate.controls[key].setValue(this.conversion[key]);
        })
        break
      case "JPY":
        keys.forEach(key => {
          this.conversionRate.controls[key].setValue(this.conversion[key]);
        })
        break;
      case "INR":
        keys.forEach(key => {
          this.conversionRate.controls[key].setValue(this.conversion[key]);
        })
        break;
      case "SGD":
        keys.forEach(key => {
          this.conversionRate.controls[key].setValue(this.conversion[key]);
        })
        break;
      case "EUR":
        keys.forEach(key => {
          this.conversionRate.controls[key].setValue(this.conversion[key]);
        })
        break;
    }
  }

  checkSelectedRowCurrency() {
    if (this.selectedRow.inrPrice && (this.vendor && this.vendor.currency_code == 'INR' || this.vendorData.currency_code == 'INR')) {
      this.price = this.selectedRow.inrPrice.price;
    }
    if (this.selectedRow.usdPrice && (this.vendor && this.vendor.currency_code == 'USD' || this.vendorData.currency_code == 'USD')) {
      this.price = this.selectedRow.usdPrice.price;
    }
    if (this.selectedRow.jpyPrice && (this.vendor && this.vendor.currency_code == 'JPY' || this.vendorData.currency_code == 'JPY')) {
      this.price = this.selectedRow.jpyPrice.price;
    }
    if (this.selectedRow.sgdPrice && (this.vendor && this.vendor.currency_code == 'SGD' || this.vendorData.currency_code == 'SGD')) {
      this.price = this.selectedRow.sgdPrice.price;
    }
    if (this.selectedRow.eurPrice && (this.vendor && this.vendor.currency_code == 'EUR' || this.vendorData.currency_code == 'EUR')) {
      this.price = this.selectedRow.eurPrice.price;
    }
  }

  getExpiredProducts() {
    let params = new HttpParams();
    if (this.vendorId) {
      params = params.append('vendor_id', `${this.vendorId}`);
    }

    if (this.shippingCompanyId) {
      params = params.append('company_id', this.shippingCompanyId);
    } else {
      params.delete('company_id');
    }
    this.rfqService.getRfqExpiredProducts(params).subscribe(data => {
      this.expiredProducts = data;
      this.showExpiredProduct = false;
      this.goingToExpireProduct = 0;
      this.expiredProducts.forEach(element => {
        if (element.sub_categories && element.sub_categories.length > 0) {
          element.sub_categories.forEach(item => {
            if (item && item.inr.expired || item.inr.going_to_expired || item.usd.expired || item.usd.going_to_expired) {
              this.goingToExpireProduct += (item.inr.expired + item.inr.going_to_expired + item.usd.expired + item.usd.going_to_expired);
              this.showExpiredProduct = true;
            }
          });
        }
      });
    }, error => {
      this.toastr.error(error.error ? error.error : 'Something went wrong! Try again.');
    });
  }

  getPrices(value) {
    switch (this.selectedCurrency) {
      case "INR":
        return value.inrPrice && value.inrPrice.price.toFixed(2);
      case "USD":
        return value.usdPrice && value.usdPrice.price.toFixed(2);
      case "JPY":
        return value.jpyPrice && value.jpyPrice.price.toFixed(2);
      case "SGD":
        return value.sgdPrice && value.sgdPrice.price.toFixed(2);
      case "EUR":
        return value.eurPrice && value.eurPrice.price.toFixed(2);
    }
  }
  currecnySelected(event) {
    console.log(event);

  }
  onRemoveCategory(event) {
    if (event && event.value.id == 0) {
      this.subCategoryId = null;
      this.subCategoryList = this.subCategoryList.map(item => {
        item.disabled = false;
        return item;
      });
      this.setPage(this.page);
    }
  }
  exportProducts(isRfq = 0, currency = 'INR', type = 'xlsx') {
    let params = new HttpParams();
    let fileName;
    this.loaderView = true;
    if (!isRfq) {
      fileName = ('products_price');
    }
    if (isRfq == 0) {
      params = params.append('currency', `${currency}`);
    }
    let subCatId = [];
    if (this.subCategoryId) {
      subCatId = [this.subCategoryId];
    }
    if (this.shippingCompanyId) {
      params = params.append('company_id', this.shippingCompanyId)
    }
    if (this.subCategoryId == null || this.subCategoryId == 0) {
      subCatId = [];
      this.subCategoryList.forEach(x => subCatId.push(x.id));
    }
    const mime = type === 'pdf' ? 'application/pdf' : 'application/vnd.ms-excel';
    this.rfqService
      .exportProduct(this.vendorId, this.portId, type, subCatId, isRfq, params)
      .subscribe(data => {
        this.loaderView = false;
        this.sharedService.saveAsBlob(data, mime, `${fileName}_${this.vendorName}.${type}`);
        this.toastr.clear();
      }, error => {
        this.loaderView = false;
        this.toastr.error(error.error ? error.error : 'Something went wrong');
      });
  }
  getDeliverable(price) {
    switch (this.selectedCurrency) {
      case "INR":
        return price?.inr && price?.inr?.deliverable_in != null ? price?.inr.deliverable_in : 'N/A';
      case "USD":
        return price?.usd && price?.usd?.deliverable_in != null ? price?.usd?.deliverable_in : 'N/A';
      case "JPY":
        return price?.jpy && price?.jpy?.deliverable_in != null ? price?.jpy?.deliverable_in : 'N/A';
      case "EUR":
        return price?.eur && price?.eur?.deliverable_in != null ? price?.eur?.deliverable_in : 'N/A';
      case "SGD":
        return price?.sgd && price?.sgd?.deliverable_in != null ? price?.sgd?.deliverable_in : 'N/A';
    }
  }

  changeUsd(event) {
    event.target.checked == false ? this.conversionRate.removeControl('USD') : this.changePrice(this.vendorData)
  }
  changeINR(event) {
    event.target.checked == false ? this.conversionRate.removeControl('INR') : this.changePrice(this.vendorData)
  }
  changeJPY(event) {
    event.target.checked == false ? this.conversionRate.removeControl('JPY') : this.changePrice(this.vendorData)
  }
  changeSGD(event) {
    event.target.checked == false ? this.conversionRate.removeControl('SGD') : this.changePrice(this.vendorData)
  }
  changeEUR(event) {
    event.target.checked == false ? this.conversionRate.removeControl('EUR') : this.changePrice(this.vendorData)
  }

  changeDate(event, enternedValue) {
    let today = new Date();
    if (enternedValue) {
      let day = parseInt(enternedValue)
      today.setDate(today.getDate() + day);
      this.validityExpiryDate = today;
    } else {
      this.validityExpiryDate = event
    }

    let currentDate = new Date();
    event = new Date(event);
    return Math.floor((Date.UTC(event.getFullYear(), event.getMonth(), event.getDate()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) / (1000 * 60 * 60 * 24));
  }

  addDays(event) {
    if (event.target.value) {
      let today = new Date();
      today.setDate(today.getDate() + parseInt(event));
      this.editPriceForm.get('expiryDate').setValue(this.datePipe.transform(new Date(today), 'yyyy-MM-dd'))
      return this.datePipe.transform(new Date(today), 'yyyy-MM-dd');
    }
  }

  validate(e) {
    const value = e.target.value;
    this.convertPrice(e)
    var regex = new RegExp(/^(?:\d|[0-9]\d|9[0-9])(?:\.\d)?$/);
    const flag = regex.test(value);
    if (!flag) {
      return this.showError = true;
    } else {
      return this.showError = false;
    }
  }
  convertPrice(event) {
    let enteredPrice = parseInt(this.form.price.value);
    if (this.currencyCode) {
      let keys = Object.keys(this.conversion);
      keys.forEach(key => {
        if (key == "INR") {
          this.inrConversionRate = this.conversion[key]
        }
        if (key == "USD") {
          this.usdConversionRate = this.conversion[key]
        }
        if (key == "JPY") {
          this.jpyConversionRate = this.conversion[key]
        }
        if (key == "SGD") {
          this.sgdConversionRate = this.conversion[key]
        }
        if (key == "EUR") {
          this.eurConversionRate = this.conversion[key]
        }
      })
      if (this.inrConversionRate) {
        this.inrPrice = (enteredPrice + ((enteredPrice * this.form.tax.value) / 100)) * this.inrConversionRate
      }
      if (this.usdConversionRate) {
        this.usdPrice = (enteredPrice + ((enteredPrice * this.form.tax.value) / 100)) * this.usdConversionRate
      }
      if (this.jpyConversionRate) {
        this.jpyPrice = (enteredPrice + ((enteredPrice * this.form.tax.value) / 100)) * this.jpyConversionRate
      }
      if (this.sgdConversionRate) {
        this.sgdPrice = (enteredPrice + ((enteredPrice * this.form.tax.value) / 100)) * this.sgdConversionRate
      }
      if (this.eurConversionRate) {
        this.eurPrice = (enteredPrice + ((enteredPrice * this.form.tax.value) / 100)) * this.eurConversionRate
      }
    }
  }

  selectedfileNames(files: FileList) {
    if (files.length > 5) {
      this.toastr.error('Please select a maximum of 5 files.');
      this.fileName = null;;
      return;
    } else {
      let formData = new FormData()
      this.isSelected = false
      this.inValidFileSize = false;
      let supportingData = []
      for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        const fileSize = file.size / 1024 / 1024;
        formData.set('file', file);
        if (fileSize > 5) {
          this.inValidFileSize = true;
          return;
        } else {
          this.isSelected = true
          this.orderService.supportingDocument(formData).subscribe(data => {
            if (data) {
              this.isSelected = false
              supportingData.push(data);
              this.supportingDoc = supportingData.join(', ');
              this.editPriceForm.get('supporting_documents').setValue(this.supportingDoc);
              const searchString = '_'
              let attachedDoc = this.editPriceForm.controls.supporting_documents.value.replace(/product_price_supporting_files_folder\//g, '').split('');
              this.attachedDocuments = []
              attachedDoc.forEach(x => {
                const index = x.indexOf(searchString);
                if (index !== -1) {
                  this.attachedDocuments.push(x.substring(index + searchString.length));
                }
              })

            }
          }, error => {
            this.isSelected = false;
          })
        }
      }
    }
  }

  getAllBrands() {
    this.sharedService.getAllBrands().subscribe(data => {
      this.brands = data;
    })
  }

  onMouseEnter(event) {
    this.priceHistory = true;
  }

  onMouseLeave(event) {
    this.priceHistory = false;
  }
  getCompanyList() {
    this.companyservice.getAll(1, 1000, 0, 1, null, 0).subscribe(data => {
      this.shippingCompanyList = data.results;
      this.shippingCompanyList = this.shippingCompanyList.filter(company => company.type == 1);
      if (this.productPrices) {
        //this.categories = this.vendorId ? data.results.find(x => x.id === this.vendorId).category.map(cat => cat.category_deals_on) : this.companys[0].category.map(cat => cat.category_deals_on);
        //this.sortCategory(this.categories)
      }
    }, error => {
      this.toastr.error(error.error.message);
    });
  }

  changeShippingCompany(event) {
    if (event && event.id) {
      this.shippingCompanyId = event.id;
    } else {
      this.shippingCompanyId = null
    }
    this.setPage(this.page);
  }

  navigate() {
    if (this.shippingCompanyId) {
      this.router.navigate(['/RFQs/products-expired'], { queryParams: { vendor_id: this.vendorId, company_id: this.shippingCompanyId } })
    } else {
      this.router.navigate(['/RFQs/products-expired'], { queryParams: { vendor_id: this.vendorId } })
    }
  }

  filterCategory(vendor) {

    this.companyService.getVendorDetailsByID(vendor.id, 1, 1000, 2).subscribe(data => {
      this.categories = data.results.map(cat => cat.category_deals_on)
    })
    //this.categories = vendor.category.map(cat => cat.category_deals_on);
    //this.sortCategory(this.categories)
  }

  /* sortCategory(categories) {
    categories.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    })
  } */
}