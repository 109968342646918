import { CatelogueModule } from '@app/modules/cetalogue/catelogue.module';
import { CartComponent } from '@app/modules/cart/components/cart.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbPaginationModule, NgbPopoverModule, NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarModule } from 'primeng/calendar';

import { PiechartComponent } from '../piechart/piechart.component';
import { SharedModule } from '../shared/shared.module';
import { SuccessMessageComponent } from '../success-message/success-message.component';
// order
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardUpdatesComponent } from './dashboard-updates/dashboard-updates.component';
import { CountryListComponent } from '@app/modules/country/components/country-list/country-list.component';
import { CountryDetailComponent } from '@app/modules/country/components/country-detail/country-detail.component';
import { CountryComponent } from '@app/modules/country/country.component';
import { DashboardCaptainComponent } from './dashboard-updates/dashboard-captain/dashboard-captain.component';
import { DashboardAdminComponent } from './dashboard-updates/dashboard-admin/dashboard-admin.component';
import { DashboardVendorComponent } from './dashboard-updates/dashboard-vendor/dashboard-vendor.component';
import { DashboardManagerComponent } from './dashboard-updates/dashboard-manager/dashboard-manager.component';
import { ChartModule } from 'primeng/chart';
// import { LinechartComponent } from '@app/linechart/linechart.component';
import { HighchartPieComponent } from '@app/highchart-pie/highchart-pie.component';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import { HIGHCHARTS_MODULES } from 'angular-highcharts';
import { HighchartBarComponent } from '@app/highchart-bar/highchart-bar.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ProductModule } from '@app/modules/product/product.module';
import { ShipComparisonComponent } from './dashboard-updates/ship-comparison/ship-comparison.component';
import { CategoryExpenditureComponent } from '@app/dashboard/dashboard-updates/category-expenditure/category-expenditure.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {CalendarUiCorrectionDirective} from '@shared/directive/calendar-ui-correction.directive';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        DashboardRoutingModule,
        SharedModule,
        CatelogueModule,
        NgbPaginationModule,
        CalendarModule,
        ChartModule,
        NgxIntlTelInputModule,
        NgbPopoverModule,
        NgbRatingModule,
        ProductModule,
        DragDropModule,
        CalendarUiCorrectionDirective
    ],
  declarations: [
    DashboardUpdatesComponent,
    // LinechartComponent,
    HighchartPieComponent,
    HighchartBarComponent,
    PiechartComponent,
    SuccessMessageComponent,
    CountryDetailComponent,
    CountryListComponent,
    CountryComponent,
    CartComponent,
    DashboardCaptainComponent,
    DashboardAdminComponent,
    DashboardVendorComponent,
    DashboardManagerComponent,
    ShipComparisonComponent,
    CategoryExpenditureComponent
  ],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: () => [more, exporting] } // a
  ]
})
export class DashboardModule { }
