<div class="row title-row">
  <div class="col-md-2 col-sm-8">
    <h3 class="title inline-block rs-top-space bmargin10"><strong>New Requests for Quotations</strong></h3>
  </div>
  <div class="col-md-10 col-sm-12">
    <div class="head-filters-wrap"> 
      <!-- <div class="head-filters bmargin15">  
        <label class="bmargin0">Select Category</label>
        <ng-select [items]="category" [(ngModel)]="categoryId" (change)="categorySelected($event)" bindLabel="name" bindValue="id" [multiple]="false" placeholder="Category" class="form-control reduce-height" [clearable]="false">
          <ng-template ng-option-tmp let-item="item">
            <a title="{{item?.name}}">{{item?.name}}</a>
          </ng-template>
        </ng-select>
      </div> -->
      <div class="head-filters bmargin15">  
        <label class="bmargin0">Select Ship</label>
        <ng-select [items]="shipList" [(ngModel)]="shipId" (change)="vendorSelected(vendorId)" bindLabel="name" bindValue="id" [multiple]="false" placeholder="Ship Name" class="form-control reduce-height" [clearable]="false">
          <ng-template ng-option-tmp let-item="item">
            <a title="{{item?.name}} | {{item?.company?.name}}" class="secondary">{{item.name}} | <b> {{item?.company?.name}}</b></a>
          </ng-template>
        </ng-select>
      </div>
      <div class="head-filters bmargin15">
        <label class="bmargin0">Select Port</label>
        <ng-select [items]="portList" [(ngModel)]="portId" (change)="portSelected($event);vendorId = null;" bindLabel="name" bindValue="id" [multiple]="false" placeholder="Port Name" class="form-control reduce-height" [clearable]="false">
        </ng-select>
      </div>
      <!-- <div class="head-filters bmargin15">
        <label class="bmargin0">Select Vendor</label>
        <ng-select [items]="vendorList" [(ngModel)]="vendorId" (change)="vendorSelected($event.id)" bindLabel="name" bindValue="id" [multiple]="false" placeholder="Select Vendor" class="form-control reduce-height" [clearable]="false" [loading]="isLoading">
        </ng-select>
      </div> -->
    </div>
  </div>
</div>
<!-- <div class="productrequest">
  <div class="single-box">
    <div class="product-req">
      <div class="upload-product-box request-place">
        <h5>Can’t find the product in our catalogue?</h5>
        <p>Place a request with us and we will get it delivered to you.</p>
        <button type="button"
          class="btn" (click)="showModal(newProductRequestTemplate)">Add Request</button>
        <img src="assets/images/dropbox-icon.svg"
          alt="excel-icon">
      </div>
    </div>
  </div>
</div> -->
<div class="row">
  <div class="col-md-4">
    <h4 class="sure-font text-center bmargin5">Client Requisition <span>(Imported from Excel)</span></h4>  
  </div>
  <div class="col-md-8">
    <h4 class="sure-font text-center bmargin5">System Generated Results</h4>
  </div>
  <div class="col-md-12">
    <div class="tabular-detail data-table new-quotation">
      <div class="scroll-table">
        <table class="table full-table req-table price update new">
          <thead>
              <tr>
                <th><input type="checkbox"
                  id="select_all"
                  [(ngModel)]="selectAllChecked"
                  (click)="selectAllCheckboxAction()" /></th>
                <th>Sr. No</th>
                <th>Product Description</th>
                <th>Quantity</th>
                <th style="width: 20px !important;border-bottom: none !important;
                border-top: none !important; background-color: #f7f7f7;"></th>
                <!-- <th>Sr. No</th> -->
                <th>Product Name<br>
                  <a href="javascript:void(0)" (click)="openProductSuggestionModal(newProductRequestTemplate, 0, 'Add New Product', 0 ,false)" class="add-items">Add Product</a>
                </th>
                <!-- <th>Pack Type/ Unit</th> -->
                <th *ngIf="vendorPriceData && vendorPriceData.show_inventory_column">Availabe Qty.<br>
                  <a href="javascript:void(0)" class="add-items">{{vendorPriceData && vendorPriceData.warehouse_name ? vendorPriceData.warehouse_name : ''}}</a>
                </th>
                <th>Quantity</th>
                <!-- <th>Price Per Unit</th> -->
                <th>Remark(s)</th>  
              </tr>
          </thead>
          <tbody>
            <tr *ngIf="!reqQuotationData || (reqQuotationData && reqQuotationData.length == 0)">
              <td colspan="4">No Data Available...</td>
              <td class="no-data-td"></td>
              <td colspan="8">No Data Available...</td>
            </tr>
            <tr *ngIf="productLoading">
              <td class="td-span1" colspan="4">Loading...</td>
              <td class="td-span1" class="no-data-td"></td>
              <td class="td-span1" colspan="8">Loading...</td>
            </tr>
            <ng-container *ngIf="!productLoading">
              <tr *ngFor="let reqQuotation of reqQuotationData; index as i">
                <td><input type="checkbox"
                  id="select_{{reqQuotation.id}}"
                  [(ngModel)]="reqQuotation.is_selected"
                  (click)="selectCheckboxAction(reqQuotation)"/></td>
                <td>{{i+1}}.</td>
                <td>
                  <span class="td-span">
                    <a class="delete-product" (click)="openModal(deletePopup, i)"><i class="fa fa-trash" aria-hidden="true"></i></a>
                    {{reqQuotation?.description ? reqQuotation?.description : 'N/A'}}<br>
                    <span>Unit: {{reqQuotation?.unit ? reqQuotation?.unit : 'N/A'}} / {{check(reqQuotation?.pro_code)?'IMPA':'Product Code'}}: {{reqQuotation?.pro_code ? reqQuotation?.pro_code : 'N/A'}}</span>
                  </span>
                </td>
                <td>
                  <span class="td-span">{{(reqQuotation?.qty && reqQuotation?.qty == null) || reqQuotation?.qty == ''  ? '0' : reqQuotation?.qty | number: '1.2-2'}}</span>
                </td>
                <td style="width: 20px !important;border-bottom: none;
                border-top: none; background-color: #f7f7f7;"></td>
                <!-- <td>{{i+1}}.</td> -->
                <td class="view-suggestion-td">
                  <div class="view-suggestion-div"> 
                    <span class="counting-span">{{i+1}}.</span>
                    <span *ngIf="reqQuotation?.suggestions && reqQuotation?.suggestions.length > 0" class="td-span">
                      <a (click)="openPopup(reqQuotation?.suggestions[0]?.id)">{{reqQuotation?.suggestions && reqQuotation?.suggestions.length > 0 ? reqQuotation?.suggestions[0]?.name : 'N/A'}}</a><br>
                      <span class="impa">Unit: {{reqQuotation?.suggestions && reqQuotation?.suggestions.length > 0 && reqQuotation?.suggestions[0]?.pack_type ? reqQuotation?.suggestions[0]?.pack_type+ ', ' : ''}}
                        {{reqQuotation?.suggestions && reqQuotation?.suggestions.length > 0 && reqQuotation?.suggestions[0]?.unit ? reqQuotation?.suggestions[0]?.unit : 'N/A'}} / {{reqQuotation?.suggestions && reqQuotation?.suggestions.length > 0 && reqQuotation?.suggestions[0]?.impa_code?'IMPA':'Product Code'}}: {{reqQuotation?.suggestions && reqQuotation?.suggestions.length > 0 && reqQuotation?.suggestions[0]?.impa_code ? (reqQuotation?.suggestions[0]?.impa_code+', ') : ''}}{{reqQuotation?.suggestions && reqQuotation?.suggestions.length > 0 && reqQuotation?.suggestions[0]?.product_code ? reqQuotation?.suggestions[0]?.product_code : ''}}
                      </span>
                      <div class="progress" *ngIf="reqQuotation?.suggestions[0]?.matching_percentage>0">
                        <div class="progress-bar progress-bar-striped"
                          role="progressbar"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          [style.width.%]="reqQuotation.suggestions[0].matching_percentage"
                          [attr.aria-valuenow]="reqQuotation.suggestions[0].matching_percentage"
                          [attr.title]="'Matching Percentage: ' + (reqQuotation.suggestions[0].matching_percentage ? reqQuotation.suggestions[0].matching_percentage.toFixed(2) + '%' : '')">
                          {{ reqQuotation.suggestions[0].matching_percentage ? reqQuotation.suggestions[0].matching_percentage.toFixed(2) + '%' : '' }}
                        </div>
                      </div>
                      <p *ngIf="reqQuotation.is_requested" class="new-product-tag" placement="bottom" ngbPopover="" triggers="mouseenter:mouseleave">New Request</p>
                    </span>
                    <span *ngIf="!reqQuotation?.suggestions || reqQuotation?.suggestions.length == 0" class="td-span not-found">-----Product Not Found-----</span>
                    <span class="view-suggestions" *ngIf="!reqQuotation.is_requested">
                      <a href="javascript:void(0)" *ngIf="reqQuotation?.suggestions && reqQuotation?.suggestions.length > 0" (click)="openProductSuggestionModal(productSuggestionsModal, 1, 'Product Suggestions', i, reqQuotation?.suggestions)">View Suggestion</a>
                      <a href="javascript:void(0)" *ngIf="reqQuotation?.suggestions && reqQuotation?.suggestions.length == 0" (click)="openProductSuggestionModal(productSuggestionsModal, 0, 'Find A Product', i)">Find Your Product</a>
                    </span>
                    <span class="view-suggestions">
                      <a href="javascript:void(0)" (click)="showModal(newProductRequestTemplate ,reqQuotation, i , true)">Add Specific</a>
                    </span>
                  </div>
                </td>
                <!-- <td>
                  <span class="td-span">
                    Pack Type: {{reqQuotation?.suggestions && reqQuotation?.suggestions.length > 0 && reqQuotation?.suggestions[0]?.pack_type ? reqQuotation?.suggestions[0]?.pack_type+ ', ' : ''}}
                    {{reqQuotation?.suggestions && reqQuotation?.suggestions.length > 0 && reqQuotation?.suggestions[0]?.unit ? reqQuotation?.suggestions[0]?.unit : 'N/A'}}
                  </span>
                </td> -->
                <td *ngIf="vendorPriceData && vendorPriceData.show_inventory_column"><span class="td-span">{{ getVendorInventoryPrices(reqQuotation?.suggestions[0]?.id)?.inventory_qty | number: '1.2-2' }}</span></td>
                <td>
                  <span class="td-span">
                    <span *ngIf="!showQuantity[i]">{{(reqQuotation?.new_qty && reqQuotation?.new_qty == null) || reqQuotation?.new_qty == ''  ? '0' : reqQuotation?.new_qty | number: '1.2-2'}} <a (click)="showQuantity[i] = true" class="edit-quantity"><img src="/assets/images/edit-icon-blue.svg" alt=""></a></span>
                    <div *ngIf="showQuantity[i]" class="form-group popup-field bmargin0"><input type="text" [(ngModel)]="reqQuotation.new_qty" pKeyFilter="pnum" (change)="editQuantity($event, reqQuotation, i)" maxlength="15" class="form-control" placeholder="Enter Quantity">
                    <button type="button" (click)="showQuantity[i] = false;"><i class="fa fa-check"></i></button></div>
                  </span>
                </td>
                <!-- <td>
                  <span class="td-span" *ngIf="getVendorInventoryPrices(reqQuotation?.suggestions[0]?.id)?.total > 0">
                    {{ (
                      getVendorInventoryPrices(reqQuotation?.suggestions[0]?.id)?.price * reqQuotation.new_qty
                    ).toFixed(6) | number: "1.2-2" }}
                    <br><span class="not-found">{{ getVendorInventoryPrices(reqQuotation?.suggestions[0]?.id)?.price | number: '1.2-2' }} Per Unit</span></span>
                  <span class="not-found" *ngIf="!getVendorInventoryPrices(reqQuotation?.suggestions[0]?.id) || getVendorInventoryPrices(reqQuotation?.suggestions[0]?.id)?.total == 0">N/A</span>
                </td> -->
                <td>
                  <div class="form-group bmargin0">
                    <input type="text" [(ngModel)]="reqQuotation.remarks" (change)="editRemark($event, reqQuotation, i)" maxlength="255" class="form-control" placeholder="Enter Remark(s)">
                  </div>
                </td>  
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
    <!-- <div class="delivery-chareges-wrap">
      <div class="total-box">
        <div class="label-box">
          <p>Total Price:</p>
          <span>(For {{sheetData && sheetData.length > 0 ? sheetData.length : '0'}} Items)</span>  
        </div>
        <div class="label-content">
          <p>{{currency ? currency+'.' : ''}} {{totalPrice | number: "1.2-2"}}</p>
        </div>
      </div>
      <div class="delivery-charges-box">
        <div class="label-box">
          <label class="custom-checkbox charges weight500 color-000" for="transport"><input
            id="transport"
            [(ngModel)]="transportCharged"
            type="checkbox"
            (ngModelChange)="addTransportCharged($event)"
          /><span class="shape"></span>
          Delivery Charges <span style="font-weight: 400;">(If applicable)</span>
        </label>
        </div>
        <div class="label-content">
          <span *ngIf="!showDeliveryCharges">{{currency ? currency+'.' : ''}} {{vendorPriceData && vendorPriceData?.transportation_charges && vendorPriceData?.transportation_charges > 0 && transportCharged ? (vendorPriceData?.transportation_charges | number: '1.2-2') : '0'}}
            <a *ngIf="vendorPriceData" (click)="showDeliveryCharges = true" class="edit-quantity"><img src="/assets/images/edit-icon-blue.svg" alt=""></a>
          </span>
          <div *ngIf="showDeliveryCharges" class="form-group popup-field charges bmargin0"><input type="text" [(ngModel)]="vendorPriceData.transportation_charges" pKeyFilter="pnum" (change)="editDeliveryCharges($event, vendorPriceData)" maxlength="15" class="form-control" placeholder="Enter Delivery Charges">
          <button type="button" (click)="showDeliveryCharges = false;"><i class="fa fa-check"></i></button></div>
        </div>
      </div>
      <div class="delivery-charges-box">
        <div class="label-box">
          <label class="custom-checkbox charges weight500 color-000" for="charges"><input
            id="charges"
            [(ngModel)]="launchCharged"
            type="checkbox"
            (ngModelChange)="addLaunchCharge($event)"
          /><span class="shape"></span>
          Launch Charges <span style="font-weight: 400;">(If applicable)</span>
        </label>
        </div>
        <div class="label-content">
          <span *ngIf="!showLaunchCharges">{{currency ? currency+'.' : ''}} {{vendorPriceData && vendorPriceData?.launch_charges && vendorPriceData?.launch_charges > 0 && launchCharged ? (vendorPriceData?.launch_charges | number: '1.2-2') : '0'}}
            <a *ngIf="vendorPriceData" (click)="showLaunchCharges = true" class="edit-quantity"><img src="/assets/images/edit-icon-blue.svg" alt=""></a>
          </span>
          <div *ngIf="showLaunchCharges" class="form-group popup-field charges bmargin0"><input type="text" [(ngModel)]="vendorPriceData.launch_charges" pKeyFilter="pnum" (change)="editLaunchCharges($event, vendorPriceData)" maxlength="15" class="form-control" placeholder="Enter Launch Charges">
          <button type="button" (click)="showLaunchCharges = false;"><i class="fa fa-check"></i></button></div>
        </div>
      </div>
      <div class="grand-total-box">
        <div class="label-box">
          <p>Grand Total:</p>
          <span>(For {{sheetData && sheetData.length > 0 ? sheetData.length : '0'}} Items)</span>  
        </div>
        <div class="label-content">
          <p>{{currency ? currency+'.' : ''}} {{grandTotalPrice | number: "1.2-2"}}</p>
        </div>
      </div>
    </div> -->
  </div>
</div>
<div class="tabledata-sendaction new">
  <button class="btn btn-primary update pull-left" [disabled]="!(shipId && portId) || exportReq" type="button" (click)="exportRequisitionQuotation()">
    <!-- <img src="/assets/images/export-icon.svg"> -->
    <i class="fa fa-download"></i> Export to Excel
    <ng-container *ngIf="exportReq"> 
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      <span class="sr-only">Loading...</span>
    </ng-container>
  </button>
  <!-- <button class="btn btn-primary update" type="button" [disabled]="!(shipId && portId && vendorId && isDisabledBtn)" (click)="openSendQuotationModal(sendQuotation)"><img src="/assets/images/email-envelope.svg"> Send Email Quotation</button> -->
  <button class="btn btn-primary update" type="button" (click)="createSpecificProducts();" [disabled]="!(is_any_selected()) || cartLoading">Create Specific Products
    <ng-container *ngIf="isLoadingSpecidSubmitButton"> 
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      <span class="sr-only">Loading...</span>
  </ng-container>
  </button>
  <button class="btn btn-primary update" type="button" (click)="moveToCartModal();" [disabled]="!(shipId && portId) || cartLoading"><img src="/assets/images/add-cart.svg"> Move to Cart
  </button>
  <!-- <button class="btn btn-primary update" type="button"><img src="/assets/images/add-cart.svg"> Create Order</button> -->
  <button class="btn btn-success update pull-right" [disabled]="!(shipId && portId)" (click)="createRequisition(orderForm)" type="button">Create REQ</button>
</div>
<ng-template #productSuggestionsModal>
  <div class="selling-product-wrap">
      <a class="popup-close" (click)="modalRef.hide();submitted=false;">&#10006;</a>
      <h4 class="sure-font text-center font22 weight600 bmargin20">{{popupTitle == '' ? 'Product Suggestions' : popupTitle | titlecase}}</h4>
      <div class="product-name" *ngIf="firstProductName">
        <span>Current Selected Product</span>
        <p>{{firstProductName}}</p> 
      </div>
      <div class="product-search">
        <span class="weight500">Find Product</span>
        <div class="search-categories search-field">
          <form [formGroup]="searchForm">
              <div class="searchbox" [class.showCross]="selectedAutocomplete">
                  <div class="form-group" [ngClass]="{error : submitted && sform.search.errors}">
                      <div class="ng-autocomplete">
                          <p-autoComplete class="long-search" formControlName="search" placeholder="Search by Product Name/ Code" [suggestions]="suggestion" (keyup)="showCrossBtn($event)" (completeMethod)="search($event)" (keyup.enter)="searchProduct()" (onSelect)="doOnSelect($event)">
                              <ng-template let-suggestion let-i="index" pTemplate="item" styleClass="wid100">
                                  <div class="search-product ui-helper-clearfix" style="border-bottom:1px solid #D5D5D5;width: 500px;">
                                      <p class="code">{{suggestion.name | titlecase}}</p>
                                      <!-- <p *ngIf="suggestion && suggestion?.pack_type" class="code">Unit: {{suggestion && suggestion?.pack_type ? (suggestion?.pack_type | titlecase) : ''}}</p> -->
                                      <p class="code impa">{{suggestion.product_code || 'N/A'}}, IMPA: {{suggestion.impa_code || 'N/A'}}</p>
                                      <p class="code impa">Unit: {{suggestion.unit || 'N/A'}}</p>
                                      <p class="code impa">Pack Type: {{suggestion.pack_type || 'N/A'}}</p>
                                  </div>
                                  <div *ngIf="suggestion.length == i-1">
                                      <button>View All</button>
                                  </div>
                              </ng-template>
                          </p-autoComplete>
                      </div>
                      <div class="errorbox" *ngIf="submitted && sform.search.errors">
                        <span>Enter search text</span>
                      </div>
                  </div>
                  <div class="search-btns">
                      <button class="find-btn cross-btn cat" (click)="resetForm()" type="button">&times;</button>
                      <button class="find-btn cat" type="button" (click)="searchProduct()">Search</button>
                  </div>
              </div>
          </form>
        </div>
      </div>
      <h4 *ngIf="popupTitle == 'Product Suggestions'" class="sure-font alternative bmargin10">Alternative Suggestions</h4>
      <div class="tabular-detail data-table batch remove-margin">
          <div class="scroll-table">
            <table class="table full-table req-table price update new batch-info remove-minwidth">
              <thead>
                  <tr>
                    <th>Product Name</th>
                    <th>Action</th>
                  </tr>
              </thead>
              <tbody>
                <tr *ngIf="!productSuggestions || (productSuggestions && productSuggestions.length == 0)">
                  <td colspan="2">No Data Available...</td>
                </tr>
                <tr *ngFor="let product of productSuggestions">
                  <td>
                    <a class="td-span">      
                      {{product?.name ? product?.name : 'N/A'}}<br>
                      <span>Unit: {{product?.pack_type ? product?.pack_type + ', ' : ''}}{{product?.unit ? product?.unit : 'N/A'}} / IMPA: {{product?.impa_code ? (product?.impa_code+', ') : 'N/A, '}}{{product?.product_code ? product?.product_code : ''}}</span>
                    </a>
                  </td>
                  <td>
                    <button type="button" (click)="addProduct(addNewProduct ? newProduct[0] : product,true)" class="btn btn-success">Add Product</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
      </div>
  </div>
</ng-template>
<ng-template #orderForm>
  <div class="modal-header">
      <h4 class="modal-title pull-left">Create Requisition</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal();">
    <span aria-hidden="true">&times;</span>
  </button>
  </div>
  <div class="modal-body modal-spc">
      <form [formGroup]="orderData">
          <div class="row">
              <div class="col-md-6">
                <div class="form-group" [ngClass]="{'error' : submitted && oForm.requirement_name.errors}">
                    <label for="">Requisition Name<span class="mendatory">*</span></label>
                    <input type="text" formControlName="requirement_name" class="form-control" placeholder="Enter Requisition Name">
                    <div class="errorbox" *ngIf="submitted && oForm.requirement_name.errors">
                        <span *ngIf="!oForm.requirement_name.valid">This field can't be blank</span>
                    </div>
                </div>
              </div>
              <!-- <div class="col-md-6">
                <div class="form-group" [ngClass]="{'error' : submitted && oForm.requirement_category_id.errors}">
                    <label for="">Select Category<span class="mendatory">*</span></label>
                    <ng-select formControlName="requirement_category_id" [items]="category" bindLabel="name" bindValue="id" [multiple]="false" placeholder="Select Category" class="form-control form-control-sm">
                    </ng-select>
                    <div class="errorbox" *ngIf="submitted && oForm.requirement_category_id.errors">
                        <span *ngIf="!oForm.requirement_category_id.valid">Select Category</span>
                    </div>
                </div>
              </div> -->
              <div class="col-md-6">
                  <div class="form-group" [ngClass]="{'error' : submitted && oForm.eta.errors}">
                      <label for="">ETA<span class="mendatory">*</span></label>
                      <p-calendar #cal [(ngModel)]="eta" formControlName="eta" placeholder="Arrival Time" [disabled]="false" dateFormat="dd M yy" showTime="true" hourFormat="24" [minDate]="today" (onSelect)="onSelectEta($event)" [maxDate]="etd" [touchUI]="false" [timeOnly]="false"
                          [readonlyInput]="true" showButtonBar="true" [numberOfMonths]="1" timeZone="Asia/Kolkata" showOn="button">
                          <p-footer class="cal-footer"> <button pButton label="Apply" (click)="cal.overlayVisible = false"></button> </p-footer>
                      </p-calendar>
                      <div class="errorbox" *ngIf="submitted && oForm.eta.errors">
                          <span *ngIf="!oForm.eta.valid">Select ETA</span>
                      </div>
                  </div>
              </div>
              <div class="col-md-6">
                  <div class="form-group" [ngClass]="{'error' : submitted && oForm.etd.errors}">
                      <label for="">ETD<span class="mendatory">*</span></label>
                      <p-calendar #cal2 formControlName="etd" placeholder="Departure Time" [(ngModel)]="etd" dateFormat="dd M yy" showTime="true" hourFormat="24" [minDate]="minEta" (onSelect)="setSelectedDate($event)" [touchUI]="false" [timeOnly]="false" [readonlyInput]="true"
                          showButtonBar="true" [numberOfMonths]="1" showOn="button">
                          <p-footer class="cal-footer"> <button pButton label="Apply" (click)="cal2.overlayVisible = false;"></button> </p-footer>
                      </p-calendar>
                      <div class="errorbox" *ngIf="submitted && oForm.etd.errors">
                          <span *ngIf="!oForm.etd.valid">Select ETD</span>
                      </div>
                  </div>
              </div>
              <!--<div class="col-md-6">
                <div class="form-group">
                  <label>Currency</label>
                  <ng-select formControlName="currency"
                    [items]="currencyDropdown"
                    bindLabel="name"
                    bindValue="id"
                    [multiple]="false"
                    [closeOnSelect]="true"
                    placeholder="Select Currency"
                    class="form-control"
                    clearAllText="Clear"
                    [clearable]="false">
                  </ng-select>
                </div>
              </div>-->                          
              <div class="col-md-6">
                <div class="form-group">
                    <label for="">RFQ Number</label>
                    <input type="text" formControlName="quotation_number" placeholder="Enter RFQ Number" (keyup)="changeQuotationNUmber($event)" maxlength="30" class="form-control">
                </div>
              </div>
              <div class="col-md-12 tmargin10">
                  <button class="btn btn-primary pull-right" type="button" (click)="orderConfirm(confirmOrderStatus)">Create Requisition</button>
              </div>
          </div>
      </form>
  </div>
</ng-template>
<ng-template #confirmOrderStatus>
  <div class="padding40 text-center">
      <h4 class="sure-font">Are you Sure?</h4>
      <div class="text-center tmargin20">
          <button class="btn btn-outline-primary" (click)="modelRef1.hide();submitted=false;" type="button">Cancel</button>
          <button class="btn btn-primary text-white" type="button" [disabled]="isLoading1" (click)="modelRef1.hide();placeRequisition();hideModal();">Confirm
              <ng-container *ngIf="isLoading1"> 
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span class="sr-only">Loading...</span>
              </ng-container>
          </button>
      </div>
  </div>
</ng-template>
<ng-template #deletePopup>
  <div class="modal-body cancelmodal-spc text-center">
      <h3 class="disable-popup-title bmargin0">Remove Product</h3>
      <div class="actionbuttons tmargin10 bmargin20 text-center">
          Are you Sure ?
      </div>
      <div class="text-center">
          <a class="btn btn-outline-danger" (click)="modalRef.hide()">Cancel</a>
          <a class="btn btn-danger text-white" (click)="removeProduct();modalRef.hide()">Remove</a>
      </div>
  </div>
</ng-template>
<app-product-popup #productPopup [product]="productDetail"></app-product-popup>
<ng-template #sendQuotation>
  <form class="quote" [formGroup]="sendQuotationForm" (ngSubmit)="sendQuotations()">
      <div class="modal-body padding20">
          <h4 class="sure-font bmargin20 text-center">Send Email Quotation</h4>
          <button type="button" class="close pull-right" (click)="modalRef.hide();resetQuotationForm();">
            <span>&times;</span>
          </button>
          <div class="row" formGroupName="requester_details">
            <div class="col-md-6 col-sm-6">
              <div class="form-group" [ngClass]="{error : submitted && sendQuotationForm.controls.requester_details.get('name').errors}">
                  <label for="">Select Requester<span class="mendatory">*</span></label>
                  <ng-select formControlName="name" [items]="requesterDetails" bindLabel="name" bindValue="name" [multiple]="false" placeholder="Select Requester" class="form-control" clearAllText="Clear" [clearable]="false" (change)="showDetails($event)">
                  </ng-select>
                  <div class="errorbox" *ngIf="submitted && sendQuotationForm.controls.requester_details.get('name').errors">
                      <span>Select requester</span>
                  </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="form-group" [ngClass]="{error : submitted && sendQuotationForm.controls.requester_details.get('designation').errors}">
                  <label for="">Requester Designation<span class="mendatory">*</span></label>
                  <input formControlName="designation" type="text" placeholder="Requester Designation" class="form-control" />
                  <div class="errorbox" *ngIf="submitted && sendQuotationForm.controls.requester_details.get('designation').errors">
                      <span>Enter valid designation</span>
                  </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="form-group" [ngClass]="{error : submitted && sendQuotationForm.controls.requester_details.get('email').errors}">
                  <label for="">Requester Email<span class="mendatory">*</span></label>
                  <input formControlName="email" type="text" placeholder="Requester Email" class="form-control" />
                  <div class="errorbox" *ngIf="submitted && sendQuotationForm.controls.requester_details.get('email').errors">
                      <span>Enter valid email</span>
                  </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="form-group" [ngClass]="{error : submitted && sendQuotationForm.controls.requester_details.get('phone').errors}">
                  <label for="">Requester Phone<span class="mendatory">*</span></label>
                  <input formControlName="phone" type="text" onkeypress="return /[0-9,+\s]/i.test(event.key)" maxlength="50" placeholder="Requester Phone" class="form-control" />
                  <div class="errorbox" *ngIf="submitted && sendQuotationForm.controls.requester_details.get('phone').errors">
                      <span>Enter valid phone</span>
                  </div>
              </div>
            </div>
            </div>
            <div class="row">
            <div class="col-md-6 col-sm-12">
              <div class="form-group" [ngClass]="{error : submitted && form.to_emails.errors}">
                  <label for="">To Email Id<span class="mendatory">*</span></label>
                  <!-- <ng-select class="form-control email-tag" formControlName="to_emails" (change)="checkToEmails($event)" [items]="toEmailItems" [(ngModel)]="toEmails" [addTag]="true" multiple="true" bindLabel="name" placeholder="Enter Email Id">
                  </ng-select> -->
                  <input formControlName="to_emails" type="text" placeholder="Enter To Email Id" class="form-control" />
                  <div class="errorbox" *ngIf="submitted && form.to_emails.errors && form.to_emails.errors">
                    <span>Enter valid email</span>
                  </div>
              </div>
            </div>
            <div class="col-md-12 col-sm-12">
              <div class="form-group" [ngClass]="{error : submitted && form.cc_emails.errors}">
                  <label for="">CC Email Ids<span class="mendatory">*</span></label>
                  <ng-select class="form-control email-tag" formControlName="cc_emails" [items]="ccEmailItems" (change)="checkCCEmails($event)" (remove)="addCCEmails($event)" [(ngModel)]="ccEmails" [addTag]="true" multiple="true" bindLabel="name" [clearable]="false" placeholder="Enter CC Email Id">
                  </ng-select>
                  <div class="errorbox" *ngIf="submitted && form.cc_emails.errors">
                      <span>Enter valid email</span>
                  </div>
              </div>
            </div>
          </div>
          <div class="text-right tmargin10">
              <button class="btn btn-outline-primary" (click)="modalRef.hide();resetQuotationForm();" type="button">Cancel</button>
              <button type="submit" [disabled]="isLoading" class="btn btn-primary text-white">Submit 
                  <ng-container *ngIf="isLoading"> 
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      <span class="sr-only">Loading...</span>
                  </ng-container>
              </button>
          </div>
      </div>
  </form>
</ng-template>
<ng-template #moveToCartPopUp>
  <div class=" padding20">
    <form class=""
      [formGroup]="moveToCartForm"
      (ngSubmit)="moveToCart()">
      <div class="modal-header">
        <h4 class="sure-font bmargin20 text-center ">Requisition</h4>
        <button type="button"
          class="close pull-right"
          (click)="modelRef.hide();submitted=false;">
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="col md-12"
          [ngClass]="{'error' : submitted && cart.requirement_name.errors}">
          <label for="">Requisition Name <span class="mendatory">*</span></label>
          <input type="text"
            class="form-control"
            placeholder="Enter Requisition Name"
            formControlName="requirement_name">
          <input-validation [control]="cart.requirement_name"
            [submit]="submitted"></input-validation>
        </div>
        <!-- <div class="col-md-12"
          [ngClass]="{'error' : submitted && cart.requirement_category_id.errors}">
          <label for="">Select Category<span class="mendatory">*</span></label>
          <ng-select class="form-control"
            formControlName="requirement_category_id"
            [items]="category"
            bindLabel="name"
            bindValue="id" placeholder="Please select category"></ng-select>
          <input-validation [control]="cart.requirement_category_id"
            [submit]="submitted">
          </input-validation>
        </div> -->
      </div>
      <div class="modal-footer">
        <button class="btn btn-outline-primary"
          (click)="modelRef.hide();submitted=false;"
          type="button">Cancel</button>
        <button class="btn btn-primary"
          type="submit">Submit
          <ng-container *ngIf="cartLoading"> 
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="sr-only">Loading...</span>
          </ng-container></button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #newProductRequestTemplate>  
    <app-product-request  (selectProduct) = selectProduct($event) (automaticparse)="automaticparse($event)" (closeModalClicked)="closeModalClicked($event)"
      [shipId]="shipId" [categoryId]="categoryId" [rfqParserProduct]="currentSelectedRFQProduct"  [updateSpecific]="updateSpecific" [addSpecific]="addSpecific" (updateItem)="updateItem($event)">
    </app-product-request>
</ng-template>
