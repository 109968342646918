<div class="pull-right custom-pull-right">
    <div class="search-categories rmargin10 search-field">
        <form [formGroup]="filterForm" (ngSubmit)="getSearch()">
            <div class="searchbox" [class.showCross]="selectedAutocomplete">
                <div class="ng-autocomplete">
                    <p-autoComplete #autocomplete formControlName="name" placeholder="Search Product" [ngModel]="selectedAutocomplete" [suggestions]="suggestion" (keyup)="showCrossBtn($event)" (completeMethod)="search($event)" (keyup.enter)="getSearch()" (onSelect)="doOnSelect($event)">
                        <ng-template let-suggestion let-i="index" pTemplate="item" styleClass="wid100">
                            <div class="search-product ui-helper-clearfix" style="border-bottom:1px solid #D5D5D5;width: 500px;">
                                <p class="code">{{suggestion.name | titlecase}}</p>
                            </div>
                            <div *ngIf="suggestion.length == i-1"><button>View All</button></div>
                        </ng-template>
                    </p-autoComplete>
                </div>
                <button class="find-btn cross-btn" (click)="resetForm()" type="reset">&times;</button>
                <button class="find-btn" type="submit"><span class="fa fa-search"></span></button>
            </div>
        </form>
    </div>
</div>
<h3 class="inline-block rs-top-space pad-bottom">New Product Request(s)</h3>
<div class="viewbox">
    <div class="load-listing" *ngIf="loading">
        Loading...
    </div>
    <div class="data-table" *ngIf="!loading">
        <ngx-datatable class="material paging-table top new-request" [class.stop-click]="isLoading" #newProductTable [rows]="newProducts" [scrollbarH]="true" [columnMode]="'force'" [headerHeight]="40" [footerHeight]="40" [rowHeight]="'auto'" [externalPaging]="true" [loadingIndicator]="isLoading"
            [count]="page.count" [offset]="page.offset" (page)="setPage($event)" [limit]="page.limit" (sort)="sortCallback($event)">
            <!--      <ngx-datatable-column *ngIf="role == userRoles.ROLE_ADMIN"-->
            <!--                            headerClass="no-arrow"-->
            <!--                            name=""-->
            <!--                            prop=""-->
            <!--                            frozenLeft="True"-->
            <!--                            [width]="40"-->
            <!--                            [maxWidth]="40">-->
            <!--        <ng-template ngx-datatable-cell-template-->
            <!--                     let-rowIndex="rowIndex"-->
            <!--                     let-value="value"-->
            <!--                     let-row="row">-->
            <!--          <a routerLink="/product/edit/{{ value.id }}"><i class="fa fa-pencil"></i></a>-->
            <!--        </ng-template>-->
            <!--      </ngx-datatable-column>-->
            <ngx-datatable-column name="Product Name" prop="name">
                <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row">
                    <a routerLink="/product/details/{{row.id}}">{{value |titlecase}}</a>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Description" prop="description">
                <ng-template ngx-datatable-cell-template let-row="row" let-value="value">
                    <div class="column-item"> {{ value}} </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Category" prop="category">
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <div class="column-item"> {{ value |titlecase}} </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column [sortable]="false" name="Action">
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <a class="btn btn-primary column-item" (click)="navigateProductEdit(row.id)" href="javascript:void(0)">Add to Catalog</a>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-footer>
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
                    <app-paging class="paging" [curPage]="curPage" [pageSize]="pageSize" [rowCount]="rowCount" [offset]="offset" [table]="table" (page)="onPageChange($event)" [filter]="filterForm.value"></app-paging>
                    <app-paging class="paging place-bottom" [curPage]="curPage" [pageSize]="pageSize" [rowCount]="rowCount" [offset]="offset" [table]="table" (page)="onPageChange($event)" [filter]="filterForm.value"></app-paging>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
    <!-- <ul class="pagination" *ngIf="!loading">
    <li class="page-item">
      <button [disabled]="page > 1 ? false : true" class="page-link" (click)="prevPage()">
        «
      </button>
    </li>
    <li>
      <ul class="pagination">
        <li class="page-item" [ngClass]="{'active': page === 1}" (click)="pageNumber('1')"><a class="page-link">1</a>
        </li>
        <li class="page-item" [ngClass]="{'active': page === 2}" (click)="pageNumber('2')"><a class="page-link">2</a>
        </li>
        <li class="page-item" [ngClass]="{'active': page === 3}" (click)="pageNumber('3')"><a class="page-link">3</a>
        </li>
      </ul>
    </li>
    <li class="page-item">
      <button [disabled]="page >= 3 ? true : false" class="page-link" (click)="nextPage()">
        »
      </button>
    </li>
  </ul> -->
</div>
<!-- <app-loading class="loaderHide" [class.loaderShow]="loaderView"></app-loading> -->
<ng-template #ImportProduct>
    <form [formGroup]="uploadForm" (ngSubmit)="uploadProduct()">
        <div class="modal-body padding40 text-center">
            <h4 class="sure-font">Import Product</h4>
            <div class="tmargin30">
                <!-- <label for="">Category</label> -->
                <ng-select [items]="categories" bindLabel="name" bindValue="id" [multiple]="false" formControlName="category_id" placeholder="Select Category" class="form-control" clearAllText="Clear" [clearable]="false">
                </ng-select>
            </div>
            <!--        <div class="tmargin30 bmargin30 pull-left">-->
            <!--          <label for="is_new">-->
            <!--          <input type="checkbox"-->
            <!--          id="is_new"-->
            <!--          formControlName="is_new"> Create New Product If Shipskart Code Exist</label>-->
            <!--        </div>-->
            <div class="select-file drop-file tmargin30 bmargin20 text-left">
                <span class="btn-span">+</span>
                <input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" type="file" class="form-control" (change)="selectedfileName($event.target.files)" formControlName="file_name" /> {{fileName ||
                'Select File'}}
            </div>
            <div class="text-right">

                <button class="btn btn-outline-primary" (click)="modalRef.hide()" type="button">
          Cancel
        </button>
                <input type="submit" class="btn btn-primary text-white" value="Upload">
            </div>
        </div>
        <div class="modal-footer">
            <a class="float-left tmargin8" target="__blank" href="https://shipskart-combined.s3.ap-south-1.amazonaws.com/static/file/product_upload_format.xlsx">
        New Product Excel
      </a>
        </div>
    </form>
</ng-template>
