import { DatePipe } from "@angular/common";
import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { first } from "rxjs/operators";
import { LoadingService } from "@app/authentication/services/loading.service";
import { ProductPopupComponent } from "@app/shared/components/product-popup/product-popup.component";
import { CommonService } from "@app/shared/helper/common";
import { AlertService } from "@app/shared/service/alert.service";
import { SharedService } from "@app/shared/service/shared.service";
import { CustomValidators } from "@app/shared/validators/custom-validators";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { CategoryService } from "../category/services/category.service";
import { Port } from "../port/models/port.model";
import { PortService } from "../port/port.service";
import { Product } from "../product/model/product.model";
import { ProductService } from "../product/services/product.service";
import { Vendor } from "../requisition/models/requisition";
import { Ship } from "../ship/model/ship.model";
import { ShipService } from "../ship/services/ship.service";
import { VendorPriceInvenoryData } from "./models/new-requisition-quotation.modal";
import { NewRequisitionQuotationService } from "./services/new-requisition-quotation.service";
import { ActivatedRoute } from "@angular/router";
import { UserRoles } from "@app/core/enums/user-roles";
import { throttleTime } from 'rxjs/operators';
import { Subject } from "rxjs";
import { IndexedDbService } from "@app/shared/service/indexeddb.service";
import { CanComponentDeactivate } from "@app/shared/service/candeactivate";

@Component({
  selector: "app-new-requisition-quotation",
  templateUrl: "./new-requisition-quotation.component.html",
  styleUrls: ["./new-requisition-quotation.component.css"],
})
export class NewRequisitionQuotationComponent implements OnInit, OnDestroy {
  private readonly clickSubject = new Subject<void>();
  private readonly clickThrottled$ = this.clickSubject.pipe(throttleTime(1000));
  shipList: Ship[] = [];
  portList: Port[] = [];
  vendorList: Vendor[] = [];
  isLoading: boolean;
  isLoading1: boolean;
  reqQuotationData: any = [];
  shipId: number;
  portId: number;
  vendorId: number;
  sheetData: {
    id: number;
    qty: number;
    requested_product_name: string;
    requested_product_quantity: number;
    requested_product_unit: string;
    suggested_product_id: string;
  }[] = [];
  tempSheetData: {
    id: number;
    qty: number;
    requested_product_name: string;
    requested_product_quantity: number;
    requested_product_unit: string;
    suggested_product_id: string;
  }[] = [];
  vendorPriceData: VendorPriceInvenoryData;
  quantity: any = [];
  quantityData: any = [];
  showQuantity: boolean[] = [];
  popupTitle: string;
  modalRef: BsModalRef;
  modelRef: BsModalRef;
  modelRef1: BsModalRef;
  productSuggestions = [];
  productIndex: number;
  transportCharged = true;
  launchCharged = true;
  totalPrice: number = 0;
  grandTotalPrice: number = 0;
  currency = "";
  showDeliveryCharges = false;
  showLaunchCharges = false;
  product: Product[] = [];
  productDetail: Product;
  newProduct: any;
  selectedAutocomplete: string = null;
  suggestion: any;
  submitted: boolean;
  searchForm: FormGroup;
  orderData: FormGroup;
  searchQuery = "";
  cartLoading: boolean;
  currentSelectedRFQProduct: any;
  firstProductName: string;
  addNewProduct: boolean;
  exportReq = false;
  @ViewChild("productPopup", { static: false })
  productPopup: ProductPopupComponent;
  @ViewChild("moveToCartPopUp", { static: false })
  moveToCartPopup: TemplateRef<any>;
  removeItemIndex: number;
  today = new Date();
  eta;
  etd;
  minEta: any;
  productLoading = false;
  sendQuotationForm: FormGroup;
  toEmails: any = [];
  ccEmails: any = [
    "operations@shipskart.com",
    "aastha@shipskart.com",
    "rupesh@shipskart.com",
  ];
  toEmailItems: any = [];
  ccEmailItems: any = [
    "operations@shipskart.com",
    "aastha@shipskart.com",
    "rupesh@shipskart.com",
  ];
  requesterDetails = [];
  isDisabledBtn = false;
  countryCode: string;
  // category = [];
  moveToCartForm: FormGroup;
  currencyDropdown = [];
  role: number;
  userRole = UserRoles;
  subAdminAssignedCompanyIds: [number];
  updateSpecific: boolean = true;
  isSelectedProduct: boolean = false;
  isNewRequest: boolean = false;
  updateRequest: boolean = false;
  addSpecific: boolean = true;
  index;
  isTrue: boolean = false;
  id;
  time_in_seconds;
  parse: boolean = true;
  isProductAdded: boolean = false;
  reqQuotationDatas: any;
  tempReqQuotation
  constructor(
    private shipService: ShipService,
    private portService: PortService,
    private newRequisitionQuotationService: NewRequisitionQuotationService,
    private productService: ProductService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private sharedService: SharedService,
    private datepipe: DatePipe,
    private router: Router,
    private loadingService: LoadingService,
    private sharedservice: SharedService,
    private commonService: CommonService,
    private alertService: AlertService,
    private categoryService: CategoryService,
    private route: ActivatedRoute,
    private indexedDbService: IndexedDbService
  ) { }

  toSentenceCase = (camelCase: string): string => {
    if (camelCase) {
      const result = camelCase.replace(/([A-Z])/g, "$1");
      return result[0].toUpperCase() + result.substring(1).toLowerCase();
    }
    return "";
  };

  async ngOnInit() {
    // let this.tempReqQuotation;
    // this.newRequisitionQuotationService.requisitionQuotationData.subscribe(value => {
    //   this.tempReqQuotation = value;
    // });
    this.getSubcategory();
    this.getUnits();
    this.role = this.sharedService.getUserRole();
    if (this.role == this.userRole.ROLE_SUBADMIN) {
      this.subAdminAssignedCompanyIds =
        this.sharedService.getSubAdminsCompanies();
    }
    this.currencyDropdown = this.sharedService.currencyDropdown;

    try {
      this.tempReqQuotation = await this.indexedDbService.getAllData();
      console.log("indexedDB", this.tempReqQuotation);

    } catch (error) {
      console.error('Error retrieving data from IndexedDB:', error);
    }

    //localStorage.removeItem('reqQuotationData');
    this.route.queryParams.subscribe((params) => {
      this.id = parseInt(params.i);
    });
    if (this.tempReqQuotation && this.tempReqQuotation.length > 0) {
      var i = 0;
      this.tempReqQuotation.forEach((tempReq, i) => {
        if (tempReq.newRequisition == this.id) {
          this.time_in_seconds = tempReq.intime;
          this.reqQuotationData = tempReq.data.map((req) => {
            if (req) {
              i = i + 1;
              const reqData = {
                description: this.toSentenceCase(req.description),
                qty: parseFloat(req.qty),
                new_qty:
                  req && req.new_qty >= 0
                    ? parseFloat(req.new_qty)
                    : parseFloat(req.qty),
                pro_code: req.pro_code,
                is_selected: false,
                id: i,
                unit: this.toSentenceCase(req.unit),
                suggestions: req.suggestions,
                remarks: this.toSentenceCase(
                  req && req.remarks ? req.remarks : ""
                ),
              };
              return reqData;
            }
          });
          this.reqQuotationDatas = this.reqQuotationData.map(a => ({ ...a }))
        }
      });
      this.sheetData = this.getSheetData(this.reqQuotationData);
      // this.tempSheetData = this.getSheetData(this.reqQuotationData, 1);
    } else {
      this.time_in_seconds = this.tempReqQuotation[this.id].intime;
      //this.toastr.error('No data found!')
      this.router.navigate(["/"]);
    }
    this.getAllShips();
    this.getPortList();
    this.searchForm = this.formBuilder.group({
      search: ["", [Validators.required]],
    });
    this.generateOrderDataForm();
    this.sendEmailQuotationForm();
    this.moveTOCartForm();
    this.clickThrottled$.subscribe(() => {
      this.productPopup.openModal();
    });
  }

  selectAllChecked = false;

  selectAllCheckboxAction() {
    this.selectAllChecked = !this.selectAllChecked;
    this.reqQuotationData.forEach((r) => {
      r.is_selected = this.selectAllChecked;
    });
  }

  selectCheckboxAction(reqQuotation) {
    reqQuotation.is_selected = !reqQuotation.is_selected;
  }

  units = [];
  getUnits() {
    this.productService.getUnits(500).subscribe((data) => {
      this.units = data;
    });
  }
  async fetchUnit(rfqParserProduct, newProductForm) {
    if (rfqParserProduct != null) {
      var unitId = null;
      for (var item of this.units) {
        if (
          rfqParserProduct["unit"].toLowerCase() == item["name"].toLowerCase()
        ) {
          newProductForm.patchValue({
            unit_id: item["id"],
          });
          unitId = item["id"];
          break;
        }
      }
      if (unitId == null) {
        var unit = this.toSentenceCase(rfqParserProduct["unit"]);
        const formData = new FormData();
        formData.append("name", unit);
        formData.append("is_deleted", "true");
        formData.append("display_name", unit);
        unitId = await this.createUnit(formData, newProductForm);
        newProductForm.patchValue({
          unit_id: unitId,
        });
        newProductForm.get("unit_id").setValue(unitId);
      }
    }
  }

  async createUnit(formData, newProductForm) {
    // this.productService.createUnits(formData).subscribe(
    //   dt => {
    //     this.units.push(dt['data']);
    //     return dt['data']['id'];
    //   },
    //   error => {
    //     this.toastr.error('Something Went Wrong', error.error);
    //   }
    // );
    let dt = await this.productService.createUnits(formData);
    this.units.push(dt["data"]);
    return dt["data"]["id"];
  }

  // categoryId = null;
  subcategory_id = null;
  isLoadingSpecidSubmitButton = false;
  createSpecificProducts() {
    if (this.tempReqQuotation[0].category.id == null) {
      this.toastr.error("Please select category first!");
      return;
    }
    if (!this.shipId) {
      this.toastr.error("Please select ship first!");
      return;
    }
    if (this.portId == null) {
      this.toastr.error("Please select port first!");
      return;
    }
    var i = 0;
    this.isLoadingSpecidSubmitButton = true;
    this.reqQuotationData.forEach(async (r, index) => {
      if (r.is_selected) {
        r.is_selected = false;
        var newProductForm: FormGroup;
        newProductForm = this.formBuilder.group({
          name: [r.description, [Validators.required]],
          description: [r.description, [Validators.required]],
          category_id: this.tempReqQuotation[0].category.id,
          sub_category_id: [null, [Validators.required]],
          requirement_id: [null],
          unit_id: [null, [Validators.required]],
          img_name: [""],
          source: ["WEB"],
          pack_type: [""],
          quantity: [r.new_qty, Validators.compose([Validators.required])],
          ship_id: [this.shipId],
          req_id: [null],
          impa_code: [r.pro_code]
        });
        await this.fetchUnit(r, newProductForm);
        newProductForm.patchValue({
          category_id: this.tempReqQuotation[0].category.id,
        });
        newProductForm.patchValue({
          sub_category_id: this.subcategory_id,
        });

        if (!newProductForm.valid) {
          return;
        }
        var formData = this.sharedService.convertModelToFormData(
          newProductForm.value
        );
        if (this.portId != null) {
          formData.append("port_id", this.portId.toString());
        }
        this.isLoadingSpecidSubmitButton = true;
        var productData = await this.productService.addProductSpecificRequest(
          formData
        );
        if (productData.message) {
          this.isLoadingSpecidSubmitButton = false;
        } else {
          productData.data.quantity = r.new_qty;
          this.isLoadingSpecidSubmitButton = false;
          this.reqQuotationData[index] = {
            description: r.description,
            qty: r.qty,
            new_qty: productData.data.quantity,
            pro_code: r.pro_code,
            unit: r.unit,
            suggestions: [
              {
                name: productData.data.name,
                description: "",
                id: productData.data.id,
                pack_type: "",
                unit: productData.data.unit.name,
                product_code: "",
                impa_code: productData.data.impa_code,
                matching_technique: "",
                matching_percentage: "",
              },
            ],
            remarks: r.remarks,
            is_requested: true,
          };
        }
      }
      this.selectAllChecked = false;
    });
  }

  is_any_selected() {
    if (this.selectAllChecked) {
      return true;
    } else {
      for (var item of this.reqQuotationData) {
        if (item.is_selected) {
          return true;
        }
      }
    }
    return false;
  }

  get sform() {
    return this.searchForm.controls;
  }
  moveTOCartForm() {
    this.moveToCartForm = this.formBuilder.group({
      requirement_name: ["", Validators.required]
    });
  }

  get cart() {
    return this.moveToCartForm.controls;
  }
  generateOrderDataForm() {
    this.orderData = this.formBuilder.group({
      requirement_name: ["", Validators.required],
      requirement_category_id: this.tempReqQuotation[0].category.id,
      eta: ["", Validators.required],
      etd: ["", Validators.required],
      offer: ["", [CustomValidators.checkNumericValue]],
      currency: [null],
      quotation_number: [null],
    });
  }

  get oForm() {
    return this.orderData.controls;
  }

  sendEmailQuotationForm() {
    this.sendQuotationForm = this.formBuilder.group({
      to_emails: ["", [Validators.required, CustomValidators.emailValidator]],
      cc_emails: [null, [Validators.required]],
      requester_details: this.formBuilder.group({
        name: [null, [Validators.required]],
        email: ["", [Validators.required, CustomValidators.emailValidator]],
        phone: ["", [Validators.required, Validators.minLength(4)]],
        designation: ["", [Validators.required]],
      }),
    });
  }

  get form() {
    return this.sendQuotationForm.controls;
  }

  getSheetData(reqQuotationData, count?) {
    let suggetionIds
    return reqQuotationData
      .map((req, index) => {
        if (
          req &&
          req.suggestions &&
          req.suggestions.length > 0 &&
          req.suggestions[0].id
        ) {
          let reqData;
          if (!this.isProductAdded) {
            suggetionIds = req.suggestions
              .map((suggestId) => suggestId.id)
              .join(",");
          } else {
            suggetionIds = this.reqQuotationDatas[index]?.suggestions?.map(x => x.id).join(',')
          }

          if (count == 1) {
            reqData = {
              id:
                req.suggestions && req.suggestions.length > 0
                  ? req.suggestions[0].id
                  : null,
              qty: parseFloat(req.new_qty),
              price:
                this.getVendorInventoryPrices(req.suggestions[0].id) &&
                  this.getVendorInventoryPrices(req.suggestions[0].id).price
                  ? this.getVendorInventoryPrices(req.suggestions[0].id).price
                  : null,
              requested_product_name: req.description,
              requested_product_quantity: parseFloat(req.qty),
              requested_product_unit: req.unit,
              suggested_product_id: suggetionIds ? suggetionIds : null,
              requested_product_code: req.pro_code ? req.pro_code : null
            };
          } else {
            reqData = {
              id:
                req.suggestions && req.suggestions.length > 0
                  ? req.suggestions[0].id
                  : null,
              qty: parseFloat(req.new_qty),
              remark: req.remarks,
              requested_product_name: req.description,
              requested_product_quantity: parseFloat(req.qty),
              requested_product_unit: req.unit,
              suggested_product_id: suggetionIds ? suggetionIds : null,
              requested_product_code: req.pro_code ? req.pro_code : null
            };
          }
          return reqData;
        }
      })
      .filter(Boolean);
  }

  getAllShips() {
    this.shipService.getAll(1, 1000).subscribe((data) => {
      this.shipList = data.results;
      if (
        this.role == this.userRole.ROLE_SUBADMIN &&
        this.subAdminAssignedCompanyIds &&
        this.subAdminAssignedCompanyIds.length > 0
      ) {
        this.shipList = this.shipList.filter((ship) => {
          return this.subAdminAssignedCompanyIds.includes(ship?.company?.id);
        });
      }
    });
  }

  getPortList() {
    this.portService.getAll(1, 1000).subscribe((res) => {
      this.portList = res.results;
    });
  }

  portSelected(event) {
    if (event) {
      if (event.country && event.country.isd_code) {
        this.countryCode = event.country.isd_code;
      }
      this.isLoading = true;
      this.newRequisitionQuotationService.getVendorsByPort(event.id).subscribe(
        (res) => {
          this.vendorList = res;
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
          this.toastr.error("No vendors are available on this port");
        }
      );
    }
  }

  // categorySelected(event) {
  //   if (event) {
  //     this.categoryId = event.id;
  //     this.moveToCartForm.patchValue({
  //       requirement_category_id: this.categoryId,
  //     });
  //     this.orderData.patchValue({
  //       requirement_category_id: this.categoryId,
  //     });
  //   }
  // }

  vendorSelected(event) {
    if (event && this.shipId && this.portId) {
      // this.sheetData = this.getSheetData(this.reqQuotationData);
      this.productLoading = true;
      this.vendorId = event;
      const formData = this.buildPayload(true);
      this.newRequisitionQuotationService
        .getVendorPricesAndInventory(formData)
        .subscribe(
          (res: any) => {
            this.vendorPriceData = res.data;
            if (res.data && res.data.transportation_charges == null) {
              this.vendorPriceData.transportation_charges = 0;
            }
            if (res.data && res.data.launch_charges == null) {
              this.vendorPriceData.launch_charges = 0;
            }
            this.currency =
              res.data && res.data.currency ? res.data.currency : "";
            if (this.isProductAdded) {
              this.tempSheetData = this.getSheetData(this.reqQuotationData, 1);
            } else {
              this.tempSheetData = this.getSheetData(this.reqQuotationData, 1);
            }
            this.isDisableButton();
            this.getTotalPrice();
            this.productLoading = false;
          },
          (error) => {
            this.productLoading = false;
            this.toastr.error("No data found!");
          }
        );
    }
  }

  getVendorInventoryPrices(productId) {
    if (
      productId &&
      this.vendorPriceData &&
      this.vendorPriceData.product_prices &&
      this.vendorPriceData.product_prices.length > 0
    ) {
      let inventoryData = this.vendorPriceData.product_prices.find(
        (elem) => elem.product_id === productId
      );
      return inventoryData;
    }
  }

  editQuantity(event, row, rowIndex) {
    if (event.target.value != "") {
      row.new_qty = parseFloat(event.target.value);
      this.showQuantity[rowIndex] = false;
      // this.updateToLocalStorage(1, rowIndex, row);
      this.tempSheetData = this.getSheetData(this.reqQuotationData, 1);
      this.getTotalPrice();
    }
  }
  prodcutItem = {
    description: "",
    qty: "",
    new_qty: "",
    pro_code: "",
    unit: "",
    suggestions: [
      {
        name: "",
        description: "",
        id: "",
        pack_type: "",
        unit: "",
        product_code: "",
        impa_code: "",
        matching_technique: "",
        matching_percentage: "",
      },
    ],
    remarks: "",
  };

  selectProduct(event) {
    this.isSelectedProduct = event;
  }

  updateItem(event) {
    for (let i = 0; i < this.reqQuotationData.length; i++) {
      if (i == this.index && this.isTrue) {
        if (event.data) {
          this.reqQuotationData[i] = {
            description: this.reqQuotationData[this.index].description,
            qty: this.reqQuotationData[this.index].qty,
            new_qty: event?.data?.quantity,
            pro_code: this.reqQuotationData[this.index].pro_code,
            unit: this.reqQuotationData[this.index].unit,
            suggestions: [
              {
                name: event?.data?.name,
                description: "",
                id: event?.data?.id,
                pack_type: "",
                unit: event?.data?.unit.name,
                product_code: "",
                impa_code: event?.data?.impa_code,
                matching_technique: "",
                matching_percentage: "",
              },
            ],
            remarks: this.reqQuotationData[this.index].remarks,
            is_requested: true,
          };
        } else {
          this.reqQuotationData[i] = {
            description: this.reqQuotationData[this.index].description,
            qty: 0,
            new_qty: event?.data?.quantity ? event?.data?.quantity : 0,
            pro_code: this.reqQuotationData[this.index].pro_code,
            unit: this.reqQuotationData[this.index].unit,
            suggestions: [
              {
                name: event?.name,
                description: "",
                id: event._id,
                pack_type: event?._source?.pack_type,
                unit: event?._source?.pack_type,
                product_code: event?._source?.product_code,
                impa_code: event?.impa_code,
                matching_technique: "",
                matching_percentage: "",
              },
            ],
            remarks: this.reqQuotationData[this.index].remarks,
          };
        }
      }
    }
    if (this.isTrue == undefined && this.isSelectedProduct) {
      this.reqQuotationData.push({
        description: event.name,
        qty: 0,
        new_qty: event?.quantity ? event?.quantity : 0,
        pro_code: "",
        unit: "",
        suggestions: [
          {
            name: event?.name,
            description: "",
            id: event?._id,
            pack_type: event?._source?.pack_type,
            unit: event?._source?.pack_type,
            product_code: event?._source?.product_code,
            impa_code: event?.impa_code,
            matching_technique: "",
            matching_percentage: "",
          },
        ],
        remarks: "",
      });
    } else if (this.isTrue == undefined && !this.isSelectedProduct) {
      this.reqQuotationData.push({
        description: "",
        qty: 0,
        new_qty: event?.data?.quantity ? event?.data?.quantity : 0,
        pro_code: "",
        unit: "",
        suggestions: [
          {
            name: event?.data?.name,
            description: "",
            id: event?.data?.id,
            pack_type: "",
            unit: event?.data?.unit?.name,
            product_code: "",
            impa_code: event?.data?.impa_code,
            matching_technique: "",
            matching_percentage: "",
          },
        ],
        remarks: "",
        is_requested: true,
      });
    }
  }
  updateToLocalStorage(count?, index?, item?) {
    switch (count) {
      case 1:
        this.reqQuotationData[index] = item;
        // localStorage.setItem('reqQuotationData', JSON.stringify(this.reqQuotationData));
        break;
      case 2:
        // localStorage.setItem('reqQuotationData', JSON.stringify(this.reqQuotationData));
        break;
      default:
        return false;
    }
  }

  editDeliveryCharges(event, row) {
    if (event.target.value != "") {
      row.transportation_charges = parseFloat(event.target.value);
      this.showDeliveryCharges = false;
      this.getTotalPrice();
    }
  }

  editLaunchCharges(event, row) {
    if (event.target.value != "") {
      row.launch_charges = parseFloat(event.target.value);
      this.showLaunchCharges = false;
      this.getTotalPrice();
    }
  }

  openProductSuggestionModal(
    template: TemplateRef<any>,
    count = 1,
    title,
    ind?,
    productData?,
    isTrue?
  ) {
    this.isTrue = isTrue;
    this.searchForm.reset();
    this.selectedAutocomplete = "";
    this.firstProductName = "";
    if (count == 1) {
      if (productData && productData.length >= 0) {
        this.firstProductName = productData[0].name;
        this.productSuggestions = productData.filter((x, i) => i > 0);
      }
      this.addNewProduct = false;
    } else {
      this.productSuggestions = [];
      title == "Find A Product"
        ? (this.addNewProduct = false)
        : (this.addNewProduct = true);
    }
    this.productIndex = ind;
    this.popupTitle = title;
    /* if (this.shipId) { */
    this.modalRef = this.modalService.show(
      template,
      Object.assign({
        backdrop: "static",
        class: "gray modal-md product-suggestion",
      })
    );
    this.alertService.backModalClose(this.modalRef);
    /* } else {
      this.toastr.error('Please select ship')
    } */
  }

  addProduct(product, isPrductAdded) {
    this.parse = false;
    this.isProductAdded = isPrductAdded;
    if (
      product &&
      this.reqQuotationData[this.productIndex].suggestions &&
      this.reqQuotationData[this.productIndex].suggestions.length > 0 &&
      this.reqQuotationData[this.productIndex].suggestions[0].id === product.id
    ) {
      this.toastr.error("This product is already available in the table.");
    } else {
      let count = 0;
      /* if (this.reqQuotationData && this.reqQuotationData.length > 0) {
        this.reqQuotationData.forEach((x) => {
          if (
            x &&
            x.suggestions &&
            x.suggestions.length > 0 &&
            x.suggestions[0].id === product.id
          ) {
            this.toastr.error(
              "This product is already available in the table."
            );
            count = 1;
          }
        });
      } */
      if (count === 0) {
        this.reqQuotationData[this.productIndex].suggestions =
          this.reqQuotationData[this.productIndex].suggestions.filter(
            (x) => x.id != product.id
          );
        if (this.addNewProduct) {
          this.reqQuotationData.push(product);
        } else {
          this.reqQuotationData[this.productIndex].suggestions.unshift(product);
        }
        // this.updateToLocalStorage(2);
        this.sheetData = this.getSheetData(this.reqQuotationData);
        if (this.sheetData && this.sheetData.length > 0 && this.vendorId) {
          this.vendorSelected(this.vendorId);
        }
        this.modalRef.hide();
      }
    }
  }

  getTotalPrice() {
    let total = 0;
    if (this.vendorPriceData) {
      if (
        this.vendorPriceData.product_prices &&
        this.vendorPriceData.product_prices.length > 0
      ) {
        this.tempSheetData.forEach((el: any) => {
          if (el && el.qty && el.qty > 0 && el.price && el.price > 0) {
            total += el.price * el.qty;
          }
        });
      }
      this.totalPrice = total;
      if (this.transportCharged) {
        total += this.vendorPriceData.transportation_charges;
      }

      if (this.launchCharged) {
        total += this.vendorPriceData.launch_charges;
      }
      this.grandTotalPrice = total;
    }
  }

  addLaunchCharge(event) {
    this.launchCharged = event;
    this.getTotalPrice();
  }
  addTransportCharged(event) {
    this.transportCharged = event;
    this.getTotalPrice();
  }

  getProduct(
    search = this.selectedAutocomplete,
    addProduct = this.addNewProduct
  ) {
    // this.processing = true;
    const page = 1;
    const limit = 10;
    this.productService.getByCategoryId(null, 0, page, limit, search).subscribe(
      (data) => {
        if (data.results && data.results.length > 0) {
          let searchProd;
          this.product = data.results
            .map((prod, i) => {
              if (prod && i == 0) {
                searchProd = {
                  id: prod.id,
                  impa_code: prod.impa_code,
                  name: prod.name,
                  pack_type: prod.pack_type,
                  product_code: prod.product_code,
                  unit: prod.unit.name,
                };
                return searchProd;
              }
            })
            .filter(Boolean);
          if (
            this.product &&
            this.product.length > 0 &&
            this.productSuggestions &&
            this.productSuggestions.length > 0 &&
            this.productSuggestions.find((x) => x.id === this.product[0].id)
          ) {
            this.toastr.error(
              "This product is already available in the sugesstions table."
            );
          } else {
            if (addProduct && this.product && this.product.length > 0) {
              let tempSuggestion = this.product[0];
              let newProd;
              this.newProduct = this.product.map((prod, i) => {
                if (prod && i == 0) {
                  newProd = {
                    id: prod.id,
                    description: prod.name,
                    pro_code: prod.impa_code,
                    unit: prod.unit,
                    qty: 0,
                    new_qty: 0,
                    suggestions: [tempSuggestion],
                    remarks: "",
                  };
                  return newProd;
                }
              });
            }
            this.productSuggestions.unshift(this.product[0]);
          }
        }
        // this.processing = false;
      },
      (error) => {
        // this.processing = false;
      }
    );
  }

  searchProduct() {
    this.submitted = true;
    this.selectedAutocomplete = this.searchForm.value.search;
    if (!this.selectedAutocomplete) {
      return false;
    }
    this.getProduct(this.selectedAutocomplete);
    this.suggestion = [];
  }

  resetForm() {
    this.searchForm.get("search").setValue("");
    this.selectedAutocomplete = "";
    this.submitted = false;
  }

  search(event) {
    this.searchQuery = event.query;
    this.productService.getProductSuggestion(0, event.query).subscribe(
      (data) => {
        data.data.map((x) => {
          x.name = x._source.title;
          x.impa_code = x._source.impa_code;
          x.product_code = x._source.product_code;
          x.pack_type = x._source.pack_type ? x._source.pack_type : "N/A";
          x.unit = x._source.unit && x._source.unit.name ? x._source.unit.name : x._source.unit ? x._source.unit : '';
        });
        this.suggestion = data.data;
      },
      (error) => {
        this.suggestion = [];
      }
    );
  }

  showCrossBtn(event) {
    this.selectedAutocomplete = event.target.value;
  }

  doOnSelect(event) {
    if (event && event.name) {
      this.selectedAutocomplete = event._source.product_code;
      this.searchForm.get("search").setValue(event.name);
      this.getProduct(event._source.product_code);
    }
    // else if (this.searchQuery === event._source.impa_code) {
    //   this.selectedAutocomplete = event._source.impa_code;
    //   this.searchForm.get('search').setValue(event._source.impa_code);
    //   this.getProduct(0, 0, event._source.impa_code);
    // } else if (this.searchQuery === event._source.product_code) {
    //   this.selectedAutocomplete = event._source.product_code;
    //   this.searchForm.get('search').setValue(event._source.product_code);
    //   this.getProduct(0, 0, event._source.product_code);
    // }
    else {
      this.selectedAutocomplete = event.name;
      this.searchForm.get("search").setValue(event.name);
      this.getProduct(event.name);
    }
    this.suggestion = [];
  }

  moveToCart() {
    this.sheetData = this.getSheetData(this.reqQuotationData);
    this.cartLoading = true;
    if (this.sheetData && this.sheetData.length > 0) {
      if (!this.moveToCartForm.valid) {
        this.cartLoading = false;
        this.toastr.error("Please Enter Required field!");
        return false;
      } else {
        const formData = this.buildPayload();
        let reqCategory = this.tempReqQuotation[0].category.id;
        if (reqCategory != "") {
          formData.append("requirement_category_id", `${reqCategory}`);
        }
        this.newRequisitionQuotationService.moveItemsToCart(formData).subscribe(
          (res) => {
            if (res) {
              this.modelRef.hide();
              this.submitted = false;
              this.cartLoading = false;
              this.toastr.success("Items successfully moved to cart!");
            }
          },
          (error) => {
            this.cartLoading = false;
            this.toastr.error(
              error && error.error
                ? error.error
                : "Something went wrong! Please try again."
            );
          }
        );
      }
    } else {
      this.cartLoading = false;
      this.toastr.error("Something went wrong! Please refresh.");
    }
  }

  createRequisition(template: TemplateRef<any>) {
    if (this.isProductAdded) {
      this.sheetData = this.getSheetData(this.reqQuotationData);
    } else {
      this.sheetData = this.getSheetData(this.reqQuotationData);
    }
    /* if (this.sheetData && this.sheetData.length > 0 && this.sheetData.find(x => x.qty === 0)) {
      this.toastr.error('Product quantity can not be zero!');
      return false;
    } */
    this.modelRef = this.modalService.show(
      template,
      Object.assign({ backdrop: "static", class: "gray modal-md" })
    );
  }

  orderConfirm(confirmOrder) {
    if (!this.orderData.valid) {
      this.submitted = true;
    } else {
      this.modelRef1 = this.modalService.show(
        confirmOrder,
        Object.assign({ backdrop: "static", class: "gray modal-sm" })
      );
      this.alertService.backModalClose(this.modelRef1);
    }
  }
  automaticparse(value) {
    this.parse = value;
  }
  placeRequisition() {
    this.isLoading1 = true;
    const formData = this.buildPayload(true, true);
    console.log("shitdata1", this.sheetData);

    formData.append(
      "requirement_name",
      this.orderData.get("requirement_name").value
    );
    formData.append(
      "requirement_category_id",
      this.tempReqQuotation[0].category.id
    );
    formData.append(
      "eta",
      this.datepipe.transform(
        this.orderData.get("eta").value,
        "yyyy-MM-dd HH:mm:ss"
      )
    );
    formData.append(
      "etd",
      this.datepipe.transform(
        this.orderData.get("etd").value,
        "yyyy-MM-dd HH:mm:ss"
      )
    );
    formData.append("mobile_prefix", this.countryCode);
    formData.append("source", "Web");
    formData.append("time_in_seconds", this.time_in_seconds == 0 ? 1 : this.time_in_seconds);
    formData.append("automatically_parsed", JSON.stringify(this.parse));
    if (this.orderData.get("currency").value != null) {
      formData.append("currency", this.orderData.get("currency").value);
    }
    formData.append(
      "quotation_number",
      this.orderData.get("quotation_number").value
    );
    /* if (this.orderData.get('offer').value == '' || this.orderData.get('offer').value == null) {
      formData.append('offer', '0');
    } else {
      formData.append('offer', this.orderData.get('offer').value);
    } */
    this.newRequisitionQuotationService.placeRequisition(formData).subscribe(
      (res) => {
        this.isLoading1 = false;
        this.modelRef1.hide();
        setTimeout(() => {
          this.toastr.success("", "Requisition created successfully!", {
            closeButton: true,
            timeOut: 2000,
            positionClass: "toast-top-center",
          });
          this.router.navigate(["/requisitions"]);
          this.indexedDbService.deleteDatabase()
            .then(() => console.log('Database deleted successfully'))
            .catch(error => console.error(error));
          //localStorage.removeItem("reqQuotationData");
        });
      },
      (error) => {
        this.isLoading1 = false;
        this.modelRef1.hide();
        this.toastr.error(
          error && error.error ? error.error
            : "Something went wrong! Please try again."
        );
      }
    );
  }

  hideModal() {
    this.orderData.get("requirement_name").setValue(null);
    // this.orderData.get("requirement_category_id").setValue(null);
    this.orderData.get("eta").setValue(null);
    this.orderData.get("etd").setValue(null);
    this.orderData.get("offer").setValue(null);
    //this.orderData.get('currency').setValue(null);
    this.modelRef.hide();
  }

  openModal(template: TemplateRef<any>, index?) {
    this.removeItemIndex = index === 0 || index >= 0 ? index : null;

    /*  if (index && (index == 0 || index > 0)) {
       this.removeItemIndex = index;
     } */
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: "gray modal-small" })
    );
    this.alertService.backModalClose(this.modalRef);
  }

  removeProduct() {
    this.reqQuotationData.splice(this.removeItemIndex, 1);
    // this.updateToLocalStorage(2);
    this.sheetData = this.getSheetData(this.reqQuotationData);
    this.tempSheetData = this.getSheetData(this.reqQuotationData, 1);
    this.isDisableButton();
    this.getTotalPrice();
  }

  buildPayload(addVendorId = false, addTransport = false) {
    const formData = new FormData();
    let reqName = this.moveToCartForm.get("requirement_name").value;
    formData.append("sheet_data", JSON.stringify(this.sheetData));
    formData.append("ship_id", `${this.shipId}`);
    formData.append("port_id", `${this.portId}`);
    if (reqName != "") {
      formData.append("requirement_name", `${reqName}`);
    }
    /* if (reqCategory != '') {
      formData.append('requirement_category_id', `${reqCategory}`);
    } */
    /* if (addVendorId) {
      formData.append('vendor_id', `${this.vendorId}`);
    } */
    /* if (addTransport) {
      formData.append('transportation_charges', `${this.vendorPriceData && this.vendorPriceData.transportation_charges > 0 ? this.vendorPriceData.transportation_charges : '0'}`);
      formData.append('launch_charges', `${this.vendorPriceData && this.vendorPriceData.launch_charges > 0 ? this.vendorPriceData.launch_charges : '0'}`);
    } */
    return formData;
  }

  exportRequisitionQuotation(type = "xlsx") {
    this.sheetData = this.getSheetData(this.reqQuotationData);
    if (
      this.shipId &&
      this.portId &&
      this.sheetData &&
      this.sheetData.length > 0
    ) {
      let fileName = "";
      this.exportReq = true;
      fileName = (
        "Requisition_Quotation_" +
        "_" +
        this.shipId +
        "_" +
        this.portId
      ).replace(/ /g, "_");
      const mime =
        type === "pdf" ? "application/pdf" : "application/vnd.ms-excel";
      const formData = this.buildPayload(true, true);
      this.newRequisitionQuotationService
        .exportRequisitionQuotation(formData)
        .subscribe(
          (data) => {
            this.exportReq = false;
            this.sharedService.saveAsBlob(data, mime, `${fileName}.${type}`);
            this.toastr.clear();
          },
          (error) => {
            this.exportReq = false;
            this.toastr.error(
              error && error.message
                ? error.message
                : "Something went wrong! Please try again."
            );
          }
        );
    } else {
      this.toastr.error("Something went wrong! Please try again.");
    }
  }

  isDisableButton() {
    if (
      this.vendorPriceData &&
      this.vendorPriceData.product_prices &&
      this.vendorPriceData.product_prices.length >= 0
    ) {
      this.isDisabledBtn = true;
      if (
        this.sheetData.length ==
        this.vendorPriceData.product_prices.filter(
          (elem) => elem.price && elem.price > 0
        ).length
      ) {
        this.isDisabledBtn = true;
      }
    } else {
      this.isDisabledBtn = false;
    }
  }

  openPopup(productId) {
    //this.loadingService.setLoading(true);
    this.productService.getByid(productId).subscribe(
      (data) => {
        this.productDetail = data;
        //this.loadingService.setLoading(false);
        this.clickSubject.next();
      },
      (error) => {
        //this.loadingService.setLoading(false);
        this.toastr.error("Product not found!");
      }
    );
  }


  onButtonClick() {

  }
  openSendQuotationModal(template: TemplateRef<any>) {
    this.requesterDetails = this.sharedservice.requesterDetails;
    this.toEmails = [];
    this.toEmailItems = [];
    this.ccEmails = [
      "operations@shipskart.com",
      "aastha@shipskart.com",
      "vivek@shipskart.com",
    ];
    this.ccEmailItems = [
      "operations@shipskart.com",
      "aastha@shipskart.com",
      "vivek@shipskart.com",
    ];
    this.modalRef = this.modalService.show(
      template,
      Object.assign({ backdrop: "static", class: "gray modal-lg" })
    );
    this.alertService.backModalClose(this.modalRef);
  }

  sendQuotations() {
    this.submitted = true;
    if (!this.sendQuotationForm.valid) {
      return false;
    } else {
      this.submitted = false;
      this.isLoading = true;
      // this.sendQuotationForm.get('to_emails').setValue(Array.from(new Set(this.toEmails)));
      this.sendQuotationForm
        .get("cc_emails")
        .setValue(Array.from(new Set(this.ccEmails)));
      const formData = this.buildPayload(true, true);
      formData.append(
        "to_emails",
        `${this.sendQuotationForm.get("to_emails").value}`
      );
      formData.append(
        "cc_emails",
        `${this.sendQuotationForm.get("cc_emails").value}`
      );
      formData.append(
        "requester_details",
        JSON.stringify(this.sendQuotationForm.get("requester_details").value)
      );
      this.newRequisitionQuotationService
        .sendRequisitionEmail(formData)
        .subscribe(
          (res) => {
            this.modalRef.hide();
            this.toastr.success("Quotation sent successfully!");
            this.isLoading = false;
            this.sendQuotationForm.reset();
          },
          (error) => {
            this.toastr.error("Something went wrong!");
            this.isLoading = false;
          }
        );
    }
  }

  resetQuotationForm() {
    this.sendQuotationForm.reset();
  }

  editRemark(event, row, rowIndex) {
    if (event.target.value != "") {
      row.remarks = event.target.value;
      // this.updateToLocalStorage(1, rowIndex, row);
    }
  }

  showDetails(event) {
    if (event) {
      this.sendQuotationForm.controls.requester_details
        .get("name")
        .setValue(event.name);
      this.sendQuotationForm.controls.requester_details
        .get("email")
        .setValue(event.email);
      this.sendQuotationForm.controls.requester_details
        .get("phone")
        .setValue(event.phone);
      this.sendQuotationForm.controls.requester_details
        .get("designation")
        .setValue(event.designation);
    }
  }

  checkToEmails(event) {
    if (event && event.length > 0) {
      event.forEach((item, i) => {
        if (!this.commonService.validateEmail(item)) {
          this.toEmails.splice(i, 1);
          this.toEmailItems.splice(i, 1);
          this.sendQuotationForm.get("to_emails").setValue(this.toEmails);
        }
      });
    }
  }

  checkCCEmails(event) {
    if (event && event.length > 0) {
      event.forEach((item, i) => {
        if (!this.commonService.validateEmail(item)) {
          this.ccEmails.splice(i, 1);
          this.ccEmailItems.splice(i, 1);
          this.sendQuotationForm.get("cc_emails").setValue(this.ccEmails);
        }
      });
    }
  }

  addCCEmails(event) {
    if (event) {
      if (
        event.value == "operations@shipskart.com" ||
        event.value == "aastha@shipskart.com" ||
        event.value == "vivek@shipskart.com"
      ) {
        this.ccEmails = this.ccEmails.concat([event.value]);
        this.ccEmailItems = this.ccEmailItems.concat([event.value]);
        this.sendQuotationForm.get("cc_emails").setValue(this.ccEmails);
      }
    }
  }

  onSelectEta(event) {
    this.etd = null;
    this.minEta = this.commonService.verifyEta(event, this.eta, this.minEta);
  }

  setSelectedDate(event) {
    if (this.etd < this.minEta) {
      this.etd = this.minEta;
    }
  }

  ngOnDestroy(): void {
    // this.newRequisitionQuotationService.getrequisitionQuotationData(null);
    this.reqQuotationData = null;
    this.indexedDbService.deleteDatabase()
      .then(() => console.log('Database deleted successfully'))
      .catch(error => console.error(error));
  }
  getSubcategory() {
    const page = 1;
    // this.categoryService.getAllV2(page, 20).subscribe((data) => {
    //   this.category = data.data;
    // });
    this.categoryService.getSubCategoryV2(true).subscribe((data) => {
      this.subcategory_id = data.filter((x) => {
        return x.name == "Unknown";
      })[0].id;
    });
  }

  moveToCartModal() {
    this.submitted = false;
    this.modelRef = this.modalService.show(
      this.moveToCartPopup,
      Object.assign({ backdrop: "static", class: "gray modal-md" })
    );
  }

  addProductRequest(event) {
    this.modalRef.hide();
    if (event) {
      this.toastr.success("Product added successfully in requisition");
      // this.ngOnInit();
    } else {
    }
  }

  showModal(template, req, i, isTrue) {
    this.isTrue = isTrue;
    this.index = i;
    this.currentSelectedRFQProduct = req;
    if (this.shipId) {
      this.modalRef = this.modalService.show(template);
    } else {
      this.toastr.error("Please Select Ship to Request Product!");
    }
  }
  closeModalClicked(event) {
    this.currentSelectedRFQProduct = null;
    this.modalRef.hide();
    if (event) {
      this.toastr.success("Product added successfully in requisition");
      /*  this.ngOnInit(); */
    } else {
    }
  }

  changeQuotationNUmber(event) {
    this.orderData.patchValue({
      quotation_number: event.target.value.toString(),
    });
  }
  /* Checking if Product code is not impa show label as Product Code */
  check(str) {
    const restrictedWords = ["PROV", "SHIP", "BOND"];
    // Convert to uppercase for case-insensitive comparison
    if (str) {
      str = str.toUpperCase();
      // Check if any of the restricted words are present using some()
      return !restrictedWords.some(word => str.includes(word));
    }
  }
}
