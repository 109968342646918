import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { DashboardService } from '@app/dashboard/dashboard.service';
import { AlertService } from '@app/shared/service/alert.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DataConfiguration } from "@app/dashboard/models/data-configuration.model";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NewDashboard } from '@app/dashboard/models/newdashboard.model';
import { Feedback } from '@app/core/enums/feedbackform';
@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PanelComponent implements OnInit, OnChanges {
  modalRef: any;
  @Input() panelData: any[] = [];
  dataCard = [{ id: "Data Card", value: "data_card" }, { id: "Others", value: "other" }];
  otherAxisIds: number[] = []
  viewData = [];
  dataConfigurationForm: FormGroup;
  selectedView;
  reportView;
  axes = [];
  report_id: number;
  loadingData: boolean;
  inputValue: any;
  checkboxStates: boolean[];
  topNID = Feedback.FILTER_ID;
  selectedType;
  selectedData;
  @ViewChild('panelModal', { static: false }) panelModal: TemplateRef<any>;
  @Output() callParentMethodEvent = new EventEmitter<any>();
  @Input() edit: boolean;
  @Input() sharedData: NewDashboard;
  filters = [];
  primaryAxis: boolean;
  primaryAxies = []
  secondayAxis = [];
  secondayAxisDataCard = [];
  isDataCardSelected: boolean = false;
  isReportOrViewSelected: boolean = false;
  otherSelected: string;
  newpanelData: any[] = [];
  filtersDataCard: any[] = [];
  constructor(
    private modalService: BsModalService,
    private altService: AlertService,
    private dashboardService: DashboardService,
    private formBuilder: FormBuilder,
    private alertService: ToastrService
  ) { }

  ngOnInit(): void {
    // this.getPanelData()
    this.dataConfiguration();
  }
  get panelDatas() {
    return this.panelData;
  }
  /* ngOnChange() {

    this.loadingData = true;
    this.newpanelData = [...this.panelData['data']];
    this.filters = [...this.panelData['filters']];
    this.secondayAxis = [...this.panelData['secondary_axes']];
  } */
  // Getting main axes, other axes ,and assigning dynamic data to the fields of panel modal
  getPanelData() {
    this.loadingData = true;
    this.dashboardService.getPanelData().subscribe((data) => {
      this.panelData = [...data.data];
      this.filtersDataCard = [...data['filters']];
      this.secondayAxisDataCard = [...data['secondary_axes']];
      this.viewData = this.panelData.filter(report => report);
      this.loadingData = false;
    });
  }

  // Data Configuration Form Template
  dataConfiguration() {
    this.dataConfigurationForm = this.formBuilder.group({ // Data Configuration Form Blueprint
      title: [null, [Validators.required]],
      other_report: [null],
      report_type: [null, [Validators.required]],
      primary_axis_id: [null, [Validators.required]],
      other_axis_ids: [null, [Validators.required]],
      filter_ids: [null],
      option_id: [null]
    })
  }

  // Executes after submitting the form
  onSubmit() {
    if (this.edit) {
      if ((this.selectedView == 'Data Card') || (this.selectedView == 'Bar Graph') || (this.selectedView == 'Line Graph')) {
        this.dataConfigurationForm.patchValue({ other_axis_ids: [this.dataConfigurationForm.get('other_axis_ids').value] })
      }
      else {
        this.dataConfigurationForm.patchValue({ other_axis_ids: Array.from(new Set(this.dataConfigurationForm.get('other_axis_ids').value)) })
      }
      this.dataConfigurationForm.get('report_type').enable();
      this.dataConfigurationForm.get('primary_axis_id').enable();
      this.dashboardService.updateReportData(this.dataConfigurationForm.value, this.report_id).subscribe(data => {
        if (data) {
          this.alertService.success('Panel Updated Successfully!')
          this.resetForm();
          setTimeout(() => {
            this.callParentMethodEvent.emit(this.report_id);
          }, 200)
        }
      }, error => {
        this.alertService.error(error.error);
        this.resetForm();
      })
    }
    else {
      if (this.isDataCardSelected) {
        this.dataConfigurationForm.patchValue({ other_axis_ids: [this.dataConfigurationForm.get('other_axis_ids').value] })
      }
      else {
        let reportType = this.dataConfigurationForm.get('report_type').value;
        this.dataConfigurationForm.get('report_type').setValue(reportType.report_type)
      }
      if ((this.selectedView == 'Data Card') || (this.selectedView == 'Bar Graph') || (this.selectedView == 'Line Graph')) {
        this.dataConfigurationForm.patchValue({ other_axis_ids: [this.dataConfigurationForm.get('other_axis_ids').value] })
      }
      this.dashboardService.createPanel(this.dataConfigurationForm.value).subscribe(data => {
        if (data) {
          this.alertService.success('Panel Created Successfully!')
          this.resetForm();
          setTimeout(() => {
            this.callParentMethodEvent.emit();
          }, 200)
        }
      }, error => {
        this.alertService.error(error.error);
        this.resetForm();
      })
    }
  }

  // Shows modal on the UI
  openModel() {
    this.getPanelData()
    this.modalRef = this.modalService.show(
      this.panelModal,
      Object.assign({ backdrop: 'static', class: 'gray modal-lg' })
    )
    this.altService.backModalClose(this.modalRef)
    if (this.edit)
      this.getPanelById();
  }

  // For modifying values of input decorators
  ngOnChanges(changes: SimpleChanges): void {
    this.getPanelById();
  }

  // Checks what view is selected 
  /**
   * viewPanelBody(): method calls for change the Report type
   * data param: param used for   
   * */
  viewPanelBody(data) {
    if (data?.report_name) {
      this.selectedType = data.report_name;
      this.selectedData = data.primary_axis?.[0]?.name ? data.primary_axis[0].name : '';
      this.dataConfigurationForm.get('title').setValue(`${this.selectedType} - ${this.selectedData}`)
    }
    this.isReportOrViewSelected = true;
    //this.dataConfigurationForm.patchValue({ report_type: data.value })
    if (data.value == 'data_card') {
      this.dataConfigurationForm.get('report_type').setValue(`${this.dataConfigurationForm.get('other_report').value}`)
      this.dataConfigurationForm.get('primary_axis_id').clearValidators()
      this.dataConfigurationForm.get('primary_axis_id').updateValueAndValidity();
      this.isDataCardSelected = true;
    } else {
      this.isDataCardSelected = false;
      this.viewData = this.panelData.filter(report => report);
    }
    if (!this.isDataCardSelected) {
      if (data.value == 'other') {
        this.dataConfigurationForm.get('report_type').reset();
        this.dataConfigurationForm.get('primary_axis_id').reset();
        this.dataConfigurationForm.get('other_axis_ids').reset();
        this.dataConfigurationForm.get('filter_ids').reset();
        this.isDataCardSelected = false
      } else {
        this.otherSelected = 'Others'
        this.dataConfigurationForm.get('primary_axis_id').reset();
        this.dataConfigurationForm.get('other_axis_ids').reset();
        this.dataConfigurationForm.get('filter_ids').reset();
        this.primaryAxies = data?.primary_axis?.filter(primary => primary);
        this.dataConfigurationForm.get('option_id').setValue(data.id);
        this.dataConfigurationForm.patchValue({ primary_axis_id: data?.primary_axis[0]?.id });
        const secondoryAxisId = this.primaryAxies[0]?.secondary_axis?.map(secondary => secondary.id);
        this.secondayAxis = this.primaryAxies[0]?.secondary_axis.filter(secondary => secondary)
        this.dataConfigurationForm.patchValue({ other_axis_ids: secondoryAxisId });
        const allFilter = data?.filters.map(filterData => filterData.id);
        this.filters = data?.filters.filter(filterData => filterData)
        this.dataConfigurationForm.patchValue({ filter_ids: allFilter });
        this.dataConfigurationForm.get('primary_axis_id').setValidators([Validators.required])
      }
      this.dataConfigurationForm.get('primary_axis_id').updateValueAndValidity();
    } else {
      this.dataConfigurationForm.get('other_axis_ids').reset();
      this.dataConfigurationForm.get('filter_ids').reset();
      this.filtersDataCard = this.filtersDataCard;
      this.secondayAxisDataCard = this.secondayAxisDataCard;
      this.filtersDataCard = this.filtersDataCard.filter(filterData => filterData);
      const allFilter = this.filtersDataCard.map(filterData => filterData.id);
      this.dataConfigurationForm.patchValue({ report_type: data.value });
      this.dataConfigurationForm.get('title').setValue(this.secondayAxisDataCard[0]?.name);
      this.dataConfigurationForm.patchValue({ other_axis_ids: this.secondayAxisDataCard[0]?.id });
      this.dataConfigurationForm.patchValue({ filter_ids: allFilter });
      this.dataConfigurationForm.get('primary_axis_id').clearValidators();
    }
    this.dataConfigurationForm.get('primary_axis_id').updateValueAndValidity();

  }

  selectDataCard(event) {
    this.dataConfigurationForm.get('title').setValue(event?.name);
  }

  /** 
   * resetForm(): resetform this method reset the report form. 
   * 
   *  */
  resetForm() {
    this.dataConfigurationForm.reset();
    this.selectedView = '';
    this.otherAxisIds.length = 0;
    this.axes.length = 0;
    this.isDataCardSelected = false;
    this.primaryAxies = [];
    this.isReportOrViewSelected = false;
  }
  /**
   * getPanelById(): method call for edit report and subscribe api get reportdata.
   * patch: patch data based on api call.   
   * */
  async getPanelById() {
    return new Promise<void>((resolve, reject) => {
      this.dataConfiguration();
      let mainAxisId;
      this.loadingData = true;
      if (this.edit) {
        this.report_id = this.sharedData.report_id
        this.selectedView = this.sharedData.report_type.split("_").map(data => data[0].toUpperCase() + data.slice(1,)).join(" ")
        if (this.primaryAxis) {
          this.dataConfigurationForm.get('primary_axis_id').setValidators([Validators.required]);
        }
        else {
          this.dataConfigurationForm.get('primary_axis_id').clearValidators();
        }
        this.dataConfigurationForm.get('primary_axis_id').updateValueAndValidity();
        this.dashboardService.getReportData(this.sharedData.report_id, null, 0)
          .then((response) => {
            if (!this.edit) {
              this.loadingData = false;
            }
            if (response[0].report_type == "pie_chart" || response[0].report_type == 'data_card') {
              this.primaryAxis = false
              this.isDataCardSelected = true
              debugger

            } else {
              this.primaryAxis = true;
              this.isDataCardSelected = false;
              if (!this.edit) {
                this.loadingData = false;
              }
            }
            if (response.length > 0) {
              //Filter Primary axis data at edit time and show as disable.
              this.primaryAxies = response[0].axes.filter(axes => axes.axis.is_main_axis == true).map(axis => axis.axis)
              response[0].axes.forEach(data => {
                if (!data.is_main_axis) {
                  this.axes.push(data.axis_id)
                } else {
                  mainAxisId = data.axis_id
                }
              })
              if (response[0].report_type !== 'data_card') {
                this.isDataCardSelected = false
                let filters = this.panelData?.filter(report => report.id == response[0].option_id);
                this.primaryAxies = filters?.map(primary => primary.primary_axis)[0];
                this.secondayAxis = this.primaryAxies?.map(seconday => seconday.secondary_axis)[0];
                this.filters = this.panelData?.map(filterData => filterData.filters)[0];
              }
              this.otherAxisIds = [...this.axes];
              let reportType = ''
              if (this.sharedData.report_type == 'data_card') {
                reportType = "Data Card"
              } else {
                reportType = "Others"
              }
              this.dataConfigurationForm.patchValue({
                title: this.sharedData?.report_title,
                primary_axis_id: mainAxisId ? mainAxisId : null,
                other_axis_ids: ((this.selectedView == 'Data Card') || (this.selectedView == 'Bar Graph') || (this.selectedView == 'Line Graph')) ? this.otherAxisIds[0] : this.otherAxisIds,
                report_type: this.sharedData?.report_type,
                other_report: reportType,
                filter_ids: response[0].filters?.map(data => data.filter_id),
                option_id: response[0]?.option_id
              })
              this.dataConfigurationForm.get('primary_axis_id').disable();
              this.loadingData = false;
              resolve(); // Resolve the promise without passing any value since data is already updated in the form
            }
            else {
              this.loadingData = false;
              this.alertService.error('No Data Found!');
              reject(new Error('No Data Found!')); // Reject the promise with an error
            }
          })
          .catch(error => {
            this.loadingData = false;
            this.alertService.error('Failed to fetch data!');
            reject(error); // Reject the promise with the error
          });
      }
    });
  }


  /**
   * changePrimaryAxis(): This method is used for filter out secondary axis.
   * secondaryAxis: this variable storing list of all secondary axis of selected primary axis.
    */
  changePrimaryAxis(event) {
    this.selectedData = event.name;
    this.dataConfigurationForm.get('title').setValue(`${this.selectedType} - ${this.selectedData}`)
    this.secondayAxis = event.secondary_axis.map(secondaryData => secondaryData);
    let secondaryAxisId = this.secondayAxis.map(axisId => axisId.id)
    this.dataConfigurationForm.patchValue({ other_axis_ids: secondaryAxisId })
  }

  /**
   * convertFormat(): report name splitting and remove underscore
    */
  convertFormat(report) {
    let reportName = report.split('_').join(' ');
    return reportName
  }
}
