<form [formGroup]="companyDetailsForm" (ngSubmit)="submitCompanyDetails()">
<div class="kyc-form">
  <h3>Section 1: Company Details and General Information</h3>
  <div class="company-details">
    <div class="row">
      <div class="col-md-6 pad-right">
        <div class="form-group kyc-field" [ngClass]="{error: submitted && companyDetailsForm.get('company_name').errors}">
          <label for="">Name of Company<span class="mendatory">*</span></label>
          <input type="text" formControlName="company_name" class="form-control" placeholder="Enter Name of Organization" maxlength="255" (input)="changesData($event)">
          <input-validation [control]="companyDetailsForm.get('company_name')" [submit]="submitted"></input-validation>
          <!-- <div class="errorbox" *ngIf="submitted && companyDetailsForm.get('company_name').errors">
            <span>Enter company name</span>
          </div> -->
        </div>
      </div>
      <div class="col-md-6"></div>
      <div class="col-md-6 pad-right">
        <div class="form-group kyc-field no-margin" [ngClass]="{error: (submitted && companyDetailsForm.get('company_address_line_one').errors)}">
          <label for="">Company Address<span class="mendatory">*</span></label>
          <input type="text" formControlName="company_address_line_one" class="form-control" placeholder="Street Address Line 1" maxlength="100" (input)="changesData($event)">
          <input-validation [control]="companyDetailsForm.get('company_address_line_one')" [submit]="submitted"></input-validation>
          <!-- <div class="errorbox" *ngIf="submitted && companyDetailsForm.get('company_address_line_one').errors">
            <span>Enter company address</span>
          </div> -->
        </div>
        <div class="form-group kyc-field">
          <input type="text" formControlName="company_address_line_two" class="form-control tmargin15" placeholder="Street Address Line 2" maxlength="100" (input)="changesData($event)">
        </div>
        <div class="form-fields-wrap">
          <div class="form-group kyc-field" [ngClass]="{error: submitted && companyDetailsForm.get('company_address_city').errors}">
            <label for="">City<span class="mendatory">*</span></label>
            <input type="text" formControlName="company_address_city" class="form-control" placeholder="Enter City Name" maxlength="200" (input)="changesData($event)">
            <input-validation [control]="companyDetailsForm.get('company_address_city')" [submit]="submitted"></input-validation>
            <!-- <div class="errorbox" *ngIf="submitted && companyDetailsForm.get('company_address_city').errors">
              <span>Enter city</span>
            </div> -->
          </div>
          <div class="form-group kyc-field" [ngClass]="{error: submitted && companyDetailsForm.get('company_address_pin_code').errors}">
            <label for="">Pincode</label>
            <input type="text" formControlName="company_address_pin_code" onkeypress="return /^[a-zA-Z0-9\s]+$/.test(event.key)" class="form-control" placeholder="Enter Pincode" (input)="changesData($event)">
            <!-- <input-validation [control]="companyDetailsForm.get('company_address_pin_code')" [submit]="submitted"></input-validation> -->
            <!-- <div class="errorbox" *ngIf="submitted && companyDetailsForm.get('company_address_pin_code').errors">
              <span>Enter pincode</span>
            </div> -->
          </div>
        </div>
        <div class="form-fields-wrap">
          <div class="form-group kyc-field" [ngClass]="{error: submitted && companyDetailsForm.get('company_address_country').errors}">
            <label for="">Country<span class="mendatory">*</span></label>
            <ng-select [items]="countryList" formControlName="company_address_country" bindLabel="name" bindValue="id" placeholder="Select Country" class="form-control new-dropdown" clearAllText="Clear" [clearable]="false" (change)="changesData($event)" #select>
            </ng-select>
            <input-validation [control]="companyDetailsForm.get('company_address_country')" [submit]="submitted"></input-validation>
            <!-- <div class="errorbox" *ngIf="submitted && companyDetailsForm.get('company_address_country').errors">
              <span>Select country</span>
            </div> -->
          </div>
          <div class="form-group kyc-field" [ngClass]="{error: submitted && companyDetailsForm.get('company_address_email').errors}">
            <label for="">Email Id<span class="mendatory">*</span></label>
            <input type="text" formControlName="company_address_email" class="form-control" placeholder="Enter Email Id" maxlength="200" (input)="changesData($event)">
            <input-validation [control]="companyDetailsForm.get('company_address_email')" [submit]="submitted"></input-validation>
            <!-- <div class="errorbox" *ngIf="submitted && companyDetailsForm.get('company_address_email').errors">
              <span>Enter valid email id</span>
            </div> -->
          </div>
        </div>
        <div class="form-fields-wrap">
          <div class="form-group kyc-field">
            <label for="">Telephone</label>
            <input type="text" formControlName="company_address_telephone" pKeyFilter="pnum" maxlength="16" class="form-control" placeholder="Enter Number" (input)="changesData($event)">
            <ng-container *ngIf="submitted && companyDetailsForm.get('company_address_telephone').errors">
              <span class="errorbox">* Invalid telephone number.</span>
            </ng-container>
          </div>
          <div class="form-group kyc-field" [ngClass]="{error: (submitted && companyDetailsForm.get('company_address_isd_code').errors) || (submitted && companyDetailsForm.get('company_address_mobile').errors)}">
            <label for="">Mobile No.<span class="mendatory">*</span></label>
            <div class="mobile-prefix">
              <ng-select [items]="countryList" formControlName="company_address_isd_code" bindLabel="isd_code" bindValue="isd_code" placeholder="Code" class="form-control new-dropdown" clearAllText="Clear" [clearable]="false" (change)="changesData($event)">
              </ng-select>&nbsp;<input type="text" formControlName="company_address_mobile" pKeyFilter="pnum" maxlength="16" class="form-control" placeholder="Enter Number" (input)="changesData($event)">
            </div>
            <div class="errorbox" *ngIf="(submitted && companyDetailsForm.get('company_address_isd_code').errors) || (submitted && companyDetailsForm.get('company_address_mobile').errors)">
              <span *ngIf="submitted && companyDetailsForm.get('company_address_isd_code').errors">Select country code<br></span>
              <span *ngIf="submitted && companyDetailsForm.get('company_address_mobile').errors">Enter mobile number</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 pad-left">
        <div class="form-group kyc-field no-margin" [ngClass]="{error: (submitted && companyDetailsForm.get('mailing_address_line_one').errors)}">
          <label for="">P.O. Box and Mailing Address<span class="mendatory">*</span></label>
          <input type="text" [readonly]="disableAddress" formControlName="mailing_address_line_one" class="form-control" placeholder="Street Address Line 1" maxlength="100">
          <input-validation [control]="companyDetailsForm.get('mailing_address_line_one')" [submit]="submitted"></input-validation>
          <!-- <div class="errorbox" *ngIf="submitted && companyDetailsForm.get('mailing_address_line_one').errors">
            <span>Enter P.O. Box and mailing address</span>
          </div> -->
        </div>
        <div class="form-group kyc-field">
          <input type="text" [readonly]="disableAddress" formControlName="mailing_address_line_two" class="form-control tmargin15" placeholder="Street Address Line 2" maxlength="100">
        </div>
        <div class="form-fields-wrap">
          <div class="form-group kyc-field" [ngClass]="{error: submitted && companyDetailsForm.get('mailing_address_city').errors}">
            <label for="">City<span class="mendatory">*</span></label>
            <input type="text" [readonly]="disableAddress" formControlName="mailing_address_city" class="form-control" placeholder="Enter City Name" maxlength="200">
            <input-validation [control]="companyDetailsForm.get('mailing_address_city')" [submit]="submitted"></input-validation>
            <!-- <div class="errorbox" *ngIf="submitted && companyDetailsForm.get('mailing_address_city').errors">
              <span>Enter city</span>
            </div> -->
          </div>
          <div class="form-group kyc-field" [ngClass]="{error: submitted && companyDetailsForm.get('mailing_address_pin_code').errors}">
            <label for="">Pincode</label>
            <input type="text" [readonly]="disableAddress" formControlName="mailing_address_pin_code" onkeypress="return /^[a-zA-Z0-9\s]+$/.test(event.key)" class="form-control" placeholder="Enter Pincode">
            <!-- <input-validation [control]="companyDetailsForm.get('mailing_address_pin_code')" [submit]="submitted"></input-validation> -->
            <!-- <div class="errorbox" *ngIf="submitted && companyDetailsForm.get('mailing_address_pin_code').errors">
              <span>Enter pincode</span>
            </div> -->
          </div>
        </div>
        <div class="form-fields-wrap">
          <div class="form-group kyc-field" [ngClass]="{error: submitted && companyDetailsForm.get('mailing_address_country').errors}">
            <label for="">Country<span class="mendatory">*</span></label>
            <ng-select [items]="countryList" [readonly]="disableAddress" formControlName="mailing_address_country" bindLabel="name" bindValue="id" placeholder="Select Country" class="kyc-input form-control new-dropdown" clearAllText="Clear" [clearable]="false">
            </ng-select>
            <input-validation [control]="companyDetailsForm.get('mailing_address_country')" [submit]="submitted"></input-validation>
            <!-- <div class="errorbox" *ngIf="submitted && companyDetailsForm.get('mailing_address_country').errors">
              <span>Select country</span>
            </div> -->
          </div>
          <div class="form-group kyc-field" [ngClass]="{error: submitted && companyDetailsForm.get('mailing_address_email').errors}">
            <label for="">Email Id<span class="mendatory">*</span></label>
            <input type="text" [readonly]="disableAddress" formControlName="mailing_address_email" class="form-control" placeholder="Enter Email Id" maxlength="200">
            <input-validation [control]="companyDetailsForm.get('mailing_address_email')" [submit]="submitted"></input-validation>
            <!-- <div class="errorbox" *ngIf="submitted && companyDetailsForm.get('mailing_address_email').errors">
              <span>Enter valid email id</span>
            </div> -->
          </div>
        </div>
        <div class="form-fields-wrap">
          <div class="form-group kyc-field">
            <label for="tel">Telephone</label>
            <input type="text" id="tel" [readonly]="disableAddress" formControlName="mailing_address_telephone" pKeyFilter="pnum" maxlength="16" class="form-control" placeholder="Enter Number">

          <ng-container *ngIf="companyDetailsForm.get('mailing_address_telephone').hasError('pattern')">
            <span class="errorbox">* Invalid telephone number.</span>
          </ng-container>
          </div>
          <div class="form-group kyc-field" [ngClass]="{error: (submitted && companyDetailsForm.get('mailing_address_isd_code').errors) || (submitted && companyDetailsForm.get('mailing_address_mobile').errors)}">
            <label for="mobile">Mobile No.<span class="mendatory">*</span></label>
            <div class="mobile-prefix">
              <ng-select [items]="countryList" [readonly]="disableAddress" formControlName="mailing_address_isd_code" bindLabel="isd_code" bindValue="isd_code" placeholder="Code" class="kyc-input form-control new-dropdown" clearAllText="Clear" [clearable]="false" (change)="changesData($event)" >
              </ng-select>&nbsp;<input type="text"  id="mobile" [readonly]="disableAddress" formControlName="mailing_address_mobile" pKeyFilter="pnum" maxlength="16" class="form-control" placeholder="Enter Number">
            </div>
            <ng-container *ngIf="companyDetailsForm.get('mailing_address_mobile').hasError('pattern')">
              <span class="errorbox">* Invalid mobile number.</span>
            </ng-container>
            <div class="errorbox" *ngIf="(submitted && companyDetailsForm.get('mailing_address_isd_code').errors) || (submitted && companyDetailsForm.get('mailing_address_mobile').errors)">
              <span *ngIf="submitted && companyDetailsForm.get('mailing_address_isd_code').errors">Select country code<br></span>
              <span *ngIf="submitted && companyDetailsForm.get('mailing_address_mobile').errors">Enter mobile number</span>
            </div>
          </div>
        </div>
        <div class="form-group kyc-field bmargin20">
          <label for="same-address" class="custom-checkbox tick">
            <input type="checkbox" id="same-address" [disabled]="isVisibleCheckBox()" [checked]="sameCompanyAddress" (change)="copyCompanyAddress($event)">
            <span class="shape"></span> Same as Company Address
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="company-details remove-border">
    <div class="row">
      <div class="col-md-6 pad-right">
        <div class="form-group kyc-field bmargin30 resmargint">
          <label for="">Parent Company (Full Legal Name)</label>
          <input type="text" formControlName="parent_company_name" class="form-control" placeholder="Enter Full Legal Name" maxlength="255">
        </div>
      </div>
      <div class="col-md-6 pad-left">
        <div class="form-group kyc-field bmargin30" [ngClass]="{error: submitted && companyDetailsForm.get('company_website').errors}">
          <label for="">Website</label>
          <input type="text" formControlName="company_website" class="form-control" placeholder="http://www.abc.com" maxlength="200">
          <input-validation [control]="companyDetailsForm.get('company_website')" [submit]="submitted"></input-validation>
          <!-- <div class="errorbox" *ngIf="submitted && companyDetailsForm.get('company_website').errors?.pattern">
            <span>Enter a valid url</span>
          </div> -->
        </div>
      </div>
      <div class="col-md-12">
        <label class="bmargin5 label-text">Contact Person Details<span class="mendatory">*</span></label>
        <div class="tabular-detail data-table">
          <div class="scroll-table">
            <table class="table full-table req-table price update new">
              <thead>
                  <tr>
                    <th width="50">Sr. No</th>
                    <th>Name of Concerned Person<span class="mendatory">*</span></th>
                    <th>Designation<span class="mendatory">*</span></th>
                    <th>Email-ID<span class="mendatory">*</span></th>
                    <th>Mobile Number<span class="mendatory">*</span></th>
                    <th>Primary Contact<span class="mendatory">*</span></th>
                  </tr>
              </thead>
              <tbody formArrayName="contact_person_details">
                <tr *ngFor="let contactPerson of companyDetailsForm.get('contact_person_details')['controls']; index as i" [formGroupName]="i">
                  <td>{{i+1}}</td>
                  <td>
                    <div class="form-group kyc-field no-margin" [ngClass]="{error: submitted && contactPersonDetails.controls[i].get('name').errors}">
                      <input type="text" formControlName="name" class="table-field" placeholder="Enter Name" maxlength="50">
                      <input-validation [control]=" contactPersonDetails.controls[i].get('name')" [submit]="submitted"></input-validation>
                      <!-- <div class="errorbox" *ngIf="submitted && contactPersonDetails.controls[i].get('name').errors">
                        <span>Enter name</span>
                      </div> -->
                    </div>
                  </td>
                  <td>
                    <div class="form-group kyc-field no-margin" [ngClass]="{error: submitted && contactPersonDetails.controls[i].get('designation').errors}">
                      <input type="text" formControlName="designation" class="table-field" placeholder="Enter Designation" maxlength="50">
                      <input-validation [control]="contactPersonDetails.controls[i].get('designation')" [submit]="submitted"></input-validation>
                      <!-- <div class="errorbox" *ngIf="submitted && contactPersonDetails.controls[i].get('designation').errors">
                        <span>Enter designation</span>
                      </div> -->
                    </div>
                  </td>
                  <td>
                    <div class="form-group kyc-field no-margin" [ngClass]="{error: submitted && contactPersonDetails.controls[i].get('email').errors}">
                      <input type="text" formControlName="email" class="table-field" placeholder="Enter Email Id" maxlength="50">
                      <input-validation [control]="contactPersonDetails.controls[i].get('email')" [submit]="submitted"></input-validation>
                      <!-- <div class="errorbox" *ngIf="submitted && contactPersonDetails.controls[i].get('email').errors">
                        <span>Enter valid email id</span>
                      </div> -->
                    </div>
                  </td>
                  <td>
                    <div class="form-group kyc-field no-margin" [ngClass]="{error: submitted && contactPersonDetails.controls[i].get('mobile').errors}">
                      <input type="text" formControlName="mobile" pKeyFilter="pnum" maxlength="16" class="table-field" placeholder="Enter Number">
                      <!-- <input-validation [control]="contactPersonDetails.controls[i].get('mobile')" [submit]="submitted"></input-validation> -->
                      <div class="errorbox" *ngIf="submitted && contactPersonDetails.controls[i].get('mobile').errors">
                        <span>Enter mobile</span>
                      </div>
                      <ng-container *ngIf="contactPersonDetails.controls[i].get('mobile').hasError('pattern')">
                        <span class="errorbox">* Invalid mobile number.</span>
                      </ng-container>
                    </div>
                  </td>
                  <td width="135px" class="border-right">
                    <div class="form-group kyc-field no-margin" [class.top-mar]="submitted && !selectPrimary">
                        <label class="custom-checkbox tick kyc" for="primary + {{ i }}"><input
                        id="primary + {{ i }}"
                        name="is-primary"
                        type="radio"
                        [checked]="contactPersonDetails.controls[i].get('is_primary').value === true ? contactPersonDetails.controls[i].get('is_primary').value : false"
                        (change)="primaryContact($event, i)"
                        /><span class="shape"></span>
                        </label>
                        <!-- <input-validation [control]="contactPersonDetails.controls[i].get('is_primary')" [submit]="submitted"></input-validation> -->
                        <div class="errorbox" *ngIf="submitted && !selectPrimary">
                          <span>Select primary contact</span>
                        </div>
                    </div>
                  </td>
                  <td class="add-td">
                    <div class="add-row">
                      <button type="button" class="close cross" *ngIf="i > 0" (click)="removeContactPersonDetailsItem(i)">&#10006;</button>
                      <a href="javascript:void(0)" (click)="addContactPersonDetailsItem()">+ Add Row</a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-6 pad-right">
        <div class="form-group kyc-field bmargin30">
          <label>Nature of Business<span class="mendatory">*</span></label>
          <div class="check-box-wrap">
            <label for="nature-1" class="custom-checkbox">
              <input type="checkbox" id="nature-1" [checked]="natureOfBusiness && natureOfBusiness.includes('Manufacturer')" (change)="selectNatureOfBusiness($event.target.id, $event.srcElement.checked, 'Manufacturer')">
              <span class="shape"></span> Manufacturer
            </label>
            <label for="nature-2" class="custom-checkbox">
              <input type="checkbox" id="nature-2" [checked]="natureOfBusiness && natureOfBusiness.includes('Trader')" (change)="selectNatureOfBusiness($event.target.id, $event.srcElement.checked, 'Trader')">
              <span class="shape"></span> Trader
            </label>
          </div>
          <div class="check-box-wrap">
            <label for="nature-3" class="custom-checkbox">
              <input type="checkbox" id="nature-3" [checked]="natureOfBusiness && natureOfBusiness.includes('Consulting Company')" (change)="selectNatureOfBusiness($event.target.id, $event.srcElement.checked, 'Consulting Company')">
              <span class="shape"></span> Consulting Company
            </label>
            <label for="nature-4" class="custom-checkbox">
              <input type="checkbox" id="nature-4" [checked]="natureOfBusiness && natureOfBusiness.includes('Authorized Agent')" (change)="selectNatureOfBusiness($event.target.id, $event.srcElement.checked, 'Authorized Agent')">
              <span class="shape"></span> Authorized Distributor
            </label>
          </div>
          <div class="check-box-wrap other">
            <label for="nature-5" class="custom-checkbox">
              <input type="checkbox" id="nature-5" [checked]="!otherNature" (change)="selectNatureOfBusiness($event.target.id, $event.srcElement.checked, 'Others')">
              <span class="shape"></span> Others
            </label>
          <div class="form-group kyc-field no-margin width100" [ngClass]="{error: submitted && !otherNature && otherNatureOfBusiness === ''}">
            <input type="text" class="form-control" *ngIf="!otherNature" [disabled]="otherNature" (change)="otherNatureOfBusinessField($event.target.value, 'nature-5');" [(ngModel)]="otherNatureOfBusiness" [ngModelOptions]="{standalone: true}"  placeholder="Please Specify Nature of Business" maxlength="255">
          </div>
          </div>
          <div class="errorbox" *ngIf="submitted && !selectOtherNature || (submitted && selectOtherNature || otherNatureOfBusiness === '')">
            <span *ngIf="submitted && otherNature && natureOfBusinessCheck && natureOfBusinessCheck.length === 0">Select nature of business</span>
            <span *ngIf="submitted && !otherNature && otherNatureOfBusiness === ''">Enter other nature of business</span>
          </div>
          <div class="row agent-certificate" [class.show]="showAgentCertificate">
            <div class="col-md-7">
              <p class="small-note tmargin10 bmargin10">If you mark "<span>Authorized Agent</span>" or "<span>Trader</span>", please provide certificate(s)</p>
            </div>
            <div class="col-md-5 remove-pad">
              <div class="form-group tmargin10 bmargin10" [ngClass]="{ error: submitted && inValidAgentFileSize}">
                <div class="select-file upload bmargin5">
                  <img src="/assets/images/file-upload-icon.svg" alt="">
                  <input class="form-control" accept=".pdf,.jpg,.jpeg,.png" (change)="selectedAgentFileName($event.target.files)" type="file"> Attach Files <span class="btn-span">Browse</span>
                </div>
                <div class="filename">
                  <span *ngIf="!authorizedAgentCertificate || agentFileName">{{agentFileName || 'No Files Chosen'}}</span>
                  <a *ngIf="authorizedAgentCertificate" [href]="authorizedAgentCertificate" target="_blank" class="d-block tmargin5 file-view" download>View Agent Certificate</a>
                </div>
                <div class="errorbox" *ngIf="submitted && inValidAgentFileSize">
                  <!-- (submitted && !selectAgentFile) || <span *ngIf="!selectAgentFile">Select File</span> -->
                  <span *ngIf="inValidAgentFileSize">File is larger than <strong>3mb !!</strong></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 pad-left">
        <div class="form-group kyc-field bmargin30">
          <label>Type of Business<span class="mendatory">*</span></label>
          <div class="check-box-wrap">
            <label for="type-1" class="radio-box">
              <input type="radio" class="radio-tab" id="type-1" name="form-tab" [checked]="companyDetails && companyDetails.type_of_business === 'Corporate Limited'" (change)="selectTypeOfBusiness($event)"> Corporate limited
            </label>
            <label for="type-2" class="radio-box">
              <input type="radio" class="radio-tab" id="type-2" name="form-tab" [checked]="companyDetails && companyDetails.type_of_business === 'Partnership'" (change)="selectTypeOfBusiness($event)"> Partnership
            </label>
          </div>
          <div class="check-box-wrap other">
            <label for="type-3" class="radio-box">
              <input type="radio" class="radio-tab" id="type-3" name="form-tab" [checked]="!otherType" (change)="selectTypeOfBusiness($event)"> Others
            </label>
            <div class="form-group kyc-field no-margin width100" [ngClass]="{error: submitted && selectOtherType && otherTypeOfBusiness === ''}">
              <input type="text" *ngIf="!otherType" [disabled]="otherType" (change)="otherTypeOfBusinessField($event);" [(ngModel)]="otherTypeOfBusinessValue" [ngModelOptions]="{standalone: true}" class="form-control" placeholder="Please Specify Type of Business" maxlength="255">
            </div>
          </div>
          <div class="errorbox" *ngIf="submitted && otherTypeOfBusiness === ''">
            <span *ngIf="submitted && !selectOtherType">Select type of business</span>
            <span *ngIf="submitted && selectOtherType && otherTypeOfBusiness === ''">Enter other business type</span>
          </div>
        </div>
      </div>
      <div class="col-md-6 pad-right bmargin30">
          <div class="row">
            <div class="col-sm-12 col-md-7 col-lg-7">
              <div class="form-group kyc-field no-margin" [ngClass]="{error: submitted && companyDetailsForm.get('registration_number').errors}">
                <label for="">Company Registration Number</label>
                <input type="text" class="form-control" formControlName="registration_number" placeholder="Enter Number" maxlength="255">
                <input-validation [control]="companyDetailsForm.get('registration_number')" [submit]="submitted"></input-validation>
                <!-- <div class="errorbox" *ngIf="submitted && companyDetailsForm.get('registration_number').errors">
                  <span>Enter company registration number</span>
                </div> -->
              </div>
            </div>
            <div class="col-sm-12 col-md-5 col-lg-5 resmargin">
              <div class="form-group kyc-field" [ngClass]="{error: submitted && companyDetailsForm.get('year_established').errors}">
                <label class="bmargin0" for="">Year Established<span class="mendatory">*</span></label>
                <input type="text" formControlName="year_established" onkeypress="return /[0-9]/i.test(event.key)" maxlength="4" class="form-control" placeholder="Enter Year">
                <!-- <ng-select [items]="years" formControlName="year_established" bindLabel="value" bindValue="value" placeholder="Select Year" class="form-control new-dropdown" clearAllText="Clear" [clearable]="false">
                </ng-select> -->
                <input-validation [control]="companyDetailsForm.get('year_established')" [submit]="submitted"></input-validation>
                <!-- <div class="errorbox" *ngIf="submitted && companyDetailsForm.get('year_established').errors">
                  <span>Enter a valid year</span>
                </div> -->
              </div>
            </div>
          </div>
      </div>
      <div class="col-md-6 pad-left resmarginb">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group kyc-field bmargin30" [ngClass]="{error: submitted && companyDetailsForm.get('number_of_full_time_employees').errors}">
              <label for="">Number of Full-time Employees<span class="mendatory">*</span></label>
              <input type="text" formControlName="number_of_full_time_employees" pKeyFilter="pnum" maxlength="6" class="form-control" placeholder="Enter Number">
              <input-validation [control]="companyDetailsForm.get('number_of_full_time_employees')" [submit]="submitted"></input-validation>
              <!-- <div class="errorbox" *ngIf="submitted && companyDetailsForm.get('number_of_full_time_employees').errors">
                <span>Enter number of full-time employees</span>
              </div> -->
            </div>
          </div>
          <div class="col-md-4 col-sm-12 pad-left">
            <div class="form-group kyc-field" [ngClass]="{error: submitted && companyDetailsForm.get('currencies').errors}">
            <label for="">Currency Dealing In<span class="mendatory">*</span></label>
              <ng-select [items]="currencyDropdown" formControlName="currencies" bindLabel="name" bindValue="id" [multiple]="false" placeholder="Select Currency" class="form-control new-dropdown multiple" [closeOnSelect]="false" [clearOnBackspace]="true" [clearSearchOnAdd]="true">
              </ng-select>
              <input-validation [control]="companyDetailsForm.get('currencies')" [submit]="submitted"></input-validation>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 pad-right">
        <div class="form-group kyc-field bmargin30">
          <div class="row">
            <div class="col-md-10">
              <div class="form-group kyc-field no-margin" [ngClass]="{error: submitted && companyDetailsForm.get('gst_number').errors}">
                <label for="">GST no./ VAT no. (attach certificate)</label>
                <input type="text" formControlName="gst_number" class="form-control" placeholder="Enter GST Number" maxlength="255">
                <input-validation [control]="companyDetailsForm.get('gst_number')" [submit]="submitted"></input-validation>
                <!-- <div class="errorbox" *ngIf="submitted && companyDetailsForm.get('gst_number').errors">
                  <span>Enter Gst number</span>
                </div> -->
              </div>
              <div class="filename-box tmargin10">
                <div class="form-group kyc-field no-margin" [ngClass]="{ error: submitted && inValidGstFileSize}">
                  <div class="select-file upload bmargin5">
                    <img src="/assets/images/file-upload-icon.svg" alt="">
                    <input class="form-control" accept=".pdf,.jpg,.jpeg,.png" (change)="selectedGstFileName($event.target.files)" type="file" id="file"> Attach Files <span class="btn-span">Browse</span>
                  </div>
                  <div class="filename">
                    <span *ngIf="!gstCertificate || fileName">{{fileName || 'No Files Chosen'}}</span>
                    <a *ngIf="gstCertificate" [href]="gstCertificate" target="_blank" class="d-block tmargin5 file-view" download>View Gst Certificate</a>
                  </div>
                  <div class="errorbox" *ngIf="submitted && inValidGstFileSize">
                    <!-- (submitted && !selectGstFile) || <span *ngIf="!selectGstFile">Select File</span> -->
                    <span *ngIf="inValidGstFileSize">File is larger than <strong>3mb !!</strong></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5 pad-left">
        <div class="form-group kyc-field bmargin30" [ngClass]="{error: submitted && companyDetailsForm.get('services_offered').errors}">
          <label for="">Services Offered</label>
          <input type="text" formControlName="services_offered" class="form-control" placeholder="Enter Text" maxlength="255">
          <input-validation [control]="companyDetailsForm.get('services_offered')" [submit]="submitted"></input-validation>
          <!-- <div class="errorbox" *ngIf="submitted && companyDetailsForm.get('services_offered').errors">
            <span>Enter services offered</span>
          </div> -->
        </div>
      </div>
      <div class="col-md-6 pad-right">
        <div class="form-group kyc-field bmargin30">
          <label>Categories Dealing with<span class="mendatory">*</span></label>
          <div class="row">
            <div class="col-md-10">
              <div class="check-box-wrap">
                <label for="61" class="custom-checkbox wid52">
                  <input type="checkbox" id="61" [checked]="companyDetails && companyDetails.company_categories.includes('61')" (change)="selectCategory($event)">
                  <span class="shape"></span> Provisions
                </label>
                <label for="87" class="custom-checkbox">
                  <input type="checkbox" id="87" [checked]="companyDetails && companyDetails.company_categories.includes('87')" (change)="selectCategory($event)">
                  <span class="shape"></span> Electrical
                </label>
              </div>
            </div>
            <div class="col-md-10">
              <div class="check-box-wrap">
                <label for="56" class="custom-checkbox wid52">
                  <input type="checkbox" id="56" [checked]="companyDetails && companyDetails.company_categories.includes('56')" (change)="selectCategory($event)">
                  <span class="shape"></span> Lubes
                </label>
                <label for="51" class="custom-checkbox">
                  <input type="checkbox" id="51" [checked]="companyDetails && companyDetails.company_categories.includes('51')" (change)="selectCategory($event)">
                  <span class="shape"></span> Paints
                </label>
              </div>
            </div>
            <div class="col-md-10">
              <div class="check-box-wrap">
                <label for="88" class="custom-checkbox wid52">
                  <input type="checkbox" id="88" [checked]="companyDetails && companyDetails.company_categories.includes('88')" (change)="selectCategory($event)">
                  <span class="shape"></span> Deck
                </label>
                <label for="89" class="custom-checkbox">
                  <input type="checkbox" id="89" [checked]="companyDetails && companyDetails.company_categories.includes('89')" (change)="selectCategory($event)">
                  <span class="shape"></span> Engine
                </label>
              </div>
            </div>
            <div class="col-md-10">
              <div class="check-box-wrap">
                <label for="55" class="custom-checkbox">
                  <input type="checkbox" id="55" [checked]="companyDetails && companyDetails.company_categories.includes('55')" (change)="selectCategory($event)">
                  <span class="shape"></span> Galley & Linen
                </label>
              </div>
            </div>
            <div class="col-md-10">
              <div class="check-box-wrap">
                <label for="95" class="custom-checkbox">
                  <input type="checkbox" id="95" [checked]="companyDetails && companyDetails.company_categories.includes('95')" (change)="selectCategory($event)">
                  <span class="shape"></span> Spare
                </label>
              </div>
            </div>
            <!-- <div class="col-md-6">
              <div class="check-box-wrap">
                <label for="cat-9" class="custom-checkbox wid100">
                  <input type="checkbox" id="cat-9">
                  <span class="shape"></span> Navigational Equipment
                </label>
              </div>
            </div>
            <div class="col-md-6">
              <div class="check-box-wrap">
                <label for="cat-10" class="custom-checkbox wid100">
                  <input type="checkbox" id="cat-10">
                  <span class="shape"></span> Nautical Publications
                </label>
              </div>
            </div> -->
          </div>
          <!-- <input-validation [control]="companyDetailsForm.get('mailing_address_line_one')" [submit]="submitted"></input-validation> -->
          <div class="errorbox" *ngIf="submitted && categoryIds && categoryIds.length === 0">
            <span>Select category</span>
          </div>
        </div>
      </div>
      <div class="col-md-6 pad-left">
          <label class="label-text">Countries & Ports of Service<span class="mendatory">*</span></label>
          <div class="country-ports-wrap bmargin20">
            <ng-container formArrayName="country_and_port_of_services">
              <div class="ports-fields" *ngFor="let countryPortItem of companyDetailsForm.get('country_and_port_of_services')['controls']; index as sl" [formGroupName]="sl">
                  <div class="form-fields-wrap">
                    <div class="form-group kyc-field wid50" [ngClass]="{error: submitted && countryPortServices.controls[sl].get('country_id').errors}">
                      <ng-select [items]="countryList" formControlName="country_id" bindLabel="name" bindValue="id" (change)="getPort($event, sl);countryPortForms[sl].portSelected = null;" placeholder="Select Country" class="form-control new-dropdown" clearAllText="Clear" [clearable]="false">
                      </ng-select>
                      <input-validation [control]="countryPortServices.controls[sl].get('country_id')" [submit]="submitted"></input-validation>
                      <!-- <div class="errorbox" *ngIf="submitted &&  countryPortServices.controls[sl].get('country_id').errors">
                        <span>Select country</span>
                      </div> -->
                    </div>
                    <div class="form-group kyc-field wid50" [ngClass]="{error: submitted && countryPortServices.controls[sl].get('port_id').errors}">
                      <ng-select class="auto-grow" [items]="countryPortForms[sl].portList" formControlName="port_id" bindLabel="name" bindValue="id" [multiple]="true" [(ngModel)]="countryPortForms[sl].portSelected" placeholder="Select Port" class="form-control rfq-subcatlist new-dropdown multiple" [closeOnSelect]="false" [clearOnBackspace]="true" [clearSearchOnAdd]="true">
                      </ng-select>
                      <input-validation [control]="countryPortServices.controls[sl].get('port_id')" [submit]="submitted"></input-validation>
                      <!-- <div class="errorbox" *ngIf="submitted && countryPortServices.controls[sl].get('port_id').errors">
                        <span>Select port</span>
                      </div> -->
                    </div>
                  </div>
                  <div class="add-row">
                    <button type="button" class="close cross" [class.hide]="sl === 0" (click)="removeCountryPortsItem(sl);">&#10006;</button>
                    <a href="javascript:void(0)" (click)="addCountryPortGroup(null, null);">+ Add More</a>
                  </div>
              </div>
            </ng-container>
          </div>
      </div>
    </div>
  </div>
</div>
<div class="submit-btn-box tmargin20 bmargin10">
  <button type="button" class="btn empty-cart back" (click)="resetForm()">Reset</button>
  <button type="submit" class="buttn place save alignText">Save and Continue</button>
</div>
</form>
