import { SharedService } from '@app/shared/service/shared.service';
import { CategoryService } from '@app/modules/category/services/category.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { first, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '../../services/product.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { CompanyService } from '@app/modules/company/services/company.service';
import { LoadingService } from '@app/authentication/services/loading.service';
import { UserRoles } from '@app/core/enums/user-roles';

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: ['./product-form.component.css']
})
export class ProductFormComponent implements OnInit, OnDestroy {
  productForm: FormGroup;
  // product: Product = null;
  submitted = false;
  loading = false;
  toggleProductView = false;
  // product: Product;
  categoryList;
  subCategoryList;
  isEditMode: boolean = false;
  isRequested: boolean;
  codeExists: boolean;
  productId: number;
  units = [];
  variants = [];
  variantInput = new Subject<string>();
  SelectLoading = false;
  source = 'WEB';
  selectedCategory;
  selectedSubCategory;
  lastProductCode = '';
  showVariants = true;
  productVariants = [];

  status = [{ name: 'Active', value: 1 }, { name: 'Inactive', value: 0 }];

  page = 1;
  limit = 10;
  prev = false;
  next = false;
  errMsg;
  value;
  file: File;
  image: File;
  enableParent = true;
  showMakeParent = false;
  private unsubscribe$ = new Subject<any>();
  searching: boolean;
  role: number;
  companyType: number;
  userRoles = UserRoles;
  brands = [];
  warehouses = [];
  unit = [];
  taxes = [];
  vendors = []
  interTax = [];
  intraTax = [];
  isLoading: boolean = false;
  isSubmitted: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private productService: ProductService,
    private alertService: ToastrService,
    private categoryService: CategoryService,
    private route: ActivatedRoute,
    private router: Router,
    private sharedService: SharedService,
    private companyService: CompanyService,
    private loadingService: LoadingService
  ) {
    this.role = this.sharedService.getUserRole();
    this.companyType = this.sharedService.getCompany().type;
  }

  ngOnInit() {
    this.createProductForm()
    this.taxForm()
    this.FormEssential();
    this.route.params.subscribe(params => {
      if (params.id) {
        if ((this.role === this.userRoles.ROLE_ADMIN) || (this.role === this.userRoles.ROLE_COMPANY_ADMIN && this.companyType === 2) || (this.role === this.userRoles.ROLE_SUBADMIN)) {
          this.productId = params.id;
          this.loading = true;
          this.loadingService.setLoading(true);
          this.showVariants = false;
          this.getProduct();
        } else {
          this.router.navigate(['/']);
        }
      }
    });

    this.getUnit();

    this.getSubCategory();
  }

  createProductForm() {
    return this.productForm = this.formBuilder.group({
      id: [''],
      name: ['', [Validators.required]],
      description: ['', [Validators.required]],
      category_id: [null],
      sub_category_id: [null, [Validators.required]],
      product_code: ['', [Validators.required]],
      unit_id: [null, [Validators.required]],
      hsn: [''],
      tax: [''],
      impa_code: [''],
      issa_code: [''],
      pack_type: [],
      variant_product_id: [''],
      variant_product_name: [''],
      make_child_parent_product_id: [''],
      make_parent: [null],
      status: [1, [Validators.required]],
      img_name: [''],
      file_attachment: [''],
      source: [this.source],
      zoho_warehouses: [null],
      zoho_vendors: [null],
      zoho_brand: [],
      zoho_taxes: this.formBuilder.array([]),
      zoho_unit: [null],
      zoho_pack_type: [],
      zoho_price: [1]
    });

  }
  get form() {
    return this.productForm.controls;
  }
  get zohoTaxes() {
    return this.productForm.get('zoho_taxes') as FormArray;
  }

  selectWarehouse(event) {
    this.productForm.get('zoho_warehouse').setValue([event.warehouse_id])
  }
  /*  setInterTax(event) {
     if (event) {
       this.productForm.controls.zoho_taxes.value[0].tax_id = event.tax_id;
       this.productForm.controls.zoho_taxes.value[0].tax_specification = event.tax_specification
     }
   }
   setIntraTax(event) {
     if (event) {
       this.productForm.controls.zoho_taxes.value[1].tax_id = event.tax_id;
       this.productForm.controls.zoho_taxes.value[1].tax_specification = event.tax_specification
     }
   } */
  taxForm() {
    const add = this.productForm.controls.zoho_taxes as FormArray;
    for (let i = 0; i < 2; i++) {
      if (i == 0) {
        add.push(this.formBuilder.group({
          tax_id: '420021000000011045',
          tax_specification: 'inter',
        }));
      }
      if (i == 1) {
        add.push(this.formBuilder.group({
          tax_id: '420021000000011081',
          tax_specification: 'intra',
        }));
      }

    }
  }
  selectedfileName(files: FileList) {
    if (files.length > 0) {
      this.file = files[0];
    }
  }
  selectedImage(files: FileList) {
    if (files.length > 0) {
      this.image = files[0];
    }
  }

  searchVariants(text) {
    this.searching = false;
    if (this.selectedSubCategory != null) {
      this.searching = true;
      this.productService
        .getProductSuggestion(this.selectedSubCategory.id, text)
        .subscribe(data => {
          data.data.map(x => {
            x.id = x._id;
            x.name = x._source.title;
            x.impa_code = x._source.impa_code;
            x.product_code = x._source.product_code;
            x.unit = x._source.unit || (x._source.unit && x._source.unit.name) ? x._source.unit || x._source.unit.name : null;
          });
          this.variants = data.data;
          this.searching = false;
        }, error => {
          this.variants = [];
        }, () => {
          this.searching = false;
        });
    }
  }

  selectVariant(e) {
    this.productService.getProductVariants(e.id).subscribe(data => {
      this.productVariants = data.data;
      this.productForm.patchValue({
        name: e.name,
        variant_product_id: e.id,
        variant_product_name: e.name,
        description: this.productVariants[0].description
      });
      if (this.productForm.get('name').value) {
        this.productForm.get('name').disable();
      }
      this.showMakeParent = true;
      this.productVariants.map(x => {
        if (x.id === x.variant_product_id) {
          x.active = true;
        }
      });
      // this.productService.getUnits(104).subscribe(units => {
      //   this.units = units;
      //   for(let i =0; i< this.productVariants.length; i++)  {
      //     for(let j =0; j< this.units.length; j++)  {
      //       if(this.productVariants[i].unit.name == this.units[j].name) {
      //         this.units[j].disabled = true;
      //       }
      //     }
      //   }
      // });
    }, error => {
      this.productForm.get('variant_product_name').setValue('');
      this.productForm.get('name').setValue('');
      this.productForm.get('name').enable();
      this.productForm.get('description').setValue('');
      this.alertService.error(error.error.message);
    });
  }

  selectVariantProduct(isChecked) {
    if (isChecked.target.id != '-1') {
      this.productForm.patchValue({
        make_parent: 0,
        make_child_parent_product_id: isChecked.target.id
      });
    } else {
      this.productForm.patchValue({
        make_parent: 1,
        make_child_parent_product_id: ''

      });
    }
  }

  onClearProduct() {
    this.productForm.get('name').enable();
    this.productForm.patchValue({
      name: null,
    });
    this.productVariants = [];
    this.showMakeParent = false;
  }

  // private searchVariants() {
  //   this.variants = concat(
  //     of([]), // default items
  //     this.variantInput.pipe(
  //       debounceTime(200),
  //       distinctUntilChanged(),
  //       tap(() => (this.SelectLoading = true)),
  //       switchMap(term =>
  //         this.productService.getProductSuggestion(0, term.toString()).pipe(
  //           catchError(() => of([])), // empty list on error
  //           tap(() => (this.SelectLoading = false))
  //         )
  //       )
  //     )
  //   );
  // }


  saveProduct() {
    if (this.isEditMode) {
      this.editProduct();
    } else {
      this.addProduct();
    }
  }

  addProduct() {
    this.submitted = true;
    this.isSubmitted = true;
    if (!this.productForm.valid) {
      return;
    } else {
      if (this.productForm.get('hsn').value && this.productForm.get('hsn').value.length !== 6) {
        this.alertService.error('Product HSN length should be 6 digits.');
        return false;
      }
      const formData = this.sharedService.convertModelToFormData(
        this.productForm.getRawValue()
      );
      formData.append('zoho_warehouses', JSON.stringify(this.productForm.get('zoho_warehouses').value))
      formData.append('zoho_vendors', JSON.stringify(this.productForm.get('zoho_vendors').value))
      let zohoTax = this.productForm.get('zoho_taxes').value;
      formData.append('zoho_taxes', JSON.stringify(zohoTax))
      if (formData.get('img_name')) {
        formData.append('image', this.image, this.image.name);
      }
      if (formData.get('file_attachment')) {
        formData.append('file_attachment', this.file, this.file.name);
      }
      if (formData.get('make_child_parent_product_id') && this.productVariants.length > 0) {
        formData.append('make_parent', '0');
      } else if (formData.get('make_child_parent_product_id') == '' && this.productVariants.length > 0) {
        formData.append('make_parent', '1');
      } else { }
      this.isLoading = true;
      this.productService
        .addProduct(formData)
        .pipe(first())
        .subscribe(productData => {
          this.isLoading = false;
          this.isSubmitted = false;
          this.alertService.success('Successfully added');
          this.router.navigate(['product/details/', productData.data.id]);
        }, error => {
          this.isLoading = false;
          this.alertService.error(error.error);
        });
    }
  }



  editProduct() {
    this.submitted = true;
    this.isSubmitted = true;
    const warehouse = ['Chennai', 'Gandhidham', 'Kochin', 'Mumbai'];
    if (!this.productForm.valid) {
      return;
    } else {
      if (this.productForm.get('hsn').value && this.productForm.get('hsn').value.length !== 6) {
        this.alertService.error('Product HSN length should be 6 digits.');
        return false;
      }
      const status = this.productForm.get('status').value;
      const formData = this.sharedService.convertModelToFormData(
        this.productForm.getRawValue()
      );
      formData.append('zoho_warehouses', warehouse.join(','))
      if (formData.get('img_name')) {
        formData.append('image', this.image, this.image.name);
      }
      if (formData.get('file_attachment')) {
        formData.append('file_attachment', this.file, this.file.name);
      }
      if (formData.get('make_child_parent_product_id') && this.productVariants.length > 0) {
        formData.append('make_parent', '0');
      } else if (formData.get('make_child_parent_product_id') == '' && this.productVariants.length > 0) {
        formData.append('make_parent', '1');
      } else { }

      formData.delete('variant_product_name');

      formData.append('status', status);
      /* Sometimes Some code not working as per logic , so appending status manually */
      // formData.append('status', this.productForm.value.status);
      this.productService
        .editProduct(formData, this.productId)

        .pipe(first())
        .subscribe(
          productData => {
            this.isSubmitted = false;
            this.alertService.success('Successfully updated');
            // this.productListedData();
            this.router.navigate(['product/details/', this.productId]);
          },
          error => {
            this.alertService.error(error.error);
          }
        );
    }
  }

  private getProduct() {
    this.loading = true;
    this.loadingService.setLoading(true);
    this.isEditMode = true;
    this.productService.getByid(this.productId).subscribe(data => {
      this.isEditMode = true;
      this.isRequested = data.is_requested;
      this.codeExists = Boolean(data.product_code);
      this.productForm.patchValue(data);
      this.isEditMode ? this.productForm.get('product_code').disable() : this.productForm.get('product_code').enable();
      this.changeSubCategory(data.sub_category)
      if (data.sub_category.category_ids.includes(61)) {
        this.productForm.patchValue({
          zoho_warehouses: data.zoho_properties.regional_availability,
          zoho_vendors: data.zoho_properties.vendors,
          zoho_brand: data.zoho_properties.brand,
          zoho_unit: data.zoho_properties.unit,
          zoho_pack_type: data.zoho_properties.pack_type,
          zoho_price: data.zoho_properties.price
        })
      }
      this.productForm.get('sub_category_id').setValue(data.sub_category.id);
      this.productForm.get('unit_id').setValue(data.unit.id);
      this.productVariants = data.variants;
      if (this.productVariants && this.productVariants.length > 1) {
        this.productVariants.map(x => {
          if (x.id === x.variant_product_id) {
            x.active = true;
            this.productForm.get('variant_product_name').setValue(x.name);
          }
        });
        // this.productForm.get('name').disable();
        this.productForm.get('variant_product_name').disable();
      }
      // this.getSubCategory(data.category);
      this.updateSubCategory(data);
      this.loading = false;
      this.loadingService.setLoading(false);
    });
  }

  private FormEssential() {
    this.loading = true;
    this.loadingService.setLoading(true);
    this.categoryService.getAllV2(this.page, 20).subscribe(data => {
      this.categoryList = data.data;
      this.loading = false;
      this.loadingService.setLoading(false);
    });
  }

  updateSubCategory(data) {
    if (data.sub_category) {
      this.selectedSubCategory = data.sub_category;
      this.productForm.get('sub_category_id').setValue(data.sub_category.id);
    }
    /* this.categoryService.getAllV2(this.page, this.limit)
      .subscribe(category => {
        // const CategoryList = category.find(x => x.id === data.category.id);
        //
        // this.subCategoryList = CategoryList.nested_categories;
        if (data.sub_category) {
          this.selectedSubCategory = data.sub_category;
          this.productForm.get('sub_category_id').setValue(data.sub_category.id);
          // if (data.sub_category.category_id !== 61 || data.sub_category.id === 49) {
          //   this.lastProductCode = data.sub_category.last_product_code;
          // }
        }
      }); */
  }

  formReset() {
    this.productForm.reset();
  }

  getSubCategory() {
    // this.selectedCategory = category;
    // this.lastProductCode = category.last_product_code;
    this.categoryService.getSubCategoryV2().subscribe(res => {
      this.subCategoryList = res;
    });
    this.productForm.get('sub_category_id').patchValue([]);
  }

  changeSubCategory(subCategory) {
    this.warehouses = [];
    this.brands = [];
    this.unit = [];
    this.vendors = [];
    this.taxes = [];
    if (subCategory.category_ids.includes(61)) {
      this.productService.getSyncWithzoho().subscribe(res => {
        this.warehouses = res.data.warehouses;
        this.brands = res.data.brands;
        this.unit = res.data.units;
        this.vendors = res.data.vendors;
        this.taxes = res.data.taxes;
        this.interTax = this.taxes.filter(x => x.tax_specification == 'inter');
        this.intraTax = this.taxes.filter(x => x.tax_specification == 'intra');
        this.validateKeys();
      })
    } else {
      this.productForm.controls['zoho_unit'].clearValidators();
      this.productForm.controls['zoho_price'].clearValidators();
      this.productForm.controls['zoho_unit'].updateValueAndValidity();
      this.productForm.controls['zoho_price'].updateValueAndValidity();
    }
    this.showVariants = true;
    this.enableParent = false;
    if (this.productVariants == null || this.isEditMode) {
      this.showVariants = false;
    }
    this.selectedSubCategory = subCategory;
    this.categoryService.getLatestProductCode(null, subCategory.id)
      .subscribe(data => {
        this.lastProductCode = data;
      });
  }

  private getUnit() {
    this.productService.getUnits(500).subscribe(data => {
      this.units = data;
    });
  }

  goPreviousUrl() {
    if (this.route.queryParams['_value']['from'] == 'request') {
      this.router.navigate(['/product/request'])
    } else {
      this.companyService.getUrl.pipe(takeUntil(this.unsubscribe$))
        .subscribe(url => {
          if (url && url.page && url.filter) {
            this.router.navigate(['/product'], { queryParams: { page: url.page, filter: url.filter } });
          } else {
            this.router.navigate(['/product']);
          }
        });
    }

  }

  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }
  validateKeys() {
    this.productForm.controls['zoho_unit'].setValidators([Validators.required]);
    this.productForm.controls['zoho_unit'].updateValueAndValidity();
    this.productForm.controls['zoho_price'].setValidators([Validators.required]);
    this.productForm.controls['zoho_price'].updateValueAndValidity();
  }
}
