import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '@app/authentication/services/authentication.service';
import { first } from 'rxjs/operators';
import { UserRoles } from '@core/enums/user-roles';
import { Ship } from '@app/modules/ship/model/ship.model';
import { ShipService } from '@app/modules/ship/services/ship.service';
import { CompanyService } from '@app/modules/company/services/company.service';
import { Company } from '@app/modules/company/models/company.modal';
import { CountryService } from '@app/modules/country/services/country.service';
import { SharedService } from '@shared/service/shared.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomValidators } from '@app/shared/validators/custom-validators';
import { MustMatch } from '@app/modules/user/components/user-list/user-list.component';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  shipId = null;
  companyId = null;
  currencyCode = null;
  source = 'WEB';
  captainRole = UserRoles.ROLE_CAPTAIN;
  returnUrl: string;

  //selectedAutocomplete = null;
  shipSuggestions: Ship[] = null;
  companySuggestions: Company[] = null;
  countries: any[] = [];
  currencies: any[] = [];

  modalRef: BsModalRef;
  @ViewChild('accountInProcess', { static: false })
  accountInProcess: any;
  emptyImo = false;
  emptyPrefix = false;
  emptyCallSign = false;
  password = '';
  isTextFieldType: boolean;
  isTextFieldConfirmType: boolean;
  currentYear: number;
  titles = ['Mr.', 'Mrs.', 'Ms.']
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertservice: ToastrService,
    private shipService: ShipService,
    private companyService: CompanyService,
    private countryService: CountryService,
    private sharedService: SharedService,
    private bsModalService: BsModalService
  ) {
    const currentUserData = this.authenticationService.currentUserValue;
    if (currentUserData && currentUserData.data.user_details.user_details.verified) {
      this.router.navigate(['/dashboard/updates']);
    }
    this.currentYear = new Date().getFullYear();
  }

  searchShip(event) {
    if (this.companyId != null) {
      this.shipService
        .getShipSuggestion(event.query, true, this.companyId)
        .subscribe(data => {
          this.shipSuggestions = data.data;
        }, error => {
          this.shipSuggestions = [];
        });
    } else {
      this.shipSuggestions = [];
    }
  }
  searchCompany(event) {
    this.companyService
      .getCompanySuggestion(event.query)
      .subscribe(data => {
        this.companySuggestions = data.data;
      }, error => {
        this.companySuggestions = [];
      });
  }
  selectCompany(event) {
    this.companyId = event.id;
    this.registerForm.get('company_name').setValue(event.name);
    this.registerForm.controls.country_id.updateValueAndValidity();
    this.registerForm.controls.currency_code.updateValueAndValidity();
  }

  selectShip(event) {
    this.shipId = event.id;
    this.registerForm.get('ship_name').setValue(event.name);
    this.registerForm.controls.imo_number.updateValueAndValidity();
  }

  selectCountry(country) {
    this.currencyCode = country.currency_code;
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      fname: ['', [Validators.required]],
      lname: [''],
      salutation: ['Mr.'],
      email: ['', [Validators.required, CustomValidators.emailValidator]],
      phone: ['', [Validators.required, Validators.pattern('^(0|91)?[6-9][0-9]{4,15}$')]],
      password: [null, [Validators.required, Validators.minLength(6)]],
      confirm_password: [null, { validator: this.checkPasswords }],
      country_id: [null, [this.checkCountry()]],
      currency_code: [null, [this.checkCurrency()]],
      company_id: [''],
      ship_id: [''],
      source: ['WEB'],
      role: [''],
      company_name: ['', [Validators.required]],
      ship_name: ['', [Validators.required]],
      imo_number: [null, [this.checkIMO()]],
      call_sign: [null],
      prefix: [null],
      username: ['']
    }, {
      validator: MustMatch('password', 'confirm_password')
    });

    this.countryService.getAll(1, 200).subscribe(data => {
      this.countries = data;
    }, error => {
      this.alertservice.error(error.error);
    });

    this.countryService.getCurrencies().subscribe(data => {
      this.currencies = data;
    }, error => {
      this.alertservice.error(error.error);
    });
  }

  checkPasswords(group: FormGroup) {
    const pass = group.get('password').value;
    const confirmPass = group.get('confirm_password').value;
    return pass === confirmPass ? null : { notSame: true };
  }

  checkCountry(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      return control.value || this.companyId ? null : { required: true };
    };
  }
  checkCurrency(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      return control.value || this.companyId ? null : { required: true };
    };
  }

  checkIMO(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      return control.value || this.shipId ? null : { required: true };
    };
  }

  // generateShipPrefix(value: any) {
  //   if (this.shipId == null)
  //     this.registerForm.get('prefix').setValue(value.query.replace('/[^A-Z0-9]+/g', '').substring(0, 50))
  // }

  get form() {
    return this.registerForm.controls;
  }
  register() {
    this.submitted = true;
    if (this.shipId == null && (this.registerForm.get('imo_number').value == '' || this.registerForm.get('imo_number').value == null)) {
      this.emptyImo = true;
      return;
    } else if (this.shipId == null && (this.registerForm.get('prefix').value == '' || this.registerForm.get('prefix').value == null)) {
      this.emptyPrefix = true;
      return;
    } else if (this.shipId == null && (this.registerForm.get('call_sign').value == '' || this.registerForm.get('call_sign').value == null)) {
      this.emptyCallSign = true;
      return;
    } else {
      if (this.shipId != null) {
        this.registerForm.patchValue({
          imo_number: '',
          prefix: '',
          call_sign: ''
        });
      }
    }

    if (this.registerForm.get('email').value != null || this.registerForm.get('email').value != '') {
      this.registerForm.get('username').setValue(this.registerForm.get('email').value);
    }
    if (!this.registerForm.valid) {
      return;
    }
    // this.loading = true;
    const data = this.sharedService.convertModelToFormData(this.registerForm.value);
    this.authenticationService
      .register(data)
      .pipe(first())
      .subscribe(resData => {
        this.loading = true;
        this.submitted = false;
        this.registerForm.reset();
        this.modalRef = this.bsModalService.show(this.accountInProcess, { class: 'verify-modal' });
        this.loading = false;
        /*this.route.queryParams.subscribe(params => {
          if (params.returnUrl) {
            this.router.navigate([params.returnUrl]);
            this.loading = false;
          } else {
            this.router.navigate(['/dashboard/updates']);
            this.loading = false;
          }
        });*/
      }, error => {
        this.alertservice.error(error.error);
        this.loading = false;
      }
      );
  }

  imoCheck(event) {
    if (event && event.target.value != null) {
      this.emptyImo = false;
    }
  }

  prefixCheck(event) {
    if (event && event.target.value != null) {
      this.emptyPrefix = false;
    }
  }

  callSignCheck(event) {
    if (event && event.target.value != null) {
      this.emptyCallSign = false;
    }
  }

  clearCompanySelection() {
    this.companyId = null;
    this.registerForm.controls.company_name.setValue('');
    this.clearShipSelection();
  }
  clearShipSelection() {
    this.shipId = null;
    this.registerForm.controls.ship_name.setValue('');
  }


  togglemyPasswordFieldType() {
    this.isTextFieldType = !this.isTextFieldType;
  }
  togglemyConfirmPasswordFieldType() {
    this.isTextFieldConfirmType = !this.isTextFieldConfirmType;
  }
}
