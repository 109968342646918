import { Component, OnInit } from '@angular/core';
import { CompanyType } from '@app/modules/company/models/company.enum';
import { appAnimations } from '../../shared/animations/animation';
import { SharedService } from '../../shared/service/shared.service';
import { UserRoles, Privilages } from './../../core/enums/user-roles';

interface Sidemenu {
  link: string;
  name: string;
}

interface NavItem {
  id: number;
  title: string;
  link: string;
  icon?: string;
  expand?: 'show' | 'hide';
  subItems?: SubNavItem[];
  className?: string;
}

interface SubNavItem {
  id?: number;
  title: string;
  link: string;
  params?: object;
  icon?: string;
}

enum MenuItemId {
  DASHBOARD = 0,
  REQUISITIONS,
  RFQ,
  ORDERS,
  CATALOGUE,
  INVOICE,
  PRODUCT,
  USER,
  COMPANY_DETAILS,
  SHIP,
  PORT,
  COMPANY,
  CATEGORY,
  COMPANY_INFO,
  ORDER_SUMMARY,
  VENDOR_DETAILS,
  INVENTORY,
  DESIGNATION,
  REQUESTS,
  SUBADMIN
}

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.css'],
  animations: appAnimations
})
export class LeftSidebarComponent implements OnInit {
  token: any;
  role;
  activeTab = false;
  //sidebar: Sidemenu[];
  //adminMenu: Sidemenu[];
  //ManagerMenu: Sidemenu[];
  //CaptainMenu: Sidemenu[];
  company: any;
  //CompanyBuyerMenu: Sidemenu[];
  //CompanySellerMenu: Sidemenu[];
  divCss: any = 'hidediv';
  vendorId: number;
  vendorEmail: number;

  // Menu Items
  navItems: NavItem[] = [];
  privilageIds: [number];
  subAdminAssinedPrivilages: boolean = false;
  privilages = Privilages
  //toggleMenu: boolean;
  excludeCompanyBuyerMenuId = [
    MenuItemId.RFQ,
    MenuItemId.COMPANY,
    MenuItemId.CATEGORY,
    MenuItemId.PORT,
    MenuItemId.INVOICE,
    MenuItemId.PRODUCT,
    MenuItemId.USER,
    MenuItemId.PORT,
    MenuItemId.SHIP,
    MenuItemId.ORDER_SUMMARY,
    MenuItemId.COMPANY_INFO,
    MenuItemId.VENDOR_DETAILS,
    MenuItemId.DESIGNATION,
    MenuItemId.REQUESTS,
    // MenuItemId.WAREHOUSE,
    MenuItemId.INVENTORY,
  ];
  excludeAdminMenuId = [
    MenuItemId.COMPANY_DETAILS,
    MenuItemId.ORDER_SUMMARY,
    MenuItemId.COMPANY_INFO,
    MenuItemId.VENDOR_DETAILS,
    MenuItemId.CATALOGUE,
    //MenuItemId.INVENTORY,
    // MenuItemId.WAREHOUSE,
    MenuItemId.INVOICE,
  ];
  excludeCompanySellerMenuId = [
    MenuItemId.REQUISITIONS,
    MenuItemId.CATALOGUE,
    MenuItemId.PRODUCT,
    MenuItemId.COMPANY,
    MenuItemId.SHIP,
    MenuItemId.PORT,
    MenuItemId.CATEGORY,
    MenuItemId.USER,
    MenuItemId.ORDER_SUMMARY,
    MenuItemId.COMPANY_INFO,
    MenuItemId.COMPANY_DETAILS,
    MenuItemId.DESIGNATION,
    MenuItemId.REQUESTS,
    MenuItemId.INVOICE,
    // MenuItemId.VENDOR_DETAILS
  ];
  excludeOthersMenuId = [
    MenuItemId.RFQ,
    MenuItemId.PRODUCT,
    MenuItemId.COMPANY,
    MenuItemId.COMPANY_DETAILS,
    MenuItemId.ORDERS,
    MenuItemId.SHIP,
    MenuItemId.PORT,
    MenuItemId.CATEGORY,
    MenuItemId.USER,
    MenuItemId.VENDOR_DETAILS,
    MenuItemId.DESIGNATION,
    MenuItemId.REQUESTS,
    // MenuItemId.WAREHOUSE,
    MenuItemId.INVENTORY,
    MenuItemId.INVOICE,
  ];
  userDetails: any;
  showInventory: boolean;
  // expandIcon = '/assets/images/sidebar-expand.svg';

  constructor(private sharedService: SharedService) {
    this.company = this.sharedService.getCompany();
    this.vendorId = this.company.id;
    this.vendorEmail = this.company.email;
    this.navItems = this.getMenu();
  }

  toggleSubMenu(item: NavItem) {
    if (item.expand) {
      item.expand = item.expand == 'hide' ? 'show' : 'hide';
    } else {
      item.expand = 'show';
    }
    this.activeTab = !this.activeTab;
    this.navItems.forEach(elem => {
      if (elem.subItems && elem.subItems.length > 0 && elem.id !== item.id) {
        elem.expand = 'hide';
      }
    });
  }

  ngOnInit() {
    this.role = this.sharedService.getUserRole();
    this.userDetails = this.sharedService.getUserDetails();
    this.showInventory = this.sharedService.checkInventoryRights();
    if (this.role == UserRoles.ROLE_SUBADMIN) {
      this.privilageIds = this.sharedService.getSubAdminsPrivileges();
    }
    switch (this.role) {
      case UserRoles.ROLE_ADMIN:
        this.navItems = this.navItems.filter(item => {
          return !this.excludeAdminMenuId.includes(item.id);
        });
        break;
      case UserRoles.ROLE_SUBADMIN:
        this.navItems = this.navItems.filter(item => {
          return !this.excludeAdminMenuId.includes(item.id);
        });
        if (this.privilageIds && this.privilageIds.length > 0) {
          if (this.privilageIds.includes(this.privilages.MANAGE_VENDOR_RFQS)) {
            this.navItems.splice(2, 0, {
              id: 2,
              title: 'RFQ',
              link: '/RFQs',
              icon: '/assets/images/sidebar-rfq.svg',
              className: ''
            })
          }
        }
        if (this.privilageIds && this.privilageIds.length > 0)
          if (this.privilageIds.includes(this.privilages.CREATE_REQUISITION)) {
            this.navItems.splice(2, 0, {
              id: 4,
              title: 'catalogue',
              link: '/dashboard/catalogue',
              icon: '/assets/images/sidebar-catalogue.svg',
              className: ''
            })
          }
        if (this.privilageIds && this.privilageIds.length > 0)
          if (this.privilageIds.includes(this.privilages.MANAGE_CATEGORIES_AND_SUB_CATAGORIES)) {
            if (this.navItems.length == 11) {
              this.navItems[10].subItems.splice(0, 0, {
                id: 1,
                title: 'Categories',
                link: '/category'
              },)
            }
            if (this.navItems.length == 10) {
              this.navItems[9].subItems.splice(0, 0, {
                id: 1,
                title: 'Categories',
                link: '/category'
              },)
            }
          }
        if (this.showInventory) {
          var subItems = [
            {
              id: 1,
              title: 'Dashboard',
              link: '/inventory/dashboard'
            },
            {
              id: 4,
              title: 'Inventory',
              link: '/inventory'
            },

            /* {
              id: 9,
              title: 'Inventory Adjustment',
              link: '/inventory/adjustment'
            } */
          ]
          if (this.privilageIds.includes(this.privilages.GENERATE_BILL) || this.privilageIds.includes(this.privilages.MANAGE_PURCHASE_RECEIVES)) {
            subItems.push({
              id: 8,
              title: 'Bills',
              link: '/bills'
            })
          }
          if (this.privilageIds.includes(this.privilages.GENERATE_PO) || this.privilageIds.includes(this.privilages.MANAGE_PURCHASE_RECEIVES)) {
            subItems.push({
              id: 7,
              title: 'Purchase Orders',
              link: '/po'
            })
          }
          if (this.privilageIds.includes(this.privilages.MANAGE_PURCHASE_RECEIVES)) {
            subItems.push({
              id: 7,
              title: 'Purchase Receives',
              link: '/purchase-receive'
            })
          }
          this.navItems.push({
            id: 13,
            title: 'Inventory',
            link: '#',
            icon: '/assets/images/sidebar-inventory.svg',
            className: '',
            subItems: subItems
          })
        }
        this.navItems.push({
          id: 12,
          title: 'More',
          link: '#',
          icon: '/assets/images/sidebar-categories.svg',
          className: '',
          subItems: [
            {
              id: 3,
              title: 'Website Leads',
              link: '/leads'
            }
          ]
        })

        break;
      case UserRoles.ROLE_COMPANY_ADMIN:
        this.company = this.sharedService.getCompany();
        if (this.company) {
          if (this.company.type == CompanyType.Buyer) {
            this.navItems = this.navItems.filter(item => {
              return !this.excludeCompanyBuyerMenuId.includes(item.id);
            });
          }
          if (this.company.type == CompanyType.Seller) {
            this.navItems = this.navItems.filter(item => {
              return !this.excludeCompanySellerMenuId.includes(item.id);
            });
          }
        }
        break;
      case UserRoles.ROLE_CAPTAIN:
        this.navItems = this.navItems.filter(item => {
          return !this.excludeOthersMenuId.includes(item.id);
        });
        break;
      case UserRoles.ROLE_USER:
        this.navItems.forEach(ele => {
          if (ele.id == MenuItemId.COMPANY_DETAILS) {
            // can add user & ship
            if (
              this.userDetails.can_add_ship &&
              this.userDetails.can_add_user
            ) {
              return;
            }
            // can not add user & ship
            // if (
            //   !this.userDetails.can_add_ship &&
            //   !this.userDetails.can_add_user
            // ) {
            //   let index = ele.subItems.findIndex(x => x.id == 3);
            //   ele.subItems.splice(index, 1);
            //   index = ele.subItems.findIndex(x => x.id == 2);
            //   ele.subItems.splice(index, 1);
            // }
            // can Add ship
            // if (this.userDetails.can_add_ship) {
            //   const index = ele.subItems.findIndex(x => x.id == 3);
            //   ele.subItems.splice(index, 1);
            // }
            // can Add User
            // if (this.userDetails.can_add_user) {
            //   const index = ele.subItems.findIndex(x => x.id == 2);
            //   ele.subItems.splice(index, 1);
            // }
          }
        });
        this.navItems = this.navItems.filter(item => {
          return !this.excludeCompanyBuyerMenuId.includes(item.id);
        });
        break;
    }
    // if (this.role === UserRoles.ROLE_CAPTAIN && this.showInventory) {
    //   this.navItems = this.navItems.filter(e => e.id != 16);
    // }
    if (this.role === UserRoles.ROLE_CAPTAIN) {
      const itemOrder = [0, 4, 1, 14, 13];
      this.navItems.sort((a, b) => itemOrder.indexOf(a.id) - itemOrder.indexOf(b.id));
    }
    if (this.role === UserRoles.ROLE_COMPANY_ADMIN && this.userDetails.company_details.type == 2) {
      const itemOrder = [0, 2, 3, 16, 15];
      if (this.showInventory) {
        this.navItems.sort((a, b) => itemOrder.indexOf(a.id) - itemOrder.indexOf(b.id));
      } else {
        this.navItems = this.navItems.filter(e => e.id != 16);
        // const index = this.navItems.findIndex(x => x.id == 17);
        // this.navItems.splice(index, 1);
      }
    }
  }

  getMenu(): NavItem[] {
    this.role = this.sharedService.getUserRole();
    if (this.role == UserRoles.ROLE_SUBADMIN) {
      return [
        {
          id: 0,
          title: 'Dashboard',
          link: '/dashboard/updates',
          icon: '/assets/images/sidebar-home.svg',
          className: ''
        },
        {
          id: 1,
          title: 'Requisitions',
          link: '/requisitions',
          icon: '/assets/images/sidebar-requisition.svg',
          className: '',
          subItems: [
            {
              title: 'All Requisitions',
              link: '/requisitions',
              params: { req_status: -1, page: 1 }
            }, {
              title: 'Waiting For Approval',
              link: '/requisitions',
              params: { req_status: 0, page: 1 }
            },
            {
              title: 'Urgent',
              link: '/requisitions',
              params: { priority_type: 'Urgent', page: 1 }
            },
            {
              title: 'Escalated',
              link: '/requisitions',
              params: { req_status: 1, page: 1 }
            },
            {
              title: 'Rejected By Manager',
              link: '/requisitions',
              params: { req_status: 2, page: 1 }
            },
            {
              title: 'Rejected By Purchase Team',
              link: '/requisitions',
              params: { req_status: 3, page: 1 }
            },
            {
              title: 'In Review',
              link: '/requisitions',
              params: { req_status: 7, page: 1 }
            },
          ]
        },
        /* {
          id: 2,
          title: 'RFQ',
          link: '/RFQs',
          icon: '/assets/images/sidebar-rfq.svg',
          className: ''
        }, */
        {
          id: 3,
          title: 'Orders',
          link: '/orders',
          icon: '/assets/images/sidebar-orders.svg',
          className: ''
        },
        {
          id: 4,
          title: 'catalogue',
          link: '/dashboard/catalogue',
          icon: '/assets/images/sidebar-catalogue.svg',
          className: ''
        },
        {
          id: 6,
          title: 'Products',
          link: '#',
          icon: '/assets/images/sidebar-products.svg',
          className: '',
          subItems: [
            {
              id: 1,
              title: 'Catalogue',
              link: '/product'
            },
            {
              id: 3,
              title: 'Product Price',
              link: '/product/prices'
            }
          ]
        },
        {
          id: 7,
          title: 'Users',
          link: '/user',
          icon: '/assets/images/sidebar-users.svg',
          className: ''
        },
        {
          id: 9,
          title: 'Ships',
          link: '/ship',
          icon: '/assets/images/sidebar-ships.svg',
          className: ''
        },
        {
          id: 10,
          title: 'Ports',
          link: '/port',
          icon: '/assets/images/sidebar-ports.svg',
          className: ''
        },
        {
          id: 11,
          title: 'Companies',
          link: '#',
          icon: '/assets/images/sidebar-companies.svg',
          className: '',
          subItems: [
            {
              title: 'Shipping Company',
              link: '/company',
              params: { type: 1, page: 1, filter: JSON.stringify({ name: '' }) }
            },
            {
              title: 'Vendor Company',
              link: '/company',
              params: { type: 2, page: 1, filter: JSON.stringify({ name: '' }) }
            },
            {
              title: 'Vendor KYC',
              link: '/kyc/list',
            }
          ]
        },
      ];
    }
    return [
      {
        id: 0,
        title: 'Dashboard',
        link: '/dashboard/updates',
        icon: '/assets/images/sidebar-home.svg',
        className: ''
      },
      {
        id: 1,
        title: 'Requisitions',
        link: '/requisitions',
        icon: '/assets/images/sidebar-requisition.svg',
        className: '',
        subItems: [
          {
            title: 'All Requisitions',
            link: '/requisitions',
            params: { req_status: -1, page: 1 }
          }, {
            title: 'Waiting For Approval',
            link: '/requisitions',
            params: { req_status: 0, page: 1 }
          },
          {
            title: 'Urgent',
            link: '/requisitions',
            params: { priority_type: 'Urgent', page: 1 }
          },
          {
            title: 'Escalated',
            link: '/requisitions',
            params: { req_status: 1, page: 1 }
          },
          {
            title: 'Rejected By Manager',
            link: '/requisitions',
            params: { req_status: 2, page: 1 }
          },
          {
            title: 'Rejected By Purchase Team',
            link: '/requisitions',
            params: { req_status: 3, page: 1 }
          },
          {
            title: 'In Review',
            link: '/requisitions',
            params: { req_status: 7, page: 1 }
          },
        ]
      },
      {
        id: 2,
        title: 'RFQ',
        link: '/RFQs',
        icon: '/assets/images/sidebar-rfq.svg',
        className: ''
      },
      {
        id: 3,
        title: 'Orders',
        link: '/orders',
        icon: '/assets/images/sidebar-orders.svg',
        className: ''
      },
      {
        id: 4,
        title: 'catalogue',
        link: '/dashboard/catalogue',
        icon: '/assets/images/sidebar-catalogue.svg',
        className: ''
      },
      {
        id: 5,
        title: 'Invoice',
        link: '#',
        icon: '/assets/images/invoice.svg',
        className: '',
        subItems: [
          {
            id: 1,
            title: 'Invoice List',
            link: '/invoice'
          },
          {
            id: 2,
            title: 'Companies',
            link: '/'
          },
          {
            id: 3,
            title: 'Transactions',
            link: '/'
          }
        ]
      },
      {
        id: 6,
        title: 'Products',
        link: '#',
        icon: '/assets/images/sidebar-products.svg',
        className: '',
        subItems: [
          {
            id: 1,
            title: 'Catalogue',
            link: '/product'
          },
          {
            id: 2,
            title: 'New Requests',
            link: '/product/request'
          },
          {
            id: 3,
            title: 'Product Price',
            link: '/product/prices'
          }
        ]
      },
      {
        id: 7,
        title: 'Users',
        link: '/user',
        icon: '/assets/images/sidebar-users.svg',
        className: ''
      },
      {
        id: 8,
        title: 'Company Details',
        link: '#',
        icon: '/assets/images/sidebar-company-info.svg',
        className: '',
        subItems: [
          {
            id: 1,
            title: 'info',
            link: '/company/info'
          },
          {
            id: 2,
            title: 'Ships',
            link: '/ship'
          },
          {
            id: 3,
            title: 'Users',
            link: '/user'
          }
        ]
      },
      {
        id: 9,
        title: 'Ships',
        link: '/ship',
        icon: '/assets/images/sidebar-ships.svg',
        className: ''
      },
      {
        id: 10,
        title: 'Ports',
        link: '/port',
        icon: '/assets/images/sidebar-ports.svg',
        className: ''
      },
      {
        id: 11,
        title: 'Companies',
        link: '#',
        icon: '/assets/images/sidebar-companies.svg',
        className: '',
        subItems: [
          {
            title: 'Shipping Company',
            link: '/company',
            params: { type: 1, page: 1, filter: JSON.stringify({ name: '' }) }
          },
          {
            title: 'Vendor Company',
            link: '/company',
            params: { type: 2, page: 1, filter: JSON.stringify({ name: '' }) }
          },
          {
            title: 'Vendor KYC',
            link: '/kyc/list',
          }
        ]
      },
      {
        id: 16,
        title: 'Inventory',
        link: '#',
        icon: '/assets/images/sidebar-inventory.svg',
        className: '',
        subItems: [
          {
            id: 1,
            title: 'Dashboard',
            link: '/inventory/dashboard'
          },
          /*{
            id: 2,
            title: 'Supplier',
            link: '/supplier'
          }, */
          // {
          //   id: 3,
          //   title: 'Warehouse',
          //   link: '/warehouse'
          // },
          {
            id: 4,
            title: 'Inventory',
            link: '/inventory'
          },
          // {
          //   id: 5,
          //   title: 'Invoice List',
          //   link: '/invoice'
          // },
          {
            id: 6,
            title: 'Contacts',
            link: '/contacts'
          },
          {
            id: 7,
            title: 'Purchase Orders',
            link: '/po'
          },
          {
            id: 8,
            title: 'Bills',
            link: '/bills'
          },
          {
            id: 10,
            title: 'Purchase Receives',
            link: '/purchase-receive'
          },
          /* {
            id: 9,
            title: 'Inventory Adjustment',
            link: '/inventory/adjustment'
          } */
        ]
      },
      {
        id: 17,
        title: 'Sub-Admin',
        link: '#',
        icon: '/assets/images/sub-admin.svg',
        className: '',
        subItems: [
          {
            id: 1,
            title: 'Sub-Admin User',
            link: '/sub-admin'
          },
          {
            id: 2,
            title: 'Sub-Admin Privileges Listing',
            link: '/sub-admin/privilages'
          },
        ]
      },
      {
        id: 12,
        title: 'More',
        link: '#',
        icon: '/assets/images/sidebar-categories.svg',
        className: '',
        subItems: [
          {
            id: 1,
            title: 'Categories',
            link: '/category'
          },
          {
            id: 2,
            title: 'Designation',
            link: '/designation'
          },
          {
            id: 3,
            title: 'Website Leads',
            link: '/leads'
          },
          {
            id: 4,
            title: 'Registration User',
            link: '/registration-request'
          },
          {
            id: 5,
            title: 'OEW Settings',
            link: '/oew'
          }
        ]
      },
      {
        id: 13,
        title: 'Company Info',
        link: '/company/info',
        icon: '/assets/images/sidebar-company-info.svg',
        className: ''
      },
      {
        id: 14,
        title: 'Orders',
        link: '/orders',
        icon: '/assets/images/sidebar-orders.svg',
        className: ''
      },
      {
        id: 15,
        title: 'Company Details',
        link: '#',
        icon: '/assets/images/sidebar-company-info.svg',
        className: '',
        subItems: [
          {
            title: 'info',
            link: '/company/info'
          },
          {
            title: 'KYC Details',
            link: '/kyc/details',
            params: { vendor_id: this.vendorId ? this.vendorId : '', email: this.vendorEmail ? this.vendorEmail : '' }
          },
          {
            title: 'Products',
            link: '/product/prices'
          },
          {
            title: 'Transportation Costs',
            link: '/company/transportation-costs'
          },
          /* {
            title: 'Drivers',
            link: '/user',

          } */
        ]
      },

      // {
      //   id: 16,
      //   title: 'Warehouse',
      //   link: '/warehouse',
      //   icon: '/assets/images/sidebar-warehouse.svg',
      //   className: ''
      // },
      // {
      //   id: 17,
      //   title: 'Inventory',
      //   link: '/inventory',
      //   icon: '/assets/images/sidebar-inventory.svg',
      //   className: ''
      // },

      // {
      //   id: 17,
      //   title: 'Designation',
      //   link: '/designation',
      //   icon: '/assets/images/sidebar-designation.svg',
      //   className: ''
      // },
      // {
      //   id: 18,
      //   title: 'Website Leads',
      //   link: '/leads',
      //   icon: '/assets/images/sidebar-website.svg',
      //   className: ''
      // },
    ];
  }
  openNav() {
    this.divCss = 'showDiv';
  }
  closeNav() {
    this.divCss = 'hidediv';
  }
}
