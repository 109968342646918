import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Port } from '../models/port.model';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { CollapseModule } from 'ngx-bootstrap/collapse';

@Injectable({ providedIn: 'root' })
export class PortService {
  private portList$ = new BehaviorSubject<any>([]);
  constructor(private http: HttpClient) { }

  getAll(page: number, limit: number) {
    const portList = JSON.parse(sessionStorage.getItem('PortList'));
    if (false && portList) {
      this.portList$.next(portList);
      return this.portList$.asObservable();
    } else {
      return this.http
        .get<{ data }>(
          `${environment.apiUrl}/v1/port/?page=${page}&limit=${limit}&orderby=asc&column=id`
        )
        .pipe(
          map(dataVal => {
            // console.log(dataVal.data.results);
            // resArray.push(dataVal.data.results);
            // return resArray;
            sessionStorage.setItem('PortList', JSON.stringify(dataVal.data));
            return dataVal.data;
          })
        );
    }
  }

  addPort(port) {
    return this.http.post<any>(`${environment.apiUrl}/v1/port/`, port);
  }

  editPort(port: Port) {
    return this.http.put(`${environment.apiUrl}/v1/port/`, port);
  }

  getByid(id: number) {
    return this.http.get<{ data }>(`${environment.apiUrl}/v1/port/${id}`).pipe(
      map(dataVal => {
        // console.log(dataVal.data.results);
        // resArray.push(dataVal.data.results);
        // return resArray;
        return dataVal.data;
      })
    );
  }

  getByShipId(shipId: number): Observable<any> {
    return this.http.get<{ data }>(`${environment.apiUrl}/v1/port/ship/${shipId}`).pipe(
      map(dataVal => {
        return dataVal.data;
      })
    );
  }
}
