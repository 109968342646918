import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CustomValidators } from '@app/shared/validators/custom-validators';
import { UserService } from '@app/modules/user/services/user.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {
  currentYear: number;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertservice: ToastrService,
    private userService: UserService,
    private modalService: BsModalService,
  ) {
    const currentUserData = this.authenticationService.currentUserValue;
    if (currentUserData && currentUserData?.data?.user_details?.user_details.verified) {
      this.router.navigate(['/dashboard/updates']);
    }
    this.currentYear = new Date().getFullYear();
  }
  get form() {
    return this.loginForm.controls;
  }
  loginForm: FormGroup;
  isLoading: boolean;
  loading = false;
  submitted = false;
  returnUrl: string;
  modalRef: BsModalRef;
  // tslint:disable-next-line:member-ordering
  @ViewChild('accountInProcess', { static: false })
  accountInProcess: any;
  isTextFieldType: boolean;
  toggleVerification: boolean;
  verified: boolean;
  notVerified: boolean;
  getOTP: boolean;
  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, CustomValidators.emailValidator]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });

    // reset login status
    // this.authenticationService.logout();
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }
  getOTPForVerification() {
    this.getOTP = true;
  }
  verifyOTP() {
    this.notVerified = false;
    this.toggleVerification = true;
    setTimeout(() => {
      this.toggleVerification = false;
      let data = Math.random();
      if (data < 0.5) {
        this.verified = true;
        this.notVerified = false;
        this.getOTP = false;
      }
      else {
        this.verified = false;
        this.notVerified = true;
      }
    }, 2000);
  }
  getLogin() {
    this.isLoading = true;
    this.submitted = true;
    if (!this.loginForm.valid) {
      this.isLoading = false;
      return;
    } else {
      //this.loading = true;
      const usernameData = this.form.username.value;
      const passData = this.form.password.value;
      this.authenticationService
        .login(usernameData, passData)
        .pipe(first())
        .subscribe(
          data => {
            if (data.data.message == "Verification pending") {
              this.loading = false;
              this.isLoading = false;
              this.modalRef = this.modalService.show(this.accountInProcess, { class: 'verify-modal' });
            }
            else if (!data.data.user_details.user_details.verified) {
              this.loading = false;
              this.isLoading = false;
              this.modalRef = this.modalService.show(this.accountInProcess, { class: 'verify-modal' });
            } else {
              this.userService.getProfilePic(this.authenticationService.currentUserValue.data.user_details.user_id).subscribe(res => {
                if (res && res.data) {
                  this.userService.userProfileImage = res.data;
                }
              });
              this.route.queryParams.subscribe(params => {
                if (params.returnUrl) {
                  this.router.navigateByUrl(this.returnUrl);
                  this.loading = false;
                } else {
                  // if (data.data.user_details.role_id === 4) {
                  //   this.router.navigate(['/dashboard/updates'], { queryParams: { user_status: true } });
                  //   this.authenticationService.getUserStatusValue(true);
                  // } else {
                  this.router.navigate(['/dashboard/updates']);
                  // }
                  this.loading = false;
                }
                this.isLoading = false;
              });
            }
          }, error => {
            this.alertservice.clear()
            const err = error?.message?.error || error?.error?.message || error?.error || error?.data?.message;
            this.alertservice.error(err);
            this.loading = false;
            // setTimeout(() => {
            //   this.alertservice.clear();
            // }, 2000)
            this.isLoading = false;
          }
        );
    }
  }
  togglemyPasswordFieldType() {
    this.isTextFieldType = !this.isTextFieldType;
  }
}
