<div class="loading"
    *ngIf="loading">
    <img src="/assets/images/loader.gif"
        alt="Loader" />
</div>
<div class="have-middlebox bg-white">
    <div class="login-bg"></div>
    <div class="login-credentials">
        <div style="width: max-content;">
            <div class="company-logo">
                <a href="https://shipskart.com/"><img src="/assets/images/white_black.svg" /></a>
            </div>
            <div class="wellcome-msg">
                Login
                <small class="sub-heading">Please sign in to your account below.</small>
            </div>
            <form [formGroup]="loginForm"
                (ngSubmit)="getLogin()">
                <div class="form-group"
                    [ngClass]="{error : submitted && form.username.errors}">
                    <span class="p-float-label">
                        <input autocomplete="off"
                            formControlName="username"
                            class="form-control"
                            type="text"
                            id="username"
                            pInputText />
                        <label for="username">Email ID or Phone No.</label>
                        <!-- <button class="eye-icon-btn hover-effects" (click)="getOTPForVerification()"
                            type="button">
                            Get OTP
                        </button> -->
                    <!-- (input)="username.value=$event.target.value.toLowerCase()" -->
                </span>
                    <input-validation [control]="form.username"
                        [submit]="submitted"></input-validation>
                    <!-- <div class="errorbox" *ngIf="submitted && form.username.errors">
                        <span *ngIf="!form.username.valid">Enter valid Email ID</span>
                    </div> -->
                </div>
                <div class="form-group"
                    [ngClass]="{error : submitted && form.password.errors}">
                    <span
                        class="p-float-label">
                        <input autocomplete="off"
                            formControlName="password"
                            class="form-control password-field"
                            [type]="isTextFieldType ? 'text' : 'password'"
                            id="pass"
                            pInputText />
                        <label for="pass">Password</label>
                        <button class="eye-icon-btn"
                            (click)="togglemyPasswordFieldType()"
                            type="button">
                            <i [class]="isTextFieldType ? 'fa fa-eye' : 'fa fa-eye-slash'"
                                aria-hidden="true"></i>
                        </button>
                    </span>

                    <div class="password-validation-div">
                        <div id="password-err-div">
                            <input-validation [control]="form.password"
                                [submit]="submitted"></input-validation>
                        </div>
                        <a class="forgot-link"
                            routerLink='/forget'>Forgot Password ?</a>
                    </div>
                </div>
                <!-- <div class="form-group" *ngIf="getOTP">
                    <span class="p-float-label">
                        <input autocomplete="off"
                            class="form-control"
                            type="number"
                            id="otp"
                            minlength="6"
                            maxlength="6"
                            pInputText />
                        <label for="otp">Enter OTP</label>
                        <button class="eye-icon-btn hover-effects" [style.right]="notVerified?'20px':'0px'" *ngIf="!toggleVerification && !verified" type="button" (click)="verifyOTP()">Verify
                        </button>
                        <button class="eye-icon-btn hover-effects"
                            type="button" *ngIf="toggleVerification && !verified">
                            <ng-container>
                                <span class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"></span>
                                <span class="sr-only">Loading...</span>
                            </ng-container>
                        </button>
                        <button class="eye-icon-btn hover-effects" *ngIf="verified"
                            type="button">✅
                        </button>
                        <button class="eye-icon-btn hover-effects" *ngIf="notVerified"
                        type="button">❌
                    </button>
                    </span>
                </div> -->
                <div class="form-group">
                    <button class="btn btn-primary"
                        type="submit"><ng-container *ngIf="isLoading">
                            <span class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"></span>
                            <span class="sr-only">Loading...</span>
                          </ng-container>&nbsp;Login</button>
                </div>
                <hr>
                <div class="form-group"
                    style="margin-top: 25px;">
                    <a class="no-hover-effect new-cursor">New to Shipskart</a>
                </div>
                <a routerLink="/register" class="btn btn-primary"> Register as Captain</a>
                <a routerLink='/kyc-form' class="btn btn-primary"> Register as Vendor </a>
            </form>
            <div class="copy-rights">
                © {{ currentYear }} Shipskart Marine Private Limited. All rights reserved
            </div>
        </div>
    </div>
</div>


<ng-template #accountInProcess
    class="modal-sm">
    <div class="modal-body text-center padding20">
        <img class="mt-4"
            width="100"
            src="/assets/images/account-verify.png"
            alt="image">
        <!-- <h4 class="sure-font mt-4">Thank you registering with ShipsKart!</h4> -->
        <h6 class="mt-4">
            Your account verification is under process. You will receive an email confirmation once your account has
            been approved.
        </h6>
        <hr />
        <h5 class="small mt-4">
            In case of an urgent requirement, feel free to reach us at <b
                class="text-nowrap">contact@shipskart.com</b>
        </h5>
        <button class="btn btn-block btn-primary mt-4"
            (click)="modalRef.hide()"
            type="button">Okay</button>
    </div>
</ng-template>
