import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { NewDashboard } from "@app/dashboard/models/newdashboard.model";
import { FilterComponent } from "../filter/filter.component";
import { PanelComponent } from "../panel/panel.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { SharedService } from "@app/shared/service/shared.service";
import { ToastrService } from "ngx-toastr";
import { AlertService } from "@app/shared/service/alert.service";
import { DashboardService } from "@app/dashboard/dashboard.service";
import { Router } from "@angular/router";
import { UserRoles } from "@app/core/enums/user-roles";
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})

export class TableComponent implements OnInit {
  @Input() title: string;
  @Input() data: NewDashboard;
  @ViewChild('panelData', { static: false }) panelData: PanelComponent;
  @ViewChild('filterData', { static: false }) filterData: FilterComponent;
  @ViewChild('tableData', { static: false }) tableDataModal: TemplateRef<any>;
  @Output() callParentMethodEvent = new EventEmitter<string>();
  modal: any;
  editPanel: boolean = false;
  sharedData: NewDashboard;
  // modalRef: BsModalRef;
  filterLength: boolean;
  role: any;
  userRoles = UserRoles;
  otherAxis;
  tempTableData;
  isDownLoading: boolean;
  disablePagination: boolean = true;
  deleteModal: BsModalRef;
  loadingDeletePanel: boolean;
  constructor(private bsModalService: BsModalService,
    private router: Router,
    private sharedService: SharedService, private toastr: ToastrService,
    private altService: AlertService, private dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.role = this.sharedService.getUserRole();
    this.checkFilter();
    this.otherAxis = this.data;
    this.tempTableData = JSON.parse(JSON.stringify(this.data));
  }
  openModel() {
    this.onPageChange('other')
    this.modal = this.bsModalService.show(
      this.tableDataModal,
      Object.assign(
        { backdrop: 'static', class: 'gray modal-xl ' }
      ))
  }
  checkFilter() {
    this.filterLength = this.data.filters.some(response => {
      return response.filter_value ? true : false;
    })
  }
  fetchData(value) {
    if (this.data.navigation_type[value]) {
      if (this.data.navigation_type[value].split("_").includes('order')) {
        switch (this.data.navigation_type[value]) {
          case "order_list":
            this.router.navigateByUrl('/orders')
            break;
          case "order_placed":
            this.router.navigateByUrl('/orders?page=1&filter=%257B%2522order_status%2522%253A%255B1%255D%257D')
            break;
          case "order_out_for_delivery":
            this.router.navigateByUrl('/orders?page=1&filter=%257B%2522order_status%2522%253A%255B3%255D%257D')
            break;
          case "order_delivered":
            this.router.navigateByUrl('/orders?page=1&filter=%257B%2522order_status%2522%253A%255B4%255D%257D')
            break;
          case "order_cancelled":
            this.router.navigateByUrl('/orders?page=1&filter=%257B%2522order_status%2522%253A%255B6%255D%257D')
            break;
          case "order_partially_delivered":
            this.router.navigateByUrl('/orders?page=1&filter=%257B%2522order_status%2522%253A%255B5%255D%257D')
            break;
        }
      }
      else if (this.data.navigation_type[value].split("_").includes('requisition')) {
        switch (this.data.navigation_type[value]) {
          case "requisition_list":
            this.router.navigateByUrl('/requisitions')
            break;
          case "requisition_list_waiting_for_approval":
            this.router.navigateByUrl('/requisitions?req_status=0&page=1')
            break;
          case "requisition_list_sent_for_approval":
            this.router.navigateByUrl('/requisitions?req_status=1&page=1')
            break;
          case "requisition_list_rejected_by_manager":
            this.router.navigateByUrl('/requisitions?req_status=2&page=1')
            break;
          case "requisition_list_rejected_by_po":
            this.router.navigateByUrl('/requisitions?req_status=3&page=1')
            break;
          case "requisition_list_urgent":
            this.router.navigateByUrl('/requisitions?priority_type=Urgent&page=1')
            break;
        }
      }
    }
  }

  onPageChange(value) {
    let page;
    this.disablePagination = false;
    if (value === 'next') {
      page = this.tempTableData.page + 1;
    }
    else if (value === 'prev') {
      page = this.tempTableData.page - 1;
    }
    else {
      page = 1;
    }
    this.dashboardService.paginateTable(this.tempTableData.report_id, page).subscribe((response: NewDashboard) => {
      this.tempTableData = response?.data[0];
      this.disablePagination = true;
    }, error => {
      this.disablePagination = true;
    })
  }

  openFilterModal() {
    this.filterData.openModel();
  }

  // Adjusts alignment of each td element
  getAlignment(i) {
    if (typeof i == 'number') {
      return 'center';
    }
  }

  openPanelModal(isEdit = false) {
    this.editPanel = isEdit ? true : false;
    this.sharedData = this.data;
    this.panelData.openModel();
  }

  panelDeleteModal(template: TemplateRef<any>) {
    this.deleteModal = this.bsModalService.show(
      template,
      Object.assign({}, { class: "catalogue gray modal-sm" })
    );
    this.altService.backModalClose(this.deleteModal);
  }

  deletePanel() {
    this.loadingDeletePanel = true;
    this.sharedService.deletePanel(this.data.report_id, 1).subscribe(res => {
      if (res) {
        this.toastr.success('Report Deleted Successfully!');
        this.dashboardService.deleteReport(true);
        this.callParentMethodEvent.emit(this.data.report_id.toString());
        this.loadingDeletePanel = false;
        this.deleteModal.hide();
      }
    }, error => {
      this.toastr.error('Something went wrong!');
      this.loadingDeletePanel = false;
      this.deleteModal.hide();
    })
  }

  reloadPage(event) {
    this.callParentMethodEvent.emit(event);
  }

  async downloadReport() {
    this.isDownLoading = true;
    try {
      const res = await this.dashboardService.getReportData(this.data.report_id, null, 1);
      this.sharedService.saveAsBlob(
        res,
        'application/vnd.ms-excel',
        `PO_${this.data.report_title}.xlsx`
      );
      this.isDownLoading = false;
      this.toastr.success('Downloaded Successfully.');
    } catch (error) {
      this.isDownLoading = false;
      this.toastr.error('Failed to download report.');
    }
  }
  filterAppied(event) {
    if (this.data.report_id) {
      this.data = event[0]
      this.dashboardService.deleteReport(false);
      this.callParentMethodEvent.emit(this.data.report_id.toString());
      this.ngOnInit();
    }
  }

}