import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../authentication/helpers/auth.guard';

import { DashboardUpdatesComponent } from './dashboard-updates/dashboard-updates.component';
import { DashboardComponent } from './dashboard.component';
import { CatalogueComponent } from '@app/modules/cetalogue/catalogue.component';
import { CartComponent } from '@app/modules/cart/components/cart.component';
import { UserRoles } from '@app/core/enums/user-roles';
import { PageNotFoundComponent } from '@app/page-not-found/page-not-found.component';
import { NewRequisitionQuotationComponent } from '@app/modules/new-requisition-quotation/new-requisition-quotation.component';
import { CreateFormComponent } from '@app/shared/new-dashboard-config/create-form/create-form.component';
import { FeedbackFormListingComponent } from '@app/shared/new-dashboard-config/feedback-form-listing/feedback-form-listing.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      roles: [
        UserRoles.ROLE_USER,
        UserRoles.ROLE_CAPTAIN,
        UserRoles.ROLE_COMPANY_ADMIN,
        UserRoles.ROLE_ADMIN,
        UserRoles.ROLE_SUBADMIN
      ]
    },
    children: [
      { path: '', canActivate: [AuthGuard], component: DashboardUpdatesComponent },
      { path: 'updates', canActivate: [AuthGuard], component: DashboardUpdatesComponent },
      {
        path: 'catalogue',
        canActivate: [AuthGuard],
        data: {
          roles: [
            UserRoles.ROLE_USER,
            UserRoles.ROLE_CAPTAIN,
            UserRoles.ROLE_COMPANY_ADMIN,
            UserRoles.ROLE_ADMIN,
            UserRoles.ROLE_SUBADMIN
          ]
        },
        component: CatalogueComponent
      },
      {
        path: 'create-form',
        component: CreateFormComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'delivery/feedback-form-listing',
        component: FeedbackFormListingComponent,
        canActivate: [AuthGuard]
      }, {
        path: 'feedback-form-listing',
        component: FeedbackFormListingComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'cart',
        canActivate: [AuthGuard],
        data: {
          roles: [
            UserRoles.ROLE_USER,
            UserRoles.ROLE_CAPTAIN,
            UserRoles.ROLE_COMPANY_ADMIN,
            UserRoles.ROLE_SUBADMIN
          ]
        },
        component: CartComponent
      },
      {
        path: 'new-requisition-quotation',
        canActivate: [AuthGuard],
        data: {
          roles: [
            UserRoles.ROLE_ADMIN,
            UserRoles.ROLE_SUBADMIN
          ]
        },
        component: NewRequisitionQuotationComponent
      },
      { path: '**', canActivate: [AuthGuard], component: PageNotFoundComponent }
    ]
  },
  { path: '**', canActivate: [AuthGuard], component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
