import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SubCategory } from '@app/modules/category/models/category.model';
import { CategoryService } from '@app/modules/category/services/category.service';
import { Company } from '@app/modules/company/models/company.modal';
import { CompanyService } from '@app/modules/company/services/company.service';
import { Port } from '@app/modules/port/models/port.model';
import { PortService } from '@app/modules/port/port.service';
import { InrPrices, ProductPriceHistory } from '@app/modules/requisition/models/requisition';
import { SharedService } from '@app/shared/service/shared.service';
import { ToastrService } from 'ngx-toastr';
import { Product } from '../../model/product.model';
import { ProductService } from '../../services/product.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserRoles } from '@app/core/enums/user-roles';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AlertService } from '@app/shared/service/alert.service';
@Component({
  selector: 'app-vendor-price-comparison',
  templateUrl: './vendor-price-comparison.component.html',
  styleUrls: ['./vendor-price-comparison.component.css']
})
export class VendorPriceComparisonComponent implements OnInit {
  modelRef: BsModalRef;
  shipForms: {
    form: FormGroup,
    loading: boolean,
    loading1: boolean,
    submitted: boolean,
    currency: string,
    priceHistoryData: ProductPriceHistory,
    productPricesInr: InrPrices[];
    portList: Port[],
    arr: [],
    price: []
  }[] = [];
  arr: any = [];
  price: any = [];
  etd = new Date();
  defaultListLoader = [{ id: -1, name: 'Loading...' }];
  // categoryList = this.defaultListLoader;
  subCategoryList: SubCategory[] = [];
  companys: Company[] = [];
  loaderView = false;
  searchForm: FormGroup;
  submitted: boolean;
  suggestion: any;
  query = null;
  searchQuery: any;
  selectedSubCategory = 0;
  selectedAutocomplete: string = null;
  selectedProductDetails = [];
  page = 1;
  limit = 10;
  enableSearch = false;
  product: Product;
  currencyDropdown = [];
  role: number;
  userRoles = UserRoles;
  lineChart: any;
  company: Company;
  dataChart: number;
  allPort: Port[] = [];
  // monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
  private unsubscribe$ = new Subject<any>();
  myForm
  constructor(
    private categoryService: CategoryService,
    private portService: PortService,
    private companyService: CompanyService,
    private productService: ProductService,
    private sharedService: SharedService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private modalService: BsModalService,
    private alertService: AlertService,
    private toastr: ToastrService,
    private router: Router,
  ) {
    this.role = this.sharedService.getUserRole();
    this.company = this.sharedService.getCompany();
    this.addShipToCompare();
    this.addShipToCompare();
  }

  ngOnInit() {
    this.searchForm = this.formBuilder.group({
      search: ['', [Validators.required]],
      subcategory_id: [null],
      portId: [null, [Validators.required]]
    });
    this.getSubCategoryList();

    this.portService.getAll(1, 1000).subscribe(data => {
      this.allPort = data.results;
    })

    this.currencyDropdown = this.sharedService.currencyDropdown;
    if (this.role == this.userRoles.ROLE_COMPANY_ADMIN && this.company.type === 2) {
      this.myForm = this.getShipForm()
      this.myForm.get('currency').setValue(this.company.currency_code);
      this.shipForms.forEach(form => {
        this.getPorts(form, this.company.id);
      });
    } else {
    }
    this.companyList();
  }
  private getSubCategoryList() {
    this.categoryService.getSubCategoryV2().subscribe(res => {
      this.subCategoryList = res;
    });
  }

  getPorts(form, vendorId) {
    form.portList = [];
    this.portService.getByVendor(vendorId, 1, 1000).subscribe(res => {
      form.portList = res;
    });
  }
  defaultCurrency
  id
  vendorSelected(shipForm, event) {
    if (event) {
      if (this.companys.find(x => x.id == event.id)) {
        shipForm.form.get('currency').setValue(event.currency_code)
      }
      shipForm.form.get('port_id').setValue(null);
      this.getPorts(shipForm, event.id);
    }
  }

  companyList() {
    this.companyService.getVendors(1, 1000, 0).subscribe(data => {
      this.companys = data.results;
    });
  }

  get form() {
    return this.searchForm.controls;
  }

  addShipToCompare() {
    if (this.shipForms.length < 6) {
      this.dataChart = this.shipForms.length;
      this.shipForms.push({
        form: this.getShipForm(),
        portList: [],
        loading: false,
        loading1: false,
        submitted: false,
        currency: 'INR',
        priceHistoryData: null,
        productPricesInr: [],
        arr: [],
        price: []
      });
    } else {
      if (this.role == this.userRoles.ROLE_ADMIN || this.role == this.userRoles.ROLE_SUBADMIN) {
        this.toastr.error('Maximum 6 vendors are allowed', 'Cannot add Vendor');
      } else {
        this.toastr.error('Maximum 6 ports are allowed', 'Cannot add port');
      }
    }
    if (this.role == this.userRoles.ROLE_COMPANY_ADMIN && this.company.type === 2) {
      this.companys = [];
      if (this.companys && this.companys.length == 0) {
        this.companys.push(this.company);
      }
      this.shipForms.forEach(form => {
        this.getPorts(form, this.company.id);
      });
    }
  }

  getShipForm(): FormGroup {
    return this.formBuilder.group({
      vendor_id: [(this.role == this.userRoles.ROLE_ADMIN) || (this.role == this.userRoles.ROLE_SUBADMIN) ? null : this.company.id, [Validators.required]],
      port_id: [null, [Validators.required]],
      from_date: [null],
      to_date: [null],
      currency: [null]
    });
  }

  search(event) {
    this.product = null;
    this.searchQuery = event.query;
    this.productService
      .getProductSuggestion(this.selectedSubCategory, event.query)
      .subscribe(data => {
        data.data.map(x => {
          x.name = x._source.title;
          x.impa_code = x._source.impa_code;
          x.pack_type = x._source.pack_type;
          x.unit = x._source.unit || (x._source.unit && x._source.unit.name) ? x._source.unit || x._source.unit.name : null;
        });
        this.suggestion = data.data;
      }, error => {
        this.suggestion = [];
      });
  }

  showCrossBtn(event) {
    this.selectedAutocomplete = event.target.value;
  }

  doOnSelect(event) {
    this.product = null;
    this.page = 1;
    if (this.searchQuery === event.name) {
      this.selectedAutocomplete = event.name;
      this.searchForm.get('search').setValue(event.name);
      this.getProduct(0, event.name);
    } else if (this.searchQuery === event._source.impa_code) {
      this.selectedAutocomplete = event._source.impa_code;
      this.searchForm.get('search').setValue(event._source.impa_code);
      this.getProduct(0, event._source.impa_code);
    } else if (this.searchQuery === event._source.product_code) {
      this.selectedAutocomplete = event._source.product_code;
      this.searchForm.get('search').setValue(event._source.product_code);
      this.getProduct(0, event._source.product_code);
    } else {
      this.selectedAutocomplete = event.name;
      this.searchForm.get('search').setValue(event.name);
      this.getProduct(0, event.name);
    }
    this.suggestion = [];
  }

  searchProduct() {
    this.product = null;
    this.submitted = true;
    this.selectedAutocomplete = this.searchForm.value.search;
    this.page = 1;
    this.getProduct(0, this.selectedAutocomplete);
    this.suggestion = [];
  }

  openModel(template: TemplateRef<any>) {
    this.modelRef = this.modalService.show(
      template,
      Object.assign({ backdrop: 'static', keyboard: false, class: 'gray modal-lg product-modal' })
    );
    this.alertService.backModalClose(this.modelRef);
  }

  onSubmit() {
    this.productService.getProductDetails(this.product.id, this.searchForm.value.portId).subscribe(data => {
      this.selectedProductDetails = data;
      console.log(this.selectedProductDetails)
    })
  }

  onDecline() {
    this.resetForm(2);
  }

  getProduct(id = 0, search = this.selectedAutocomplete, showLoading = false) {
    this.product = null;
    // this.selectedSubCategory = id;
    if (!showLoading) {
      // this.pageLoading = true;
    }
    // this.processing = true;
    const page = this.page;
    const limit = this.limit;

    this.productService.getByCategoryId(id, 0, page, limit, search).subscribe(
      data => {
        this.product = null;
        this.product = data.results[0];
        // this.productList.map(product => {
        //   product.quantity = (product.quantity && product.quantity > 0) ? product.quantity : 0;
        //   product.product_id = product.id;
        //   product.ship_id = this.shipId;
        //   product.category_id = this.selectCategoryId;
        // });
        // this.processing = false;
        // this.loading2 = false;
      }, error => {
        // this.processing = false;
        // this.loading2 = false;
      });
  }

  resetForm(count) {
    this.searchForm.get('search').setValue('');
    this.searchForm.get('portId').setValue(null);
    if (count > 1) {
      this.searchForm.patchValue({ search: '' });
      this.selectedAutocomplete = '';
      this.shipForms.forEach(reset => {
        if (this.role !== this.userRoles.ROLE_COMPANY_ADMIN) {
          reset.form.reset();
        }
        reset.form.value.port_id == null;
        reset.loading1 = null;
        this.product = null;
      })
    }
  }
  getReport(shipForm) {
    if (this.product == null) {
      this.toastr.error('Select a product first!');
      return;
    }
    shipForm.submitted = true;
    if (!shipForm.form.valid) {
      return;
    }
    if (shipForm.form.get('from_date').value != null && shipForm.form.get('to_date').value == null) {
      shipForm.form.get('to_date').setValue(this.etd);
    }
    if (shipForm.form.get('from_date').value == null && shipForm.form.get('to_date').value != null) {
      const fromDate = new Date(2016, 0, 1);
      shipForm.form.value.from_date = this.datePipe.transform(fromDate, 'yyyy-MM-dd');
    }

    if (this.isDateRangeInvalid(shipForm.form)) {
      this.toastr.error('"From date" cannot be grater than "To date"');
      return;
    }
    if (shipForm.portList == null && shipForm.form.value.port_id == null) {
      this.toastr.error('No ports found for this Country');
      return;
    }
    // if (shipForm.form.value.port_id == null) {
    //   this.toastr.error('Please select the port');
    //   return;
    // }
    if (shipForm.form.value.from_date) {
      shipForm.form.value.from_date = this.datePipe.transform(shipForm.form.value.from_date, 'yyyy-MM-dd');
    }
    if (shipForm.form.value.to_date) {
      shipForm.form.value.to_date.setDate(shipForm.form.value.to_date.getDate() + 1);
      shipForm.form.value.to_date = this.datePipe.transform(shipForm.form.value.to_date, 'yyyy-MM-dd');
    }
    shipForm.loading = true;
    shipForm.loading1 = false;

    shipForm.productPricesInr = [];
    const formData = new FormData();
    formData.append('vendor_id', `${shipForm.form.value.vendor_id}`);
    formData.append('port_id', `${shipForm.form.value.port_id}`);
    formData.append('product_id', `${this.product.id}`);
    formData.append('from_date', `${shipForm.form.value.from_date}`);
    formData.append('to_date', `${shipForm.form.value.to_date}`);
    this.productService.getPriceHistory(formData).subscribe(res => {
      shipForm.priceHistoryData = res;
      if (shipForm.priceHistoryData && (shipForm.priceHistoryData.product_prices.inr &&
        shipForm.priceHistoryData.product_prices.inr.length > 0) ||
        (shipForm.priceHistoryData.product_prices.usd && shipForm.priceHistoryData.product_prices.usd.length > 0) || (shipForm.priceHistoryData.product_prices.jpy && shipForm.priceHistoryData.product_prices.jpy.length > 0) || (shipForm.priceHistoryData.product_prices.sgd && shipForm.priceHistoryData.product_prices.sgd.length > 0) || (shipForm.priceHistoryData.product_prices.eur && shipForm.priceHistoryData.product_prices.eur.length > 0)) {
        shipForm.price = [];
        if (shipForm.currency === 'USD') {
          shipForm.productPricesInr = res.product_prices.usd;
          shipForm.productPricesInr.map(price => shipForm.price.push(price))
          // shipForm.currency = shipForm.productPricesInr && shipForm.productPricesInr.length > 0 ? shipForm.productPricesInr[0].currency
          //  : '';
        } else if (shipForm.currency === 'JPY') {
          shipForm.productPricesInr = res.product_prices.jpy;
          shipForm.productPricesInr.map(price => shipForm.price.push(price))
        } else if (shipForm.currency === 'SGD') {
          shipForm.productPricesInr = res.product_prices.sgd;
          shipForm.productPricesInr.map(price => shipForm.price.push(price))
        } else if (shipForm.currency === 'EUR') {
          shipForm.productPricesInr = res.product_prices.eur;
          shipForm.productPricesInr.map(price => shipForm.price.push(price))
        } else {
          shipForm.productPricesInr = res.product_prices.inr;
          shipForm.productPricesInr.map(price => shipForm.price.push(price))
        }
        shipForm.arr = []
      }
      shipForm.submitted = false;
      shipForm.loading = false;
      shipForm.loading1 = true;
    }, error => {
      this.toastr.error('Data not found');
      shipForm.loading = false;
      shipForm.loading1 = false;
    });
  }

  currencySwitch(shipForm, event) {
    shipForm.productPricesInr = []
    if (event && event.id === 'INR') {
      shipForm.productPricesInr = shipForm.priceHistoryData.product_prices.inr;
      let newObject = Object.assign({}, shipForm.productPricesInr)
      Array.of(newObject).forEach(item => this.sharedService.removeMarginAmt(item))
    } else if (event && event.id === 'JPY') {
      shipForm.productPricesInr = shipForm.priceHistoryData.product_prices.jpy;
      let newObject = Object.assign({}, shipForm.productPricesInr)
      Array.of(newObject).forEach(item => this.sharedService.removeMarginAmt(item))
    } else if (event && event.id === 'SGD') {
      shipForm.productPricesInr = shipForm.priceHistoryData.product_prices.sgd;
      let newObject = Object.assign({}, shipForm.productPricesInr)
      Array.of(newObject).forEach(item => this.sharedService.removeMarginAmt(item))
    } else if (event && event.id === 'EUR') {
      shipForm.productPricesInr = shipForm.priceHistoryData.product_prices.eur;
      let newObject = Object.assign({}, shipForm.productPricesInr)
      Array.of(newObject).forEach(item => this.sharedService.removeMarginAmt(item))
    } else if (event && event.id === 'USD') {
      shipForm.productPricesInr = shipForm.priceHistoryData.product_prices.usd;
      let newObject = Object.assign({}, shipForm.productPricesInr)
      Array.of(newObject).forEach(item => this.sharedService.removeMarginAmt(item))
    }
  }

  /* changeSubCategory(event) {
    if (event) {
      if (this.searchForm.get('subcategory_id').value != null) {
        this.selectedSubCategory = this.searchForm.get('subcategory_id').value;
        this.resetForm(2);
      }
      this.enableSearch = false;
    }
  } */

  isDateRangeInvalid(form: FormGroup) {
    const fromDate = form.value.from_date;
    const toDate = form.value.to_date;
    return fromDate != null && toDate != null && fromDate > toDate;
  }

  resetFromDate(form: FormGroup) {
    const fromDate = form.value.from_date;
    if (fromDate != null) {
      form.patchValue({
        from_date: null
      });
    }
  }

  resetToDate(form: FormGroup) {
    const toDate = form.value.to_date;
    if (toDate != null) {
      form.patchValue({
        to_date: null
      });
    }
  }

  onImgError(event) {
    event.target.src = '/assets/images/placeholder_image.png';
  }

  goPreviousUrl() {
    this.companyService.getUrl.pipe(takeUntil(this.unsubscribe$))
      .subscribe(url => {
        if (url && url.filter && url.filter.includes('vendor_id' || 'port_id')) {
          this.role == this.userRoles.ROLE_COMPANY_ADMIN && this.company.type === 2 ? this.router.navigate(['/product'], { queryParams: { page: url.page, filter: url.filter } }) : this.router.navigate(['/product/prices'], { queryParams: { page: url.page, filter: url.filter } });
        } else {
          this.role == this.userRoles.ROLE_COMPANY_ADMIN && this.company.type === 2 ? this.router.navigate(['/product']) : this.router.navigate(['/product/prices']);
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  /*diff(fDate: Date, toDate: Date, price: any, arr: any) {
    //this.price = price;
    let datFrom = new Date(fDate);
    let datTo = new Date(toDate);
    let s1 = datFrom.getMonth();
    let s2 = datTo.getMonth();
    if ((s1 - s2) == 0) {
      for (let i = 1; i <= 5; i++) {
        arr.push('Week ' + i);
      }
    } else {
      let fromYear = datFrom.getFullYear();
      let toYear = datTo.getFullYear();
      let diffYear = (12 * (toYear - fromYear)) + datTo.getMonth();
      for (var i = datFrom.getMonth(); i <= diffYear; i++) {
        arr.push(this.monthNames[i % 12]);
      }
    }
    return arr;
  }*/
  /*  removeMarginAmt(item) {
     let prc_without_tax;
     if (parseFloat(item.tax) != 0) {
       prc_without_tax = (parseFloat(item.price) * 100) / (parseFloat(item.tax) + 100);
     } else {
       prc_without_tax = parseFloat(item.price);
       item.tax = 0;
     }
     const prc_without_margin = (prc_without_tax * 100) / (parseFloat(item.margin) + 100);
     const prc_with_tax = prc_without_margin + ((prc_without_margin * parseFloat(item.tax)) / 100);
     item.price = prc_with_tax;
     return item.price;
   } */
}
